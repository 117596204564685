.aboutus {
  background:url("https://api.unblokdata.com/frontImg/About/backgroung.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.aboutus_body {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
  padding: 50px 0px;
  position: relative;
}
.about_banner_content {
  margin-bottom: 100px;
}
.container_about {
  padding: 50px 0px;
}
.about_title {
  max-width: 410px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 50px;
}
.title_content_about h1 {
  color: #fff;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-shadow: -2px 1.5px 0px black, -4px 2.5px 0px #612cd2;
}
.about_title p {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.container_about .header_p {
  color: #fff;
  font-family: "Helvetica Neue Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  margin-top: 100px;
}
.header_image img {
  position: absolute;
  top: 0px;
  right: 0px;
}
.about_baner_heading {
  display: flex;
  align-items: center;
  margin-left: -20px;
}
.about_baner_heading_title {
  gap: 5px;
  display: flex;
  flex-direction: column;
}
.about_baner_heading_title h2 {
  color: #612cd2;
  text-align: start;
  font-family: "HelveticaNeue";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0px;
  margin-left: -80px;
}
.about_baner_heading_title .Cryptocurrency {
  margin-left: -50px;
  margin-top: -3px;
}
.about_baner_heading_title .Benefits {
  margin-left: -20px;
  margin-top: -10px;
}
.about_baner_heading_title .Global {
  margin-left: -20px;
  margin-top: -10px;
  max-width: unset !important;
}
.about_peragraph .strong {
  color: #000;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about_peragraph .normal {
  color: #000;
  font-family: "Helvetica Neue Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.about_baner_heading {
  margin: 40px 0px 10 0px;
}
.getstart_section {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 100px;
}
.marque_main_box {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace//marque.svg");
  background-size: cover;
  background-position: center;
  height: 392px;
  position: absolute;
  top: -140px;
}
.marque_about {
  width: 110%;
  transform: rotate(4.538deg);
  flex-shrink: 0;
  margin-left: -5%;
  margin-top: 104px;
}
.marque_interdiv {
  padding-top: 15px;
  padding-bottom: 15px;
  height: 120px;
  display: flex;
  align-items: center;
}
.get_start_box {
  border-radius: 5px;
  border: 1px solid rgba(162, 121, 250, 0.2);
  background: rgba(155, 124, 220, 0.1);
  backdrop-filter: blur(5px);
  margin-top: 400px;
  margin-bottom: 100px;
  padding: 50px;
  display: flex;
  gap: 40px;
  width: fit-content;
  align-items: center;
  position: relative;
}
.getstatr_button {
  max-width: 395;
  margin-top: -120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.getstatr_content {
  max-width: 599px;
}
.getstatr_content .about_getstart {
  color: #fff;
  font-family: "Helvetica Neue Light";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.btn_getstart {
  color: #000;
  font-family: "HelveticaNeue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  border-radius: 5px;
  background: #fff600;
  padding: 6px 30px;
  max-width: 355px;
  transition: 0.5s ease-in-out !important;
}
.btn_getstart:hover {
  color: #fff;
  background-color: rgba(97, 44, 210, 0.2);
  border: 1px solid rgba(162, 121, 250, 0.5);
}
.about_image_box {
  position: absolute;
  right: 0px;
  bottom: 60%;
}
.about_image_box .img1 {
  position: absolute;
  top: 0px;
  right: 55px;
}
.about_image_box .img2 {
  position: absolute;
  top: -200px;
  right: 170px;
}
.about_image_box .img3 {
  position: absolute;
  top: 120px;
  right: 70px;
}
.about_baner_heading .img4 {
  right: 0px;
  top: 0px;
}
.about_baner_heading .img5 {
  right: 50px;
  top: -40px;
}
#about_light_star {
  width: 90px;
}
/* @media Query  */
@media screen and (min-width: 1920px) {
  .marque_main_box {
    height: 519px;
    top: -200px;
  }
  .marque_about {
    margin-top: 155px;
  }
}
@media screen and (min-width: 1441px) {
  .header_image img {
    top: 20px;
    right: 5%;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .header_image img {
    width: 50%;
    top: 0px;
  }
}
@media screen and (max-width: 1441px) and (min-width: 1025px) {
  .header_image img {
    width: 50%;
    top: 0px;
  }
  .marque_main_box {
    height: 350px;
    top: -160px;
  }
  .marque_about {
    margin-top: 93px;
  }
  .get_start_box {
    margin-top: 330px;
  }
  .getstatr_button {
    margin-top: -110px;
  }
}
@media screen and (max-width: 1025px) and (min-width: 769px) {
  .header_image img {
    width: 50%;
    top: 20px;
  }
  .container_about .header_p {
    font-size: 18px;
    margin-top: 40px;
  }
  .about_title p {
    font-size: 25px;
  }
  .title_content_about h1 {
    font-size: 60px;
  }
  .about_title {
    margin-top: 40px;
    gap: 25px;
  }
  .about_baner_heading_title h2 {
    font-size: 40px;
    margin-left: -30px;
  }
  .about_baner_heading {
    margin-left: -10px;
  }
  .about_peragraph .strong,
  .about_peragraph .normal {
    font-size: 18px;
  }
  .about_baner_heading_title {
    gap: 16px;
  }
  .marque_main_box {
    height: 304px;
    top: -90px;
  }
  .marque_about {
    margin-top: 70px;
  }
  .get_start_box {
    padding: 30px;
    margin-top: 330px;
  }
  .getstatr_button {
    margin-top: -100px;
  }
  .btn_getstart {
    padding: 5px 30px;
    font-size: 25px;
  }
  .getstatr_content .about_getstart {
    font-size: 25px;
  }
}
@media screen and (max-width: 769px) and (min-width: 601px) {
  #about_light_star, .about_baner_heading .img4 {
    width: 60px;
  }
  .header_image img {
    width: 45%;
    top: 20px;
  }
  .container_about .header_p {
    font-size: 16px;
    margin-top: 30px;
  }
  .about_title p {
    font-size: 22px;
  }
  .title_content_about h1 {
    font-size: 50px;
  }
  .about_title {
    margin-top: 0px;
    gap: 20px;
  }
  .about_baner_heading_title h2 {
    font-size: 30px;
    margin-left: -40px;
  }
  .about_baner_heading {
    margin-left: 0px;
  }
  .about_peragraph .strong,
  .about_peragraph .normal {
    font-size: 16px;
  }
  .about_baner_heading_title {
    gap: 16px;
  }
  .marque_main_box {
    height: 314px;
    top: -90px;
  }
  .marque_about {
    margin-top: 75px;
  }
  .get_start_box {
    flex-direction: column;
  }
  .get_start_box {
    flex-direction: column;
    max-width: -webkit-fill-available;
    padding: 30px;
    margin-top: 350px;
  }
  .get_start_box img {
    width: 100%;
  }
  .getstatr_button {
    margin-top: -100px;
  }
  .btn_getstart {
    padding: 5px 30px;
    font-size: 22px;
  }
  .getstatr_content .about_getstart {
    font-size: 22px;
  }
  .about_image_box .img1 {
    top: -100px;
    right: 70px;
  }
  .about_image_box .img2 {
    top: -300px;
    right: 60px;
    width: 50px;
  }
  .about_image_box .img3 {
    top: 40px;
    right: 70px;
  }
  .about_baner_heading .img5 {
    right: 20px;
    top: -40px;
  }
}
#about_baner_heading {
  width: 200px;
}
#about_benefit_heading {
  width: 130px;
  margin-left: 30px;
}
#about_global_heading {
  width: 150px;
  margin-left: 20px;
}
@media screen and (max-width: 601px) and (min-width: 426px) {
  #about_light_star, .about_baner_heading .img4 {
    width: 60px;
  }
  .header_image img {
    width: 75%;
    position: unset;
  }
  .container_about .header_p {
    font-size: 16px;
    margin-top: 20px;
  }
  .about_title p {
    font-size: 20px;
    width: 280px;
  }
  .title_content_about h1 {
    font-size: 40px;
  }
  .about_title {
    margin-top: 0px;
    gap: 20px;
  }
  .about_baner_heading_title h2 {
    font-size: 25px;
    margin-left: -40px;
  }
  .about_baner_heading {
    margin-left: 0px;
  }
  .about_peragraph .strong,
  .about_peragraph .normal {
    font-size: 16px;
  }
  .about_baner_heading img,
  #about_baner_heading {
    width: 140px;
  }
  #about_benefit_heading,
  #about_global_heading {
    width: 100px;
    margin-left: 0px !important;
  }
  .about_baner_heading_title {
    gap: 16px;
  }
  .marque_main_box {
    height: 304px;
    top: -85px;
  }
  .marque_about {
    margin-top: 70px;
  }
  .get_start_box {
    flex-direction: column;
  }
  .get_start_box {
    flex-direction: column;
    max-width: -webkit-fill-available;
    padding: 20px;
    margin-top: 300px;
  }
  .get_start_box img {
    width: 100%;
  }
  .getstatr_button {
    margin-top: -100px;
  }
  .btn_getstart {
    padding: 5px 20px;
    font-size: 20px;
  }
  .getstatr_content .about_getstart {
    font-size: 20px;
  }
  .about_baner_heading .img5 {
    right: 20px;
    top: -60px;
    width: 60px;
  }
}
@media screen and (max-width: 426px) {
  #about_light_star, .about_baner_heading .img4 {
    width: 50px;
    top: -10px!important;
    right:0px!important;
  }
  .header_image img {
    width: 75%;
    position: unset;
  }
  .header_image {
    display: flex;
    justify-content: end;
  }
  .container_about .header_p {
    font-size: 14px;
    margin-top: 20px;
  }
  .about_title p {
    font-size: 18px;
    width: 280px;
  }
  .title_content_about h1 {
    font-size: 40px;
  }
  .about_title {
    margin-top: 0px;
    gap: 20px;
  }
  .about_baner_heading_title h2 {
    font-size: 25px;
    margin-left: -40px;
  }
  .about_baner_heading {
    margin-left: 0px;
  }
  .about_peragraph .strong,
  .about_peragraph .normal {
    font-size: 14px;
  }
  .about_baner_heading img,
  #about_baner_heading {
    width: 140px;
  }
  #about_benefit_heading,
  #about_global_heading {
    width: 100px;
    margin-left: 0px !important;
  }
  .about_baner_heading_title {
    gap: 16px;
  }
  .marque_main_box {
    height: 168px;
    top: -84px;
  }
  .marque_about {
    margin-top: 9px;
  }
  .get_start_box {
    flex-direction: column;
    max-width: -webkit-fill-available;
    padding: 10px;
    margin-top: 190px;
  }
  .get_start_box img {
    width: 100%;
  }
  .getstatr_button {
    margin-top: -90px;
  }
  .btn_getstart {
    padding: 5px 20px;
    font-size: 18px;
    margin-left: 20px;
  }
  .getstatr_content .about_getstart {
    font-size: 18px;
  }
  .about_image_box .img1 {
    top: -250px;
    right: 30px;
    width: 60px;
  }
  .about_image_box .img2 {
    top: -400px;
    right: 30px;
    width: 40px;
  }
  .about_image_box .img3 {
    top: -100px;
    right: 20px;
    width: 50px;
  }
  .about_baner_heading .img4 {
    right: 0px;
    top: 0px;
    width: 60px;
  }
  .about_baner_heading .img5 {
    right: 20px;
    top: -60px;
    width: 60px;
  }
}
@media screen and (max-width: 601px) {
  .title_content_about h1 {
    text-align: left;
  }
  #about_page4Wrap .mb-5{
    margin-bottom: 10px!important;
  }
  .header_image{
    text-align: end;
  }
}
