.home_header {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
}
.content-banner {
  background: transparent;
}
.mobile_content {
  background: transparent !important;
}
.homebanerheight h1 {
  color: white !important;
}
.mobile_content .homebanerheight {
  background-color: transparent !important;
}
.faq_img_aligned {
  padding-top: 30px !important;
}
.searchiconsd {
  border-radius: 0px !important;
  border: 0px solid rgba(162, 121, 250, 0.5) !important;
}
.searchbar {
  border-radius: 5px !important;
  background-color: rgba(97, 44, 210, 0.2);
  border: 01px solid rgba(162, 121, 250, 0.5) !important;
}
.learnmore {
  display: none;
}
.homebanerheight {
  padding: 0px;
  margin-top: 0px;
}
.mobile_content {
  margin: 15px 0px;
}
.recentlyadded {
  background: transparent !important;
}
.top-data-token {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 50px 0px;
}
.top-data-token h1 {
  color: #fff;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-shadow: -2px 1.5px 0px black, -4px 2.5px 0px #612cd2;
}
.top-data-token h2 {
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.explore-button {
  border-radius: 5px !important;
  background: #612cd2 !important;
  color: white !important;
  height: 60px !important;
  width: 248px !important;
}
.live_auctions h3 {
  color: #000;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.live_auctions h1 {
  color: #612cd2;
  font-family: "HelveticaNeue";
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-shadow: -2px 1.5px 0px white, -4px 2.5px 0px #612cd2;
}
.customer-section {
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}
.customer-h1,
.growth-h1 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.h1-span {
  color: #fff600;
  font-family: "HelveticaNeue";
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.customer-p,
.growth-p {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 654px;
}
.section-main {
  width: 90%;
}
.customer-baner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customer-div1 {
  width: 60%;
}
.customer-div2 {
  width: 40%;
}
.growth-baner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.growth-div1 {
  width: 40%;
  position: relative;
}
.growth-div2 {
  position: relative;
}
.customer-div2 {
  width: 60%;
}
.video-main-container {
  max-width: fit-content;
  border-radius: 04px;
  border: 1px solid rgba(162, 121, 250, 0.2);
  background: rgba(97, 44, 210, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 30px 10px;
}
.video_container {
  max-width: 100%;
  border-radius: 4px;
}
.thumbnail_container {
  cursor: pointer;
  position: absolute;
  height: -webkit-fill-available;
  top: 0px;
}
.home_img .img1 {
  right: 50px;
  top: 0px;
  width: 80px;
}

.home_img .img3 {
  left: 50px;
  bottom: -50px;
  height: 40px;
  width: 40px;
}
.recentlyadded-img .img2 {
  left: 50px;
  bottom: -120px;
  width: 80px;
}
.popup_loader{
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
  color: white;
}
@media screen and (max-width: 350px) {
  .video_container {
    height: 100% !important;
  }
  .faq_img_aligned {
    height: fit-content;
  }
  .customer-h1,
  .customer-h1 span,
  .growth-h1,
  .growth-h1 span {
    font-size: 28px !important;
  }
  .homebanerheight h1 span {
    font-size: 24px !important;
  }
  .mobile_content .homebanerheight {
    margin-top: 0px !important;
  }
  .seathbats,
  .searchiconsd {
    width: 98% !important;
  }
  .top-data-token h2,
  .live_auctions h3 {
    font-size: 20px !important;
  }
  .top-data-token h1,
  .live_auctions h1 {
    font-size: 34px !important;
    margin-top: -10px;
  }
  .top-data-token {
    margin: 18px 0px !important;
  }
  .card-main {
    padding: 0px;
  }
  .tokencardlg_connectwallet.connectwallet {
    width: 120px !important;
    height: 40px !important;
  }
  .tokencardlg_connectwallet.connectwallet {
    font-size: 18px !important;
  }
  .recentlyadded {
    padding-bottom: 27px !important;
  }
  .live_auctions {
    padding: 27px 0px !important;
  }
  .explore-button {
    width: 95% !important;
    height: 40px !important;
  }
  .data-card {
    width: 96%;
  }
  .slicing_row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-data {
    justify-content: center;
  }
  .customer-baner {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .growth-baner {
    flex-direction: column;
  }
  .customer-div1 {
    width: 100%;
  }
  .growth-div1 {
    width: 100%;
    margin-bottom: 30px;
  }
  .home_img .img1 {
    right: 50px;
    top: 110%;
    width: 40px;
  }
  .home_img .img3 {
    left: 50px;
    bottom: -50px;
    width: 30px;
  }
  .recentlyadded-img .img2 {
    left: 50px;
    bottom: -35px;
    width: 50px;
  }
}
@media screen and (max-width: 601px) {
  .video_container {
    height: auto;
  }
  .video-main-container {
    max-width: 100%;
    border: 0px solid rgba(162, 121, 250, 0.2);
    background: rgba(97, 44, 210, 0.2);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    padding: 04px !important;
    margin-top: 0px;
    margin-bottom: 40px;
    margin-left: 0 !important;
    margin-right: 0px !important;
  }
  .video-main-container {
    padding: 04px !important;
    border: 0px;
    width: 100%;
    border-radius: 2px !important;
  }
  .video_container {
    height: auto !important;
    border-radius: 2px !important;
  }
}
@media screen and (min-width: 351px) and (max-width: 600px) {
  .growth-h1,
  .customer-h1,
  .h1-span {
    font-size: 34px;
  }
  .customer-p,
  .growth-p {
    font-size: 18px;
  }
  .video-main-container {
    padding: 5px;
  }
  /* .video_container {
    height: 150px !important;
  } */
  .faq_img_aligned {
    height: fit-content;
  }
  .mobile_content .homebanerheight {
    margin-top: 0px !important;
  }
  .homebanerheight h1 span {
    font-size: 28px !important;
  }
  .customer-h1,
  .customer-h1 span,
  .growth-h1,
  .growth-h1 span {
    font-size: 33px !important;
  }
  .seathbats,
  .searchiconsd {
    width: 96% !important;
    margin: auto;
  }
  .top-data-token h2,
  .live_auctions h3 {
    font-size: 22px !important;
  }
  .top-data-token h1,
  .live_auctions h1 {
    font-size: 38px !important;
    margin-top: -10px;
  }
  .top-data-token {
    margin: 18px 0px !important;
  }
  .card-main {
    padding: 0px;
  }
  .tokencardlg_connectwallet.connectwallet {
    width: 100px !important;
    height: 40px !important;
  }
  .tokencardlg_connectwallet.connectwallet {
    font-size: 20px !important;
  }
  .live_auctions {
    padding: 30px 0px !important;
  }
  .recentlyadded {
    padding-bottom: 27px !important;
  }
  .explore-button {
    width: 90% !important;
    height: 45px !important;
  }

  .container-data {
    justify-content: center;
  }
  .growth-baner {
    flex-direction: column;
    gap: 20px;
  }
  .customer-baner {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .customer-div1 {
    width: 100%;
  }
  .growth-div1,
  .growth-div2 {
    width: 100%;
    margin-bottom: 30px;
  }
  .customer-p,
  .growth-p {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 601px) {
  .img11,
  .img33 {
    display: none;
  }
}
@media screen and (max-width: 601px) {
  .home_img_top {
    padding: 20px 24px;
    max-width: 80%;
    position: relative;
    margin: auto;
  }
  .home_img_top .img11,
  .home_img_top .img33 {
    width: 24px;
  }
  .home_img_top .img11 {
    position: absolute;
    right: -10px;
    bottom: -12px;
  }
  .home_img_top .img33 {
    position: absolute;
    left: 28px;
    top: 8px;
  }
  .home_img .img1,
  .imgg1 {
    right: 50px;
    top: 135%;
    width: 24px;
  }
  .home_img .img3 {
    left: 50px;
    bottom: -120px;
    width: 16px;
  }
  .recentlyadded-img .img2 {
    display: none;
    left: 45%;
    bottom: -35px;
    width: 50px;
  }
  .home_header {
    /* background-size: contain!important; */
    /* background-repeat: no-repeat; */
    background-position: top;
  }
}
@media screen and (min-width: 602px) and (max-width: 770px) {
  .growth-baner {
    flex-direction: column;
    gap: 20px;
  }
  .customer-baner {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .customer-div1 {
    width: 100%;
    margin-bottom: 20px;
  }
  .growth-div1 {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  .customer-div2 {
    width: 60%;
  }
  .growth-div2 {
    width: 100%;
    left: 0px;
  }
  .seathbats,
  .searchiconsd {
    width: 100%;
  }
  .customer-h1,
  .customer-h1 span,
  .growth-h1,
  .growth-h1 span {
    font-size: 50px !important;
  }
  .mobile_content .homebanerheight {
    margin-top: 0px !important;
  }
  .top-data-token h2 {
    font-size: 40px !important;
  }
  .top-data-token h1 {
    font-size: 60px !important;
    margin-top: -10px;
  }
  .top-data-token {
    margin: 18px 0px !important;
  }
  .top-data-token h1,
  .live_auctions h1 {
    font-size: 45px !important;
    margin-top: -10px;
  }
  .top-data-token h2,
  .live_auctions h3 {
    font-size: 30px !important;
  }
  .video-main-container {
    width: 95%;
  }
}
@media screen and (min-width: 770px) and (max-width: 1430px) {
  .growth-div1 {
    width: 65%;
    left: 0px;
  }
  .growth-div2 {
    margin-top: 50px;
    left: 0px;
  }
  .homebanerheight {
    margin-bottom: 15px !important;
  }
  .top-data-token h1,
  .live_auctions h1 {
    font-size: 60px !important;
    margin-top: -10px;
  }
  .top-data-token h2,
  .live_auctions h3 {
    font-size: 40px !important;
  }
  .video-main-container {
    width: 95%;
  }
  .home_img .img1 {
    right: 50px;
    top: 0px;
  }
  .home_img .img3 {
    left: 50px;
    bottom: -50px;
  }
}
@media screen and (min-width: 1430px) {
  .section-main {
    width: 1300px;
  }
  .customer-div1,
  .growth-div2 {
    width: 60%;
  }
  .customer-div2,
  .growth-div1 {
    width: 40%;
  }
  .growth-div1 {
    position: relative;
    width: 40%;
  }
  .homebanerheight {
    margin-bottom: 15px;
  }
  .top-data-token {
    margin: 0px 0px 50px 0px !important;
  }
  .homebanerheight {
    margin-bottom: 15px !important;
  }
  .homebanerheight h1 span {
    font-size: 80px !important;
  }
  .top-data-token h1,
  .live_auctions h1 {
    font-size: 80px !important;
    margin-top: -10px;
  }
  .top-data-token h2,
  .live_auctions h3 {
    font-size: 50px !important;
  }
  .video_container {
    max-width: 100%;
    border-radius: 4px;
  }
  .video-main-container {
    max-width: 1275px !important;
  }
}
@media screen and (min-width: 1200px) {
  html body #home_headerBox .container {
    padding: 4px !important;
    max-width: 1400px !important;
  }
  html body #video-Wrapper .container {
    max-width: 1200px !important;
  }
}
