.termsBanner {
  background: url("../assets/Pages/Terms-and-service/HeaderBackground.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  height: 500px;
  display: flex;
}
.termsBanner .termsofservice {
  max-width: 1200px;
  padding: 10px;
  margin: 0px auto;
}
.termsBanner .termsofservice {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: auto;
  width: 100%;
}
.termsimageBox {
  text-align: center;
}
.termsimageBox #termsimageBox{
  width: 300px;
}
.termsBanner .termsofservice h1 {
  color: #ffffff;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  padding: 0;
  margin: 0;
  text-align: left;
}
.termsBanner .termsofservice h4 {
  color: #fff;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.termstextBox img {
  position: relative;
  right: -250px;
}
.termstextBox #termsBannerStar{
  width: 80px!important;
}
.terms-sevice-content .disc_accord > p strong,
.terms-sevice-content .disc_accord b {
  display: none !important;
}
.termsbanner_bgcolor .terms-sevice-content .termsofservice p {
  color: #000;
  font-family: Helvetica Neue Light !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

@media screen and (max-width: 1100px) {
  .termsBanner .termsofservice h1 {
    font-size: 50px;
  }
  .termsBanner .termsofservice h4 {
    font-size: 24px;
  }
  .termsBanner .termsofservice {
    padding: 30px;
  }
  .termstextBox img {
    right: -217px;
    width: 62px;
    top: -8px;
  }
}
@media screen and (max-width: 769px) {
  .termsofservice {
    display: block !important;
  }
  .termsBanner {
    height: auto;
  }
  .termsBanner .termsofservice h1,
  .termsBanner .termsofservice h4 {
    text-align: center;
  }
  .termstextBox {
    text-align: center;
  }
  .termstextBox img {
    right: 0;
    width: 62px;
    top: -8px;
  }
  .termsBanner .termsimageBox img {
    width: 40% !important;
    margin: auto;
    height: auto !important;
  }
}
@media screen and (max-width: 601px) {
  .termsBanner .termsofservice h1 {
    font-size: 30px;
    text-align: center;
  }
  .termsBanner .termsofservice h4 {
    text-align: center;
    font-size: 16px;
  }
  .termstextBox {
    text-align: center;
  }
  .termsimageBox img {
    width: 40% !important;
    margin: auto;
    height: auto !important;
  }
  .terms-sevice-content .termsofservice p {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .terms-sevice-content h6 {
    font-size: 16px !important;
    line-height: 30px !important;
    padding-bottom: 20px;
  }
  .terms-sevice-content .accordion-body {
    padding: 20px !important;
  }
  .terms-sevice-content .accordion-button{
    padding-top: 06px!important;
  }
}
.termsbanner_bgcolor {
  background: url("../assets/Pages/Terms-and-service/body-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.termsbanner_bgcolor .terms-sevice-content .termsofservice p {
  color: #000;
  font-family: Helvetica Neue Light !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.terms-sevice-content .accordion-button {
  padding: 0px;
}
.terms-sevice-content .accordion {
  margin-top: 87px;
}
.terms-sevice-content h6 {
  color: #612cd2;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px;
}
.terms-sevice-content button.accordion-button.collapsed h6 {
  color: #090806;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px;
}
.terms-sevice-content .accordion-body {
  border-radius: 5px;
  border: 2px solid rgba(162, 121, 250, 0.3);
  background: rgba(155, 124, 220, 0.15);
  backdrop-filter: blur(10px);
  padding: 69px;
}
.terms-sevice-content .accordion-button::after {
  color: #000000 !important;
}
.terms-sevice-content .accordion-button {
  background: url("../assets/Pages/Terms-and-service/border-bottom.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
.terms-sevice-content {
  margin-bottom: 130px;
}
