#buynow #buynowLabel {
  color: #fff;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 79px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
  text-shadow: -6px 3px 0px #120b19, -8px 6px 0px #612cd2;
  margin-top: 40px;
}
#buynow .modal-dialog {
  max-width: 743px;
}
#buynow .modal-content {
  max-width: 743px;
  width: 100% !important;
  border: 4px solid rgba(162, 121, 250, 0.3) !important;
  background-color: rgba(133, 100, 201, 0.25) !important;
  backdrop-filter: blur(10px);
  height: fit-content !important;
  margin: 20px;
}
#buynow .idImagWrapper img {
  max-width: 743px;
  width: 100% !important;
  border-radius: 6.673px;
  backdrop-filter: blur(13.345815658569336px);
  border: 4px solid rgba(162, 121, 250, 0.3) !important;
  background-color: rgba(133, 100, 201, 0.25) !important;
  padding: 5px;
}
#buynow .idTextWrapper h5 {
  color: #fff600;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
#buynow .idTextWrapper h6 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.ImagsContentWrapper {
  width: 84%;
  margin: auto;
  display: grid;
  grid-template-columns: 30% 70%;
  margin-top: 36px;
  margin-bottom: 31px;
  align-items: center;
}
.idTextWrapper {
  padding: 0px 20px;
}
#buynow .modal-body {
  width: 84%;
  margin: auto;
  padding: 0 !important;
}
#buynow .buynow_popupname h6 {
  color: #a279fa;
  font-family: "HelveticaNeue";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px !important;
}
#buynow .buynow_popupname1 h6 {
  color: #FFF;
  text-align: right;
  font-family: "HelveticaNeue";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px !important;
}
#buynow .buynow_popupname1 h5 {
  color: #FFF;
}
#buynow .buynow_popup {
 align-items: center;
}
#buynow .buynow_popupname1 {
max-width: 50%;
}
.buynow_popupname h2,
.buynow_popupname1 h2 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#buynow .modal-footer {
  margin-top: 50px;
}
#buynow .modal-footer .buybtn {
  width: 85%;
  border-radius: 5px !important;
  background: #fff600 !important;
  color: #000 !important;
  font-family: "HelveticaNeue";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 16px !important;
}
#buynow #WalletBalance {
  margin-top: 39px !important;
}
.palcebideths.palcebideths_krdrop,
.palcebideths {
  border-radius: 5px !important;
  border: 1px solid rgba(162, 121, 250, 0.5) !important;
  background: rgba(97, 44, 210, 0.2) !important;
  color: #ffffff;
  font-family: "Helvetica Neue Light" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 34px 10px;
}
#buynow .react-select__control {
  border-radius: 5px !important;
  border: 0px solid rgba(162, 121, 250, 0.5) !important;
  background: rgba(97, 44, 210, 0.2) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px) !important;
  color: #ffffff;
  font-family: "Helvetica Neue Light" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#buynow #buyemail::placeholder,
#buynow .form-control:focus,
#buynow input[type="text"]:focus-visible,
.palcebideths input::placeholder,
.palcebideths.palcebideths_krdrop::placeholder,
#tokenBidAmt:focus,
.palcebideths::placeholder {
  font-family: "Helvetica Neue Light" !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff !important;
  padding: 34px 10px;
}
.palcebideths input {
  margin-left: 0px !important;
}
.react-select__single-value,
input#tokenBidAmt {
  color: #ffffff !important;
}
#buynow .bidbtn {
  position: absolute;
  top: -44px;
  right: -57px;
  width: fit-content;
}
.modal-footer .bidbtn:hover {
  background: transparent !important;
  border: none !important;
}
@media screen and (max-width: 1025px) and (min-width: 769px) {
  .headDivider {
    overflow: hidden;
    display: none;
  }
  #buynow #buynowLabel {
    font-size: 50px !important;
  }
  #buynow .idTextWrapper h5 {
    font-size: 30px;
  }
  #buynow .idTextWrapper h6 {
    font-size: 20px !important;
  }
  #buynow .buynow_popupname1 h6 {
    font-size: 20px !important;
  }
  #buynow .buynow_popupname h6 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 769px) {
  .idImagWrapper img {
    width: 30%;
  }
  #buynow .modal-dialog {
    max-width: 90% !important;
    margin: 20px auto;
  }
  .headDivider {
    overflow: hidden;
    display: none;
  }
  .ImagsContentWrapper {
    display: block !important;
    text-align: center;
    margin: 0 auto;
  }
  #buynow #buynowLabel {
    font-size: 24px !important;
  }
  #buynow .idTextWrapper h5 {
    font-size: 25px;
  }
  #buynow .idTextWrapper h6 {
    font-size: 16px !important;
  }
  #buynow .buynow_popupname h6,
  #buynow .buynow_popupname1 h6 {
    margin: 04px 0px !important;
    font-size: 18px !important;
  }
  #buynow .idTextWrapper h5,
  #buynow .idTextWrapper h6 {
    text-align: center !important;
    margin: 10px 0px;
  }
  .palcebideths.palcebideths_krdrop,
  .palcebideths {
    padding: 12px 10px;
  }
  #buynow #WalletBalance {
    margin-top: 10px !important;
  }
  #buynow .modal-footer {
    margin-top: 20px !important;
  }
  #buynow #buyemail::placeholder,
  #buynow .form-control:focus,
  #buynow input[type="text"]:focus-visible,
  .palcebideths input::placeholder,
  .palcebideths.palcebideths_krdrop::placeholder,
  #tokenBidAmt:focus,
  .palcebideths::placeholder,
  #buynow .react-select__control,
  .palcebideths.palcebideths_krdrop,
  .palcebideths {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1025px) {
  #buynow .bidbtn img {
    width: 30px;
    margin: 0;
  }
  #buynow .bidbtn {
    position: absolute;
    top: 10px !important;
    right: 10px !important;
    width: fit-content;
    padding: 0px !important;
  }
  #buynow .modal-content {
    margin: 0px !important;
  }
}
#buynow {
  backdrop-filter: blur(0px);
}
#yellowBidText {
  color: #fff600;
}
.quantityValue h6 {
  color: #fff;
  font-family: "Helvetica Neue Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 600px){
  #buynow .buynow_popupname h6,
  #buynow .buynow_popupname1 h6 {
    font-size: 14px !important;
  }
  #buynow .idTextWrapper h5 {
    font-size: 22px;
  }
}