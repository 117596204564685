.myitemspage {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
}
.myitemspagesec {
  background-color: transparent;
}
@media screen and (min-width:892px){
  .myitemspagesec {
    background-color: transparent;
    padding-bottom: 90px;
  }
}
.myitem-img .img1 {
  bottom: 50px;
  right: 20px;
  width: 80px;
}
.myitem-img .img3 {
  top: 30px;
  left: 70px;
  height:40px;
width:40px;
}
.myitem-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.myitem-title h2 {
  color: #fff600;
  text-align: start;
  font-family: "HelveticaNeue";
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-shadow: -2px 1.5px 0px black, -4px 2.5px 0px #612cd2;
}
.main-item-header {
  padding-top: 100px;
}
.myitem-title-span span {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.myaccount_tab .nav-tabs {
  border-radius: 0px;
  border: none;
  background: transparent;
  justify-content: unset;
  gap: 15px;
}
.myaccount_tab .nav-tabs li {
  padding: 10px 60px;
  border-radius: 5px;
  border: 1px solid rgba(162, 121, 250, 0.5);
  transition: 0.5s ease-in-out !important;
}
.myaccount_tab .nav-tabs li.active a {
  color: white;
}
.myaccount_tab .nav-tabs li.active {
  border-radius: 5px;
  border: unset;
  background: #612cd2;
}
.myaccount_tab .nav-tabs li:hover {
  background: #612cd2;
}
.myaccount_tab {
  display: flex;
}
.container-tab{
  margin-bottom: 100px;
}
.not-foung-item{
  margin: 50px 0px;
}
.not-foung-item p{
  font-size: 25px;
  color: white;
}
/* .myaccount_tab .business_disabled {
  border-radius: 5px;
  border: 1px solid rgba(162, 121, 250, 0.50);
 color: #ccc;

} */

/* .myaccount_tab .nav-link.disabled {
  color: #ccc;
  cursor: not-allowed;
} */

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .myitem-title h2 {
    font-size: 60px;
  }
  .main-item-header {
    padding-top: 80px;
  }
  .myitem-img .img3 {
    top: 20px;
    left: 10%;
    width: 35px;
  }
}
@media screen and (max-width: 769px) and (min-width: 601px) {
  .myitem-title h2 {
    font-size: 50px;
  }
  .main-item-header {
    padding-top: 70px;
  }
  .myitem-img .img1 {
    bottom: 40px;
    right: 40px;
    width: 65px;
  }
  .myitem-img .img3 {
    top: 20px;
    left: 10%;
    width: 35px;
  }
  .myitem-title-span span {
    font-size: 18px;
  }
  .myaccount_tab .nav-tabs li {
    padding: 8px 25px;
    border-radius: 5px;
    border: 1px solid rgba(162, 121, 250, 0.5);
    transition: 0.5s ease-in-out !important;
  }

  .myaccount_tab.nav-tabs li a {
    font-size: 14px;
  }
}
@media screen and (max-width: 601px) and (min-width: 426px) {
  .myitem-title h2 {
    font-size: 45px;
  }
  .main-item-header {
    padding-top: 60px;
  }
  .myitem-img .img1 {
    bottom: 40px;
    right: 30px;
    width: 60px;
  }
  .myitem-img .img3 {
    top: 30px;
    left: 70%;
    width: 40px;
  }
  .myitem-title-span span {
    font-size: 17px;
  }
  .myaccount_tab .nav-tabs li {
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid rgba(162, 121, 250, 0.5);
    transition: 0.5s ease-in-out !important;
    width: 80%;
    margin: auto;
  }
  .myaccount_tab .nav-tabs {
    display: grid;
  }
  .myaccount_tab.nav-tabs li a {
    font-size: 14px;
  }
}
@media screen and (max-width: 426px) {
  .myitem-title h2 {
    font-size: 32px;
  }
  .main-item-header {
    padding-top: 40px;
  }
  .myitem-img .img1 {
    bottom: 40px;
    right: 10px;
    width: 40px;
  }
  .myitem-img .img3 {
    top: 20px;
    left: 80%;
    width: 30px;
  }
  .myitem-title-span span {
    font-size: 16px;
  }
  .myaccount_tab .nav-tabs li {
    padding: 8px 30px;
    border-radius: 5px;
    border: 1px solid rgba(162, 121, 250, 0.5);
    transition: 0.5s ease-in-out !important;
    width: 80%;
    margin: auto;
  }
  .myaccount_tab .nav-tabs {
    display: grid;
  }
  .myaccount_tab.nav-tabs li a {
    font-size: 14px;
  }
}
