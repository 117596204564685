.explorepage-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.explorepage-title h2 {
  color: #fff600;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-shadow: -2px 1.5px 0px black, -4px 2.5px 0px #612cd2;
}
.explore-title-span,
.explore-digital-assest {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.explore-digital-assest {
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
}
.explore-digital-assest img{
    max-width: 60%;
}
.explore-title-span span {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.explore-digital-assest h3 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tabbable-panel{
    width: 100%;
}
.image-box .img1{
right: 30px;
top: 30%;
width: 86px;
}
.image-box .img2{
left: 30px;
top: 30%;
width: 86px;
}
.image-box .img3{
left: 200px;
top: 0%;
height:40px;
width:40px;
}
@media screen and (max-width: 600px) {
  .explorepage-title h2 {
    font-size: 32px;
  }
  .explore-title-span span {
    font-size: 16px;
  }
  .explore-digital-assest h3 {
    font-size: 18px;
  }
  .explore-digital-assest {
    margin-top: 30px;
  }
  .explorepage .tabbable-line .nav-tabs {
    max-width: 100% !important;
  }
  .explore-digital-assest img{
    max-width: 90%;
}
.explorepage .tabbable-line .nav-tabs li a {
    font-size: 14px;
    margin-right: 0px !important;
}
.image-box .img1{
    right: 10px;
    top: 45%;
    width: 50px;
    }
.image-box .img2{
    left: 10px;
    top: 45%;
    width: 50px;
    }
.image-box .img3{
    left: 40px;
    top: -10%;
    width: 30px;
    }
}
@media screen and (min-width: 601px) and (max-width: 1023px) {
  .explorepage-title h2 {
    font-size: 60px;
  }
  .explore-title-span span {
    font-size: 18px;
  }
  .explore-digital-assest h3 {
    font-size: 25px;
  }
  .explore-digital-assest {
    margin-top: 40px;
  }
.tabbable-panel{
    width: 100% !important;
}
.explorepage .tabbable-line .nav-tabs {
    width: 100%;
  }
  .explore-digital-assest img{
    max-width: 75%;
}
.explorepage .tabbable-line .nav-tabs li a {
    font-size: 16px;
    margin-right: 0px !important;
}
.image-box .img1{
    right: 30px;
    top: 30%;
    width: 60px;
    }
.image-box .img2{
    left: 30px;
    top: 30%;
    width: 60px;
    }
.image-box .img3{
    left: 150px;
    top: -10%;
    width: 40px;
    }
}
