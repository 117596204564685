.explorepage.faq.otherpage {
  background: url("https://api.unblokdata.com/frontImgMarkeetplace/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.faqBanner {
  background: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 550px;
  display: flex;
}
.faqBanner .termsofservice {
  max-width: 1200px;
  padding: 10px;
  margin: 0px auto;
}
.faqBanner .termsofservice {
  display: grid;
  grid-template-columns: 100%;
  margin: auto;
  width: 100%;
  align-items: center;
}
.faqBanner .termsofservice h1 {
  color: #fff600;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  padding: 0;
  margin: 0;
  text-align: center;
  text-shadow: -6px 3px 0px #120b19, -8px 6px 0px #612cd2;
  margin-bottom: 30px;
}
.faqtextBox img {
  position: relative;
  right: -250px;
}
.faq-content .disc_accord > p strong,
.faq-content .disc_accord b {
  display: none !important;
}
.blog_image img {
  max-width: 360px;
  max-height: 259px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #a279fa;
  background: rgba(155, 124, 220, 0.5);
  backdrop-filter: blur(2.5px);
  padding: 5px;
}
.blogsWrapper .blogsWrapperContent {
  max-width: 374px;
  max-height: 269px;
}
.blogsWrapper .blogsWrapperContent h4 {
  max-width: 374px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blogsWrapper .blogsWrapperContent p {
  max-width: 374px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 1100px) {
  .faqBanner .termsofservice h1 {
    font-size: 50px;
  }
  .faqBanner .termsofservice {
    padding: 30px;
  }
  .faqtextBox img {
    right: -217px;
    width: 62px;
    top: -8px;
  }
}
@media screen and (max-width: 769px) {
  .termsofservice {
    display: block !important;
  }
  .faqBanner {
    height: 360px;
  }
  .faqtextBox {
    text-align: center;
  }
  .faqtextBox img {
    right: 0;
    width: 62px;
    top: -8px;
  }
  .faqBanner .termsofservice h1 {
    font-size: 40px;
  }
  .searchWraper .col-lg-6 .searchbar .serchitems,
  .searchWraper .col-lg-6 .searchbar .serchitems::placeholder {
    color: #ffffff !important;
  }
  .searchWraper .col-lg-6 .search_galass,
  .searchWraper .col-lg-6 .search_closer {
    color: rgba(162, 121, 250, 1) !important;
  }
  .faq_para {
    padding-top: 0 !important;
  }
  .blogsWrapper {
justify-content: center !important;
  }
}
@media screen and (max-width: 601px) {
  .faqBanner .termsofservice h1{
    margin-bottom:0px!important;
  }
  .faqBanner {
    height: 280px;
  }
  .faqBanner .termsofservice h1 {
    font-size: 28px;
  }
  .faqtextBox {
    text-align: center;
  }
  .faq-content .termsofservice p {
    font-size: 16px !important;
    line-height: 30px !important;
    margin: 0px;
  }
  .faq-content b {
    font-size: 16px !important;
    line-height: 30px !important;
    padding-bottom: 20px;
  }
  .faq-content .accordion-body {
    padding: 20px !important;
  }
  .faqBanner .termsofservice {
    padding-bottom: 0px;
  }
  .faqBanner .termsofservice h1 {
    font-size: 30px;
  }
  .termstextBox img,
  .eclipseWrapper img,
  .faqRightBox img {
    width: 32px !important;
  }
  .faqBanner .termsofservice {
    padding: 10px;
  }
  img#rightPurpleStar, img#leftPurpleStar {
    top: -60px;
    width: 60px!important;
  }
  .eclipseWrapper {
    padding-top: 2px;
  }
  .searchWraper .col-lg-6 {
    width: 90% !important;
  }
  .terms-accordian-stars h2 {
    font-size: 30px !important;
  }
  .FeaturedSupportArticles h2 {
    font-size: 20px !important;
  }

  .blogsWrapper {
    margin-top: 20px !important;
  }
  .blogsWrapper .blogsWrapperContent h4{
    font-size: 20px;
  }
  .blogsWrapper .blogsWrapperContent p{
    font-size: 16px;
  }
}
.faqbanner_bgcolor .faq-content p {
  color: #ffffff;
  font-family: Helvetica Neue Light !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.faq-content .accordion-button {
  padding: 0px;
}
.faq-content .accordion {
  margin-top: 87px;
}
.faq-content b {
  color: #ffffff;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px;
}
.faq-content button.accordion-button.collapsed b {
  color: #ffffff;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px;
}
.faq-content .accordion-body {
  border-radius: 5px;
  border: 2px solid rgba(162, 121, 250, 0.3);
  background: rgba(155, 124, 220, 0.15);
  backdrop-filter: blur(10px);
  padding: 69px;
}
.faq-content .accordion-button::after {
  color: #ffffff !important;
}
.faq-content .accordion-button {
  background: url("../assets//Pages//Terms-and-service/border-bottom.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}
.terms-accordian-stars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: unset;
  position: relative;
}
.terms-accordian-stars h2 {
  color: #fff;
  font-family: HelveticaNeue;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.faq-content .accordion-button {
  margin-top: 34px !important;
}
.termstextBox {
  position: relative;
}
img#leftPurpleStar {
  position: absolute;
  left: 0;
  top: -66px;
}
img#rightPurpleStar {
  position: absolute;
  right: 0;
}
.searchWraper .col-lg-6 {
  width: 70%;
  margin: auto;
}
@media screen and (min-width: 769px) {
  .searchWraper .col-lg-6 .searchbarsd {
    height: 75px;
    padding: 0;
  }
  .searchWraper .col-lg-6 .searchbar .serchitems,
  .searchWraper .col-lg-6 .searchbar .serchitems::placeholder {
    font-size: 30px !important;
    color: #ffffff !important;
  }
  .searchWraper .col-lg-6 .search_galass,
  .searchWraper .col-lg-6 .search_closer {
    font-size: 30px !important;
    color: rgba(162, 121, 250, 1) !important;
  }
}
.eclipseWrapper {
  padding-top: 33px;
  width: 33%;
  text-align: center;
}
.searchWraper .col-lg-6 .searchbar {
  padding: 0px 7px;
}
.FeaturedSupportArticles {
  background: #ffffff;
  padding-top: 66px;
  padding-bottom: 43px;
}
.FeaturedSupportArticles h2 {
  color: #612cd2;
  font-family: HelveticaNeue;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.faqRightBox img {
  position: absolute;
  top: -60px;
  right: 0;
}
@media screen and (max-width: 1025px) {
  .faq-content {
    margin-bottom: 130px;
    padding: 20px;
  }
}
.blogsWrapper {
  display: flex;
  margin-top: 43px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.blogsWrapper p {
  color: #000;
  font-family: Helvetica Neue Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 7px;
  margin-bottom: 0;
}
.blogsWrapper h4 {
  color: #000;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 39px;
}
.blogsWrapper img {
  width: 100%;
}
.blogsWrapperContent {
  margin-bottom: 20px;
}
@media screen and (min-width: 1025px) {
  .faqbanner_bgcolor .faq-content {
    padding-bottom: 110px !important;
  }
}
.searchWraper {
  display: none;
}
@media screen and (max-width: 1182px) and (min-width: 769px) {
  .blogsWrapper {
    justify-content: space-evenly;
    gap: 20px;
  }
  /* .blogsWrapper img {
    width: 90%;
    margin: auto;
  } */
}
