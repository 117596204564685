.salesItemBody {
  border-radius: 5px;
  border: 1.5px solid rgba(162, 121, 250, 0.5);
  background: rgba(155, 124, 220, 0.1);
  backdrop-filter: blur(2.5px);
  display: flex;
  padding: 5px;
  margin: 24px 0px;
}
.detail_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 0px 0px 30px;
  justify-content: center;
  gap: 10px;
}
.detail_item h5 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-bottom: 0px;
}
.detail_item img {
  width: 100%;
}

@media screen and (max-width: 1025px) and (min-width: 769px) {
  .detail-image img {
    width: 95%;
  }
  .detail_item h5 {
    font-size: 22px;
  }
  .detail_item {
    padding: 0px 0px 0px 22px;
  }
}
@media screen and (max-width: 769px) and (min-width: 601px) {
  .detail-image img {
    width: 90%;
  }
  .detail_item h5 {
    font-size: 20px;
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail_item {
    padding: 0px 0px 0px 20px;
  }
}
@media screen and (max-width: 601px) and (min-width: 426px) {
  .salesItemBody{
    display: block;
  }
  .detail-image img {
    width: 90%;
    margin: 20px auto;
  }
  .detail-image{
    text-align: center;
  }
  .detail_item h5 {
    font-size: 18px;
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail_item {
    padding: 0px 0px 0px 10px;
  }
}
@media screen and (max-width: 426px) {
  .detail-image img {
    width: 100%;
  }
  .detail_item h5 {
    font-size: 16px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail_item {
    padding: 0px 0px 0px 0px;
  }
  .salesItemBody {
    flex-direction: column;
    gap: 20px;
  }
}
