/* .dcard {

	-webkit-perspective: 1000px; 
}

.cube {
	width: 100%;
	text-align: center;
	margin: 0 auto;
	height: 200px;

	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s; 

	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d; 
}


.flippety,.flop {
	background: rgb(247, 247, 247);
	border: 1px solid rgba(147, 184, 189, .8);

	-webkit-border-radius: 5px;
	border-radius: 5px;

	-webkit-box-shadow: 0 5px 20px rgba(105, 108, 109, .3), 0 0 8px 5px rgba(208, 223, 226, .4) inset;
	box-shadow: 0 5px 20px rgba(105, 108, 109, .3), 0 0 8px 5px rgba(208, 223, 226, .4) inset;
	height: 200px;
}

.flippety {
	-webkit-transform: translateZ(100px);
	transform: translateZ(100px);
}

.flop {
	-webkit-transform: rotateX(-90deg) translateZ(-100px);
	transform: rotateX(-90deg) translateZ(-100px);
}

.cube:hover {
	-webkit-transform: rotateY(90deg);
	transform: rotateX(90deg);
} */

/* 
.button {
	font-size: .8em;
	color: #fff;
	width: 90%;
	line-height: 1.15;
	font-weight: 700;
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	padding: .95em 0;
	border-radius: .5em;
	background: rgba(74,137,202, .8);
  margin: auto;
  transition: background .3s;
}

.button:hover {
	background: rgba(74,137,202, 1);
}

.cube-container {
  text-align: center;
  perspective: 800px;
  backface-visibility: hidden;
  margin-bottom: 70px;

}

.photo-cube {
    transform-style: preserve-3d;
    width: 220px;
    height: 200px;
    transition: transform 2s ease-in-out;
}

.photo-cube:hover {
   transform: rotateY(90deg);  
}

.photo-desc {
	font-size: .85em;
	color: #fff;
	padding: 1.1em 1em 0;
	background: #345d88;
}

.front,
.back,
.right,
.left {
width: 100%;
height: 100%;
display: block;    border-radius: 10px;
position: absolute;

}

.front {
    transform: translateZ(110px);
  backface-visibility: hidden;
}

.right {
    transform: rotateY(90deg) translateZ(110px);
  
}

.back {
    transform: translateZ(-110px);
}

.left {
    transform: rotateY(-90deg) translateZ(110px);
    
} */

.card-main {
  border-radius: 5px;
  border: 1.5px solid rgba(162, 121, 250, 0.5);
  background: rgba(155, 124, 220, 0.1);
  backdrop-filter: blur(2.5px);
  overflow: hidden;
  padding: 10px;
}
.tokencardsecimg {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.tokencardinfo {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: transparent !important;
}
.tokencardsec {
  border-radius: 0px;
  box-shadow: unset;
}
.tokencardsecimgsec .tokencardsecimg {
  padding: 4px;
  height: 260px!important;
  width: 100%;
}
.tokencardsecimgsec .tokencardinfo {
  background: transparent;
}
.tokencardinfosec a .card-title {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 23px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px !important;
}
.tokencardsec .text-left {
  color: gray;
}
#Liveaudion a .card-title {
  color: black;
}
.tokencardinfoheade {
  height: 50px;
}
.tokencardinfoheade p {
  color: #fff;
  font-family: "HelveticaNeue" ;
  font-size: 19px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#Liveaudion .tokencardinfoheade p {
  color: black;
  font-family: "HelveticaNeue";
  font-size: 19px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.connectwallet {
  border: none !important;
  border-radius: 5px !important;
  background: #fff600 !important;
  color: #000 !important;
  font-size: 25px !important;
  font-weight: 400 !important;
}
#Liveaudion .connectwallet {
  border: none !important;
  border-radius: 5px !important;
  background: #612cd2 !important;
  color: white !important;
  font-size: 25px !important;
  font-weight: 400 !important;
}
#Liveaudion .tokencardlg_connectwallet:hover {
  background: #FFF600 !important;
  color: #000 !important;
  border: 1px solid #612cd2 !important;
}
@media screen and (max-width:580px){
  .tokencardsecimgsec .tokencardsecimg{
    margin-top: 6px!important;
  }
}
@media screen and (max-width: 600px) {
  .tokencardinfosec a .card-title {
    font-size: 20px !important;
  }
  .tokencardinfoheade p,#Liveaudion .tokencardinfoheade p {
    font-size: 18px !important;
  }
  /* .tokencardlg_connectwallet.connectwallet{
    width: 100px !important;
  } */
}
#allcategories .col-xl-3 {
  padding: 6px!important;
}
#allcategories .tokencardinfo{
  padding: 4px!important;
}
#allcategories .tokencardlg_connectwallet{
color: #000;
font-family: "HelveticaNeue";
font-size: 19.347px!important;
font-style: normal;
font-weight: 400;
line-height: normal;
}
#allcategories .tokencardsec{
  margin-bottom:20px!important;
}