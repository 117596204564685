/* @media (prefers-reduced-motion: no-preference) {
  .img1,.img2 {
    animation: img-spin infinite 20s linear;
  }
}
@keyframes img-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.nav-tabs {
  border-radius: 5px;
  border: 1px solid rgba(162, 121, 250, 0.5);
  background: rgba(97, 44, 210, 0.3);
  justify-content: space-evenly;
  display: flex;
  margin-top: 25px;
  padding: 8px 0px;
  width: 100%;
}
.nav-tabs li a {
  color: white;
}
.nav-tabs li.active {
  background: transparent;
  color: #fff;
}
.nav-tabs li.active a {
  font-weight: 400 !important;
}
.nav-tabs li.active:hover {
  color: #000;
}
.nav-tabs li.active a {
  color: #a279fa;
}
.info-tags {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.instrock_al span {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.token-detail {
  display: flex;
  justify-content: space-between;
}
.token-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}
.token-data h3 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px !important;
}
.token-data h2 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.token-data2 {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid rgba(162, 121, 250, 0.3);
  background: rgba(133, 100, 201, 0.25);
  backdrop-filter: blur(10px);
  padding: 20px;
}
.data-token {
  display: flex;
  justify-content: space-between;
}
.data-token h3 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px !important;
}
.bidnowbtn .connectwallet {
  margin-top: 30px;
  width: 230px;
  height: 50px;
}
.connectwallet:hover {
  border: 1px solid #a279fa !important;
  color: #fff !important;
}
#Liveaudion .connectwallet:hover {
  border: 1px solid #d8d107 !important;
  color: black !important;
  background: #FFF600 !important;
}
.connectwallet_bids {
  background: #a279fa !important;
  color: white !important;
}
.connectwallet_bids:hover {
  border: 1px solid #a279fa !important;
  color: white !important;
}
#shareicons .Share_modle {
  border-radius: 5px;
  border: 4px solid rgba(162, 121, 250, 0.3) !important;
  background: rgba(133, 100, 201, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  padding: 5px;
}
#shareicons .Share_modle_header,
#shareicons .Share_modle_body,
#shareicons .Share_modle_footer {
  background: transparent !important;
}
#shareicons .Share_modle_header h5 {
  color: #fff600;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#shareicons .Share_modle_footer {
  padding-bottom: 10px !important;
}
#shareicons .Share_modle_footer .bidbtn {
  border-radius: 5px !important;
  background: #fff600 !important;
  width: 80%;
  height: 40px;
  color: black !important;
  position: unset;
}
.infoPage-title-span {
  display: flex;
  gap: 5px;
}
.infoPage {
  padding-top: 50px;
  margin-bottom: 10px;
}
.infoPage-title-span span {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.info-img .img3 {
  right: 20px;
  top: 50px;
  width: 40px;
}
.infosec .row {
  padding-bottom: 120px!important;
}
.info-img .img1 {
  left: 200px;
  margin-top: 40px;
  width:80px;
}
.info-img .img2 {
  right: -30px;
  bottom: 150px;
  width:80px;
}
.activity-tabs{
  max-width: 700px;
  padding: 50px 0 30px 20px;
}
.activity-tabs h4{
  color: #fff;
}
.activity-tabs .nav-tabs{
  background: transparent;
  justify-content: unset;
  border: unset;
  gap: 50px;
  margin-top: 0px;
}
#searchiconsd{
  display: block;
  width: 100%;
}
@media screen and (max-width: 601px) {
  .nav-tabs {
    width: 100% !important;
  }
  .infolist h2 {
    font-size: 30px;
  }
  .instrock_al span {
    font-size: 18px;
  }
  .infosec p {
    font-size: 14px;
  }
  .token-data h2 {
    font-size: 18px;
  }
  .token-data h3,
  .data-token h3 {
    font-size: 14px;
  }
  .data-token {
    flex-direction: column;
  }
  .token-detail {
    flex-direction: column;
  }
  .img-rotate {
    background-image: url("https://api.unblokdata.com/Markeetplace/devider_vartical.svg");
    height: 1px;
  }
  .image-rotate {
    display: none;
  }
  .bidnowbtn {
    justify-content: space-evenly !important;
  }
  .connectwallet {
    width: 165px;
    height: 45px;
    margin: 0px !important;
    font-size: 18px !important;
  }
  #shareicons .Share_modle_header h5 {
    font-size: 25px;
  }
  .infoPage-title-span span {
    font-size: 14px;
  }
  .info-img .img3 {
    right: 20px;
    top: 15px;
    width: 30px;
  }
  .info-img .img1 {
    left: 70%;
    bottom: 37%;
    width: 50px;
  }
  .info-img .img2 {
    right: 10px;
    bottom: 30px;
    width: 50px;
  }
}

@media screen and (max-width: 770px) {
  .info-img .img2, .info-img .img1, .info-img .img3 {
    display: none;
  }
  .nav-tabs {
    width: 100%;
  }
  .infolist h2 {
    font-size: 40px;
  }
  .instrock_al span {
    font-size: 20px;
  }
  .infosec p {
    font-size: 16px;
  }
  .token-data h2 {
    font-size: 20px;
  }
  .token-data h3,
  .data-token h3 {
    font-size: 16px;
  }
  .data-token {
    flex-direction: column;
  }
  #shareicons .Share_modle_header h5 {
    font-size: 30px;
  }
  .infoPage-title-span span {
    font-size: 16px;
  }
  .info-img .img3 {
    right: 20px;
    top: 30px;
    width: 40px;
  }
  .info-img .img1 {
    left: 150px;
    bottom: 37%;
    width: 50px;
  }
  .info-img .img2 {
    right: 20px;
    bottom: 50px;
    width: 50px;
  }
}
@media screen and (min-width: 1020px) and (max-width: 1200px) {
  .infolist h2 {
    font-size: 50px;
  }
  .instrock_al span {
    font-size: 22px;
  }
  .infosec p {
    font-size: 18px;
  }
  .token-data h2 {
    font-size: 25px;
  }
  .token-data h3,
  .data-token h3 {
    font-size: 18px;
  }
  #shareicons .Share_modle_header h5 {
    font-size: 35px;
  }
  .infoPage-title-span span {
    font-size: 18px;
  }
  .info-img .img3 {
    right: 50px;
    top: 30px;
    width: 40px;
  }
  .info-img .img1 {
    left: 180px;
    bottom: 180px;
  }
  .info-img .img2 {
    right: 50px;
    bottom: 50px;
   
  }
}
@media screen and (min-width: 1430px) {
  .table {
    margin-top: 30px;
  }
  .infoPage {
    padding-top: 80px;
  }
}
