.disclaimerPge {
  background: url("../assets//Pages/Disclaimer/BackgroundImage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  background-position: 0px -52px;
}
.disclaimBanner .termsofservice,
.topBannerDisclaimer {
  max-width: 1200px;
  padding: 10px;
  margin: 0px auto;
}
.topBannerDisclaimer {
  padding-bottom: 100px !important;
}
.disclaimBanner .termsofservice {
  display: grid;
  grid-template-columns: 60% 40%;
  margin: auto;
  width: 100%;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 60px;
}
.termsimageBox {
  text-align: center;
}
.termsimageBox img {
  width: 343px;
  height: 343px;
}
@media screen and (max-width: 601px){
.termsimageBox img {
    width: 80% !important;
    /* height: auto !important; */
    margin-top: 20px;
}
}
.disclaimBanner .termsofservice h1 {
  color: #fff600;
  font-family: "HelveticaNeue";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  padding: 0;
  margin: 0;
  text-align: left;
  text-shadow: -6px 3px 0px #120b19, -8px 6px 0px #612cd2;
}
.disclaimBanner .topBannerDisclaimer h4 {
  color: rgba(255, 255, 255, 1);
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.disclaimBanner .topBannerDisclaimer p {
  color: rgba(255, 255, 255, 1);
  font-size: 25px;
  font-style: normal;
  line-height: normal;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 30px;
  font-family: "Helvetica Neue Light";
}
.termstextBox img {
  position: relative;
  right: -250px;
}
.disclaimer-sevice-content .disc_accord > p strong,
.disclaimer-sevice-content .disc_accord b {
  display: none !important;
}
@media screen and (max-width: 1100px) {
  .disclaimBanner .termsofservice h1 {
    font-size: 50px;
  }
  .disclaimBanner .termsofservice h4 {
    font-size: 24px;
  }
  .disclaimBanner .termsofservice {
    padding: 30px;
  }
  .termstextBox img {
    right: -217px;
    width: 62px!important;
    top: -8px;
  }
}
@media screen and (max-width: 769px) {
  .termsofservice {
    display: block !important;
  }
  .disclaimBanner {
    height: auto;
  }
  .termstextBox {
    text-align: center;
  }
  .termstextBox img {
    right: 0;
    width: 62px;
    top: -8px;
  }
}
@media screen and (max-width: 601px) {
  .disclaimBanner .termsofservice h1 {
    font-size: 34px;
  }
  .topBannerDisclaimer h4 {
    font-size: 18px !important;
  }
  .disclaimBanner .termsimageBox img {
    width: 60%;
    margin: auto;
    height: 200px;
  }
  .topBannerDisclaimer p {
    font-size: 16px !important;
    line-height: 30px !important;
    margin-top: 0px !important;
  }
  .disclaimer-sevice-content .termsofservice p {
    font-size: 16px !important;
    line-height: 30px !important;
    margin: 6px;
  }
  .topBannerDisclaimer {
    padding-bottom: 60px !important;
  }
  .faq_anstxtclr p,
  .faq_anstxtclr,
  .faq_anstxtclr li {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .disclaimer-sevice-content h6 {
    font-size: 16px !important;
    line-height: 30px !important;
    padding-bottom: 20px;
  }
  .disclaimer-sevice-content .accordion-body {
    padding: 20px !important;
  }
  .disclaimer-sevice-content {
    margin-bottom: 80px !important;
  }
}
.disclaimBanner_bgcolor {
  background: url("../assets//Pages/Disclaimer/Background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.disclaimer-sevice-content .termsofservice p {
  color: #000;
  font-family: Helvetica Neue Light !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.disclaimer-sevice-content .accordion-button {
  padding: 0px;
}
.disclaimer-sevice-content .accordion {
  padding-top: 87px;
}
.disclaimer-sevice-content h6 {
  color: #612cd2;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px;
}
.disclaimer-sevice-content button.accordion-button.collapsed h6 {
  color: #090806;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px;
}
.disclaimer-sevice-content .accordion-body {
  border-radius: 5px;
  border: 2px solid rgba(162, 121, 250, 0.3);
  background: rgba(155, 124, 220, 0.15);
  backdrop-filter: blur(10px);
  padding: 69px;
}
.disclaimer-sevice-content .accordion-button::after {
  color: #000000 !important;
}
.disclaimer-sevice-content .accordion-button {
  background: url("../assets//Pages//Terms-and-service/border-bottom.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
.disclaimer-sevice-content {
  margin-bottom: 130px;
}

.terms-accordian-stars img{
  float: right;
  width: 100px;
}
.disclaimer-sevice-content .disc_accord.pt-5 {
  display: none;
}
@media screen and (max-width: 769px) {
  .disclaimBanner .termsofservice h1 {
    text-align: center;
  }
  .terms-accordian-stars img{
    width: 60px;
  }
}