.privacyBanner {
    background: url("../assets//Pages//Terms-and-service/HeaderBackground.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
    height: 500px;
    display: flex;

  }
  .privacyBanner .termsofservice {
    max-width: 1200px;
    padding: 10px;
    margin: 0px auto;
  }
  .privacyBanner .termsofservice {
    display: grid;
    grid-template-columns: 60% 40%;
    margin: auto;
    width: 100%;
    align-items: center;
  }
  .termsimageBox {
    text-align: center;
  }
 #privacyImageBox img{
    width: 80%;
  }
  .privacyBanner .termsofservice h1 {
    color: #ffffff;
    text-align: center;
    font-family: "HelveticaNeue";
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  .termstextBox img {
    position: relative;
    right: -250px;
  }
  .privacy-policy-content .disc_accord > p strong,
  .privacy-policy-content .disc_accord b {
    display: none !important;
  }
  @media screen and (max-width: 1100px) {
    .privacyBanner .termsofservice h1 {
      font-size: 50px;
    }
    .privacyBanner .termsofservice {
      padding: 30px;
    }
    .termstextBox img {
      right: -217px;
      width: 62px;
      top: -8px;
    }
  }
  @media screen and (max-width: 769px) {
    .termsofservice {
      display: block !important;
    }
    .privacyBanner {
      height: auto;
    }
    .privacyBanner .termsofservice h1 {
      text-align: center;
    }
    .termstextBox {
      text-align: center;
    }
    .termstextBox img {
      right: 0;
      width: 62px;
      top: -8px;
    }
  }
  @media screen and (max-width: 601px) {
    .privacyBanner .termsofservice h1 {
      font-size: 30px;
      text-align: center;
    }
    .termstextBox {
      text-align: center;
    }
    #privacyImageBox img {
      width: auto!important;
      margin: auto;
      height: 200px!important;
    }
    .privacy-policy-content .termsofservice p {
      font-size: 16px !important;
      line-height: 30px!important;
      margin:0px;
    }
    .privacy-policy-content h6{
      font-size: 16px !important;
      line-height: 30px !important;
      padding-bottom: 20px;
    }
    .privacy-policy-content .accordion-body{
      padding: 20px!important;
    }
    .privacyBanner .termsofservice{
      padding-bottom:0px;
      }
  }
  .privacybanner_bgcolor {
    background: url("../assets/Pages/PrivacyPolicy/body-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .privacybanner_bgcolor .privacy-policy-content .termsofservice p {
    color: #000;
    font-family: Helvetica Neue Light!important;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
  .privacy-policy-content .accordion-button {
    padding: 0px;
  }
  .privacy-policy-content .accordion {
    margin-top: 87px;
  }
  .privacy-policy-content h6 {
    color: #612cd2;
    font-family: "HelveticaNeue";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 75px;
  }
  .privacy-policy-content button.accordion-button.collapsed h6 {
    color: #090806;
    font-family: "HelveticaNeue";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 75px;
  }
  .privacy-policy-content .accordion-body {
    border-radius: 5px;
    border: 2px solid rgba(162, 121, 250, 0.3);
    background: rgba(155, 124, 220, 0.15);
    backdrop-filter: blur(10px);
    padding: 69px;
  }
  .privacy-policy-content .accordion-button::after {
    color: #000000 !important;
  }
  .privacy-policy-content .accordion-button {
    background: url("../assets//Pages//Terms-and-service/border-bottom.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .privacy-policy-content{
      margin-bottom: 130px;
  }
  /* .terms-accordian-stars{
    text-align: end!important;
    display: unset;
  } */