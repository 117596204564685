@font-face {
  font-family: "HelveticaNeue";
  src: url("https://db.onlinewebfonts.com/t/0d49fc455f4a8951a42daf952412a713.eot");
  src: url("https://db.onlinewebfonts.com/t/0d49fc455f4a8951a42daf952412a713.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/0d49fc455f4a8951a42daf952412a713.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/0d49fc455f4a8951a42daf952412a713.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/0d49fc455f4a8951a42daf952412a713.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/0d49fc455f4a8951a42daf952412a713.svg#Helvetica Neue Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue Light";
  src: url("https://db.onlinewebfonts.com/t/0e6de1ec911a2e267ff136bbdd384a44.eot");
  src: url("https://db.onlinewebfonts.com/t/0e6de1ec911a2e267ff136bbdd384a44.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/0e6de1ec911a2e267ff136bbdd384a44.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/0e6de1ec911a2e267ff136bbdd384a44.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/0e6de1ec911a2e267ff136bbdd384a44.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/0e6de1ec911a2e267ff136bbdd384a44.svg#Helvetica Neue Light")
      format("svg");
}
@font-face {
  font-family: "Helvetica Neue outline";
  src: url("https://db.onlinewebfonts.com/t/88041027a757e084e649d474e8917758.eot");
  src: url("https://db.onlinewebfonts.com/t/88041027a757e084e649d474e8917758.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/88041027a757e084e649d474e8917758.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/88041027a757e084e649d474e8917758.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/88041027a757e084e649d474e8917758.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/88041027a757e084e649d474e8917758.svg#Helvetica Neue LT W04 75 B Ou")
      format("svg");
}
html body .container {
  max-width: 1200px !important;
  padding: 0px 20px !important;
}
.card {
  font-size: 0.875rem;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  overflow: auto;
  scrollbar-color: #008cff rgba(0, 0, 0, 0.3);
  scrollbar-width: thin;
  font-family: "HelveticaNeue" !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  letter-spacing: 0.5px;
  -webkit-tap-highlight-color: rgb(0, 0, 0);
}
html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
a {
  text-decoration: none !important;
}
body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px !important;
  text-align: left;
  background-color: #ffffff !important;
  font-family: "HelveticaNeue";
  color: #787878 !important;
  overflow-x: hidden;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "HelveticaNeue";
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Helvetica Neue Light";
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background-color: #a279fa;
}

/* Loader */
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #ffffff 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #ffffff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

/* scrollbar */
.custom-container {
  max-width: 1200px !important;
  margin: auto;
}
.container-theme {
  max-width: 50%;
  margin: auto;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: end !important;
}

.bodyoverflow {
  overflow: hidden;
}
.homelinkswhole {
  list-style: none;
}

.searchiconsd {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
}
.learnmore a {
  color: #00affe;
  font-size: 20px;
  text-shadow: 1px 2px #000;
}
.searchiconsd i {
  color: #d6d6d6;
  z-index: 0;
  font-size: 14px;
}
.homelinks {
  display: flex;
  align-items: center;
  justify-content: end;
}
.homelinkswhole {
  display: flex;
  margin-bottom: 0;
  padding: 0;
  justify-content: space-evenly;
  max-width: 100%;
  width: 700px;
}
.pHieT .selected {
  background-color: #000 !important;
}
#cancelOrder .modal-body .ownernane {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #000 !important;
  text-align: center !important;
  padding: 0;
}
#cancelOrder .modal-body .owneradd {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #888 !important;
  text-align: center !important;
  padding: 0;
}
#cancelOrder .modal-body h5 {
  color: #00affe;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
  padding-top: 10px;
}
::placeholder {
  color: #000 !important;
}
.searchbar .serchitems::placeholder,
.searchbarsd i {
  color: #a279fa80 !important;
  font-size: 22px !important;
}
.searchbar .serchitems {
  padding: 6px !important;
}
@media screen and (max-width: 601px) {
  .searchbar {
    padding: 6px !important;
  }
  html body .searchbar .serchitems::placeholder {
    color: #a279fa80 !important;
    font-size: 14px !important;
  }
}
.searchbar {
  width: 100%;
  padding: 16px;
  background-color: #fff;
  max-width: 100%;
  border-radius: 30px;
  border: 2px solid #d3d3d3 !important;
}
.navbar.scroll .searchbar {
  border: 2px solid rgb(229 232 235 / 23%) !important;
}
.listeitems {
  position: absolute;
  width: 100%;
}
.searchbar p {
  background-color: #000;
  margin-bottom: 0;
  padding: 10px;
}
/* .navbar.scroll .searchbar{background-color: transparent !important;} */
.searchbar p:hover {
  background-color: #fff;
  color: #000;
}
.searchbar p:last-child {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.searchbar .serchitems {
  --webit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  color: #000;
}
.searchbarsd i {
  color: #000;
}

.searchbar .serchitems:focus-visible {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  transform: none !important;
}
.searchbar .serchitems:focus {
  border: none;
  box-shadow: none;
  outline: none;
  transform: none;
}
.searchbar .serchitems::placeholder {
  color: #ffffffb3;
  font-size: 16px;
  height: 100%;
  font-weight: 400;
}
.searchbar {
  /* position: absolute; */
  cursor: pointer;
}

.searchlist.active {
  display: block;
}
.searchlistsec .createitemsec {
  bottom: 20px;
  position: absolute;
  left: 25%;
}
.searchlistsec img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
.searchlistseclist h5 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: "HelveticaNeue";
}
.searchlistseclist h4 {
  color: #8a939b;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  font-family: "HelveticaNeue";
}
.searchlistsec::-webkit-scrollbar {
  display: none !important;
}
.srecat {
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  cursor: pointer;
  margin-top: 15px;
}
.tabsicons .searchlistsec {
  min-height: 70px;
  max-height: 220px;
  overflow-y: scroll;
  cursor: pointer;
}
.loadmorebtn .buybtn {
  border-radius: 12px;
  padding: 10px 20px !important;
}
.not_found_text {
  font-size: 36px;
  font-weight: 400;
}
.not_found_text_sub {
  font-size: 16px;
  color: #000;
}
#buynow .modal-footer {
  flex-direction: row;
}
#buynow .modal-footer .buybtn {
  width: unset;
  padding: 6px 20px !important;
  margin-bottom: 0;
}
#buynow .modal-footer .bidbtn {
  width: unset;
  padding: 6px 20px !important;
}
.searchlist {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  gap: 20px;
  background-color: rgba(133, 100, 201, 0.25);
  border-radius: 25px;
  /* position: absolute; */
  width: 100%;
  left: 0px;
  top: 50px;
  border-radius: 10px;
  height: 240px;
  /* box-shadow: 0 0 11px 1px #00000030; */
  display: none;
  min-height: 450px;
  max-height: 450px;
}
.searchbarexplore .searchlist {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  gap: 20px;
  background-color: #000;
  border-radius: 5px;
  position: absolute;
  width: 350px;
  left: 0px;
  top: 50px;
  height: 240px;
  box-shadow: 0 0 5px 1px #00000012;
  display: none;
  min-height: 260px;
  max-height: 260px;
  z-index: 99;
}
.searchbarexplore .searchlist.active {
  display: block;
}
.searchlist .createitemsec {
  position: static;
  bottom: 36px;
  left: 0;
  right: 0;
  text-align: center;
  width: 200px;
  margin: auto;
  padding-top: 15px;
}
.fa-pencil-alt {
  color: #787878;
}
.searchbarmob input[type="text"] {
  --webit-appearance: none;
  appearance: none;
  border: 1px solid #9d9d9e;
  background-color: transparent;
  border-radius: 20px;
  padding: 5px;
  width: 90%;
  box-shadow: 0 0 5px 1px #00000012;
}
.bidbtn.modal_cancelbtn {
  color: #0266cb !important;
}
.searchbarmob input[type="text"]:focus-visible {
  --webit-appearance: none;
  appearance: none;
  border: 1px solid #0d6efd;
  background-color: transparent;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 0 0 5px 1px #00000012;
}
.explorefles {
  display: flex;
  align-items: center;
  width: 100%;
}
.searchbarmob {
  position: relative;
}
.searchbarmob input[type="text"]:focus {
  --webit-appearance: none;
  appearance: none;
  border: 1px solid #0d6efd;
  background-color: transparent;
  border-radius: 20px;
  padding: 5px;
  box-shadow: 0 0 5px 1px #00000012;
}
.collectionslsd {
  padding: 10px 15px !important;
  font-size: 16px !important;
  color: #fff !important;
  font-family: "HelveticaNeue" !important;
  /* border-bottom: 1px solid rgb(53 56 64 / 19%) !important; */
  margin: 0 !important;
  text-align: left !important;
}
.arryasearch {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 12px;
  transition: 0.5s ease;
  /* border-bottom: 1px solid rgb(53 56 64 / 19%); */
}
.arryasearch:last-child {
  border-bottom: none;
}
ol li {
  list-style: none;
}
.navbar {
  position: relative !important;
  top: -1px;
  padding: 9px 0;
  background-color: transparent !important;
  /* border-bottom: 1px solid #00000021; */
  z-index: 89;
  width: 100%;
}
.infodropdown .dropdown-menu.show {
  background: #444;
  box-shadow: 0 0 9px #00000038;
  padding: 0;
  border-radius: 8px;
}
.infodropdown .dropdown-item {
  color: #fff;
  border-radius: 8px;
}
.infodropdown .dropdown-item a {
  color: #fff;
}
.infodropdown .dropdown-item:hover {
  background: linear-gradient(180deg, #00b1ff 0%, #005dff 100%) 0% 0%;
  color: #fff;
  box-shadow: 0 0 9px #00000038;
}
.connectwallet:hover {
  /* background: linear-gradient(180deg, #005DFF 0%, #00B1FF 100%) 0% 0%; */
  background: rgba(133, 100, 201, 0.25) !important;
  color: #fff600 !important;
  text-decoration: none;
}
.connectwallet {
  background: #0071e3 !important;
  border: 1px solid #0071e3 !important;
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
  /* text-transform: capitalize !important; */
  border: none;
  border-radius: 30px !important;
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}

.modal-dialog .buybtn:hover {
  /* background: linear-gradient(180deg, #005DFF 0%, #00B1FF 100%) 0% 0%; */
  background: #fff !important;
  color: #00b1ff !important;
  text-decoration: none;
}
.modal-dialog .buybtn {
  /* background: linear-gradient(180deg, #00B1FF 0%, #005DFF 100%) 0% 0%; */
  background: #0071e3 !important;
  border: 1px solid #0071e3 !important;
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize !important;
  border: none;
  border-radius: 30px !important;
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.connectwallet1 {
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize !important;
  border: 2px solid #00b1ff !important;
  border-radius: 10px !important;
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;

  text-decoration: none;
}
.connectwallet1:hover {
  background: linear-gradient(180deg, #00b1ff 0%, #005dff 100%) 0% 0% !important;
  color: #fff !important;
}
.modal-body .buybtn {
  width: 80% !important;
}
#putonsale .modal-body .buybtn {
  font-size: 14px;
}
.change_pricesd {
  text-align: right !important;
}

.modalbtns {
  background: linear-gradient(180deg, #00b1ff 0%, #005dff 100%) 0% 0%;
  color: #ffff !important;
  cursor: pointer;
  font-size: 14px;
  font-family: "HelveticaNeue";
  border: none;
  border-radius: 10px !important;
  transition: 0.5s ease-in-out !important;
  padding: 4px 45px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.modalbtns:hover {
  background: linear-gradient(180deg, #005dff 0%, #00b1ff 100%) 0% 0%;
  text-decoration: none;
}
.bannerimgesdf {
  position: relative;
}
.downfarrow {
  width: 25px !important;
  height: 25px !important;
  max-width: 100% !important;
  min-height: unset !important;
  max-height: unset !important;
  transform: rotateZ(90deg);
  position: absolute;
  top: 383px;
  z-index: 999;
  transform: rotate(0deg);
  left: 481px;
}
#putonsale .connectwallet {
  padding: 7px 38px !important;
}
#putonsale .modal-content {
  width: 680px !important;
}
.cancelbtn:hover {
  background: #00a5ff;
  text-decoration: none;
  color: #fff !important;
}
.cancelbtn {
  background: none;
  color: #000 !important;
  cursor: pointer;
  font-family: "HelveticaNeue";

  font-size: 16px;
  border: 2px solid #00a5ff !important;
  border-radius: 10px !important;
  transition: 0.5s ease-in-out !important;

  padding: 5px 25px !important;
  font-weight: 400 !important;

  text-decoration: none;
}
.arryasearch:hover {
  background: rgba(155, 124, 220, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 5px;
}
.createitemsec .connectwallet:hover {
  background: none;
  border: 1px solid #005dff !important;
  transition: 0.5s ease-in-out !important;
}
.createitemsec .connectwallet {
  background: linear-gradient(180deg, #00b1ff 0%, #005dff 100%) 0% 0%;
  color: #ffff !important;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #005dff !important;
  border-radius: 8px !important;
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.homebanerheight .connectwallet {
  padding: 10px 25px !important;
}
.homelinksmobile {
  display: none;
}
/* .expore.connectwallet{padding: 10px 20px !important;} */
#mobilenav {
  position: absolute;
  width: 100% !important;
  right: -497px;
  top: 0px;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  box-shadow: 0 0 12px #00000045;
}
.home_links {
  position: absolute;
  height: 100%;
  width: 50%;
  background: #f1f1f1;
  animation: slide 0.5s forwards;
  right: 0;
}
#mobilenav.show {
  right: 0px;
  position: absolute;
  display: block;
  z-index: 0;
}
@-webkit-keyframes slide {
  0% {
    right: -497px;
  }
  100% {
    right: 0px;
  }
}

@keyframes slide {
  0% {
    right: -497px;
  }
  100% {
    right: 0px;
  }
}
.burger {
  display: none;
}
.burger.clicked p:first-child {
  transform: rotate(45deg);
  background-color: #000;
}
.burger.clicked p:nth-child(2) {
  display: none;
}
.burger.clicked p:last-child {
  transform: rotate(-45deg);
  margin-top: -2px;
  background-color: #000;
}
.burger:focus-visible {
  outline: none;
}
.burger {
  position: absolute;
  border: none;
  right: 19px;
  z-index: 99;
  background: none;
}
button:focus:not(:focus-visible) {
  box-shadow: none;
}
.burger p:first-child {
  background-color: #fff;
  height: 3px;
  width: 30px;
  border-radius: 50px;
  margin-bottom: 0;
}
.burger p:nth-child(2) {
  background-color: #fff;
  height: 3px;
  width: 30px;
  border-radius: 50px;
  margin-top: 6px;
  margin-bottom: 0;
}
.listnone {
  display: none;
}
.overflowbody {
  overflow: hidden;
}
.burger p:last-child {
  margin-top: 6px;
  background-color: #fff;
  height: 3px;
  width: 30px;
  margin-bottom: 0;
  border-radius: 50px;
}

.noresultfound1 {
  display: none;
}
.noresultfound {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  color: #fff;
}
.list {
  margin: 0;
}
.listitem {
  list-style: none;
  color: #fff;
  cursor: pointer;
}
.bannerimges {
  text-align: center !important;
}
.mobileview,
.tabview {
  display: none !important;
}
.tabsicons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
header {
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
    0 7px 12px -5px rgb(33 33 33 / 46%) !important;
}
.header_main {
  text-align: center;
}
.header_main img {
  max-width: 100% !important;
  width: 800px;
  margin-bottom: 20px;
}

/* header */
.home_header {
  background-color: transparent !important;
  position: relative;

  margin-top: -2px !important;
  min-height: calc(100vh - 450px) !important;
}

.home_header header {
  z-index: 99;
  box-shadow: none !important;
}
.homebanner {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  max-height: 100%;
  background-color: transparent !important;
}

.homebanner .custom-container {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
}
/* .tokencardsecimg img:hover {
  transform: scale(1.05) !important;
  transition: 0.5s ease-out;
} */
.bannernftcard {
  position: relative;
  text-align: center;
}
.searchbar:hover,
.searchbar:focus-visible,
.searchbar {
  /* background-color: #626262; */
  border: 1px solid rgba(162, 121, 250, 0.5) !important;
  box-shadow: 0px 0px rgba(162, 121, 250, 0.5);
  outline: none;
}
/* .homebanner{padding-top: 121px;} */
.homebanner img {
  -webkit-filter: brightness(1);
  filter: brightness(1);
}

.mobile_search .searchbarsd {
  border: px solid rgba(162, 121, 250, 0.5) !important;
  margin-top: 8px !important;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 10px;
  width: 100% !important;
}
.homebanerheight h1 {
  font-size: 100px;
  color: #fff;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
}
.homebanerheight h4 {
  margin-bottom: 50px;
}
.seathbat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.seathbats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.owdlslider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  overflow: hidden;
}
.infosec .tab-content .ownder {
  /* height: 550px; */
  overflow-y: auto;
}
.polugonbalances {
  text-align: center;
  margin-bottom: 20px;
}
#putonsale h5 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
}
#price_item_modal .approve_text {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-align: left;
  padding: 0;
}
#price_item_modal .approve_desc {
  font-size: 14px;
  font-weight: 400;
  color: #888;
  text-align: left;
  padding: 0;
}
#putonsale h5 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 0 auto;
}
.owl-nav.disabled {
  margin: 0 !important;
}
.owdlslider img {
  max-width: 100%;
  object-fit: cover;
  transition: 0.5s ease-out;
}
.owdlslider img:hover {
  transform: scale(1.05) !important;
  transition: 0.5s ease-out;
}
.owdlsliderpara {
  position: absolute;
  bottom: -19px;
  left: 20px;
  z-index: 2;
}
.owdlsliderpara h2 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}
.owdlsliderpara p {
  margin: 0;
  font-size: 14px !important;
  color: #fff;
}
.owl-carousel .owl-nav.disabled {
  display: block !important;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  opacity: 0;
}
.owl-prev span {
  font-size: 31px;
  color: #000;
}

.owl-carousel:hover .owl-nav button.owl-prev {
  top: 40% !important;
  left: -8px;
  opacity: 1;
  background: #ffffff !important;

  padding: 1px 14px !important;
  margin: 0;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 8px;
}
.owl-next span {
  font-size: 31px;
  color: #000;
}
.searchbarsd {
  display: flex;
  align-items: center;
  padding: 0px 5px;
}

.owl-carousel:hover .owl-nav button.owl-next {
  top: 40% !important;
  right: -6px;
  opacity: 1;
  background: #ffffff !important;

  padding: 1px 14px !important;
  margin: 0;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 8px;
}

.owl-theme .owl-dots .owl-dot.active {
  background: #16161a99 !important;
  width: 70px;
  margin: 0 !important;
  height: 6px;
  border-radius: 50px !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: #16161a14 !important;

  width: 70px !important;
  border-radius: 0px !important;
  margin: 0 auto !important;
  height: 6px !important;
}
.owl-carousel .owl-nav button.owl-next:hover {
  padding: 3px 15px !important;
  transform: scale(1.05);
}
.owl-carousel .owl-nav button.owl-prev:hover {
  padding: 3px 15px !important;
  transform: scale(1.05);
}
.owdlslider::before {
  position: absolute;
  content: "";
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgba(0, 0, 0, 0.4654236694677871) 100%
  );
  width: 100%;
  height: 23%;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 1;
}
.owl-theme .owl-dots button:first-child span {
  border-radius: 15px 0 0 15px !important;
}
.owl-theme .owl-dots button:last-child span {
  border-radius: 0px 15px 15px 0px !important;
}
.homebanerheight p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 30px;
  font-family: "HelveticaNeue";
  text-align: left;
}
/* .homebanerheight{position: relative;} */
.homebanner {
  position: relative;
  text-align: center;
}
.bannerimges img {
  max-height: 400px;
  min-height: 400px;
}
.bannerimges {
  position: absolute;
  bottom: 0;
  top: 0;
}
.homebanner .tokencardsec {
  margin-left: 30px;
  max-width: 320px;
  margin-top: 30px;
}
.homebanner .timercontent {
  top: 20px !important;
  bottom: unset;
  transform: translate(-68%, 0%);
}
.homebanner .backsideimge {
  position: absolute;
  left: 62px;
  opacity: 0.5;
  top: 0;
  transform: scale(0.9);
  z-index: 9;
}

.swiper {
  z-index: 99 !important;
}
.word_brak_txt {
  color: #888;
}
.homebanner .heart i {
  color: #505050;
  font-size: 24px;
  margin-right: 5px;
}
.homebanner .heart {
  color: #a7a7a7 !important;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.homebanner .tokencardinfoheade p {
  text-align: right;
}
.homebanner .tokencardinfo {
  background-color: #363636;
  margin-top: 0;
}
.homebanner .tokencardinfosec h3 {
  color: #fff;
  margin-top: 10px;
}
.homebanner .tokencardinfoheade h4 {
  margin: 0;
}
.homebanner .tokencardinfoheade {
  margin: 0;
}
.timercontent i {
  color: #fff;
  margin-right: 10px;
}
.homebanerheight h1 {
  font-size: 56px;
  color: #000;
  font-weight: 400;
  line-height: 1.2;
  font-family: "HelveticaNeue";
  /* text-shadow:1px 2px #000 */
}
.react-select__control:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  transform: none !important;
}
.react-select__control:focus-visible {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  transform: none !important;
}
.react-select__control:hover {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  transform: none !important;
}

#placeabid .react-select__option {
  text-align: left;
  padding: 2px 11px;
}
.homebanerheight h4 {
  font-size: 90px;
  font-weight: 900;
  line-height: 1.2;
  font-family: "HelveticaNeue" !important;
  color: #000;
  text-align: center;
}

.homebanerheight h5 {
  font-size: 28px;
  color: #fff !important;
  font-weight: 500;
  line-height: 1.2;
  font-family: "HelveticaNeue";
  color: #fff;
  text-align: center;
  margin-top: 20px;
  margin: 0 !important;
  letter-spacing: 4px;
  margin-bottom: 20px !important;
  text-shadow: 1px 2px #000;
}

.homebanerheight h4 .spanclasd {
  font-size: 78px;
  font-weight: 900;
  line-height: 1.2;
  font-family: "HelveticaNeue";
  color: #000;
  text-align: center;
}

/* header */
.kdiejr {
  margin: 0 20px;
}

.footerhead img {
  width: 110px;
}

.footerhead ul li span {
  margin-right: 12px;
}
.quicklinks ul {
  padding: 0;
}
.footersociallinks .foot_soc {
  font-size: 39px;
  margin-right: 10px;
  transition: 0.5s ease-in;
  color: #000;
  cursor: pointer;
}
.footersociallinks .foot_soc:hover {
  color: #00b1ff;
}
.quicklinks p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  font-family: "HelveticaNeue";
}
.quicklinks ul li a {
  list-style: none;
  color: #9e9e9e;
  font-size: 16px !important;
  margin-top: 10px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.5s ease-in;
  font-family: "HelveticaNeue";
}
.quicklinks ul li a:hover {
  color: #00b1ff !important;
}
.quicklinks ul li a span {
  margin-right: 12px;
}

.footerhead .connectwallet {
  background: #f9231a !important;
  color: #fff !important;
  font-size: 16px;
  border: 2px solid #f9231a !important;
  border-radius: 30px !important;
  padding: 10px 25px !important;
  max-width: 150px;

  font-weight: 400 !important;
  text-align: center;
}
.footerhead .connectwallet:hover {
  box-shadow: 0 14px 26px -12px rgb(153 153 153 / 42%),
    0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%);
}
::placeholder {
  color: #555;
  opacity: 1;
}
.copyrightsde {
  color: #000;
  font-size: 16px !important;
  text-align: center;
  margin: 0 !important;
}
.copyrightsde li a:hover {
  color: #000000;
}
.copyrights {
  padding-top: 10px;
}

.ratingsec {
  background-color: #fff;
  box-shadow: 2px 2px 13px 3px #00000047;
  border-radius: 20px;
  padding: 30px;
  margin-top: -60px;
}
.ratingsec h2 {
  font-size: 40px;
  font-weight: 800;
  color: #272626;
}
.ratingsec p {
  font-size: 28px;
  font-weight: 400;
  color: #646262;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
#videolinks .modal-content {
  max-width: 40%;
  min-width: 90%;
  margin-top: 70px;
  width: 90%;
  min-height: 90%;
  max-height: 90%;
}
.walletconnect-modal__base {
  max-width: 330px !important;
}
#videolinks .modal-dialog {
  width: 90%;
  margin: 0 auto;
  max-width: 1440px;
  /* min-width: 90%; */
  min-height: 90%;
  max-height: 90%;
}
/* footer */
/* liveauctions */
.live_auctions {
  background-color: #fff;
  height: 100%;
  padding: 50px 0px 50px 0px;
}
.live_auctions h2 {
  font-size: 36px;
  font-family: "HelveticaNeue";
  color: #1f1f1f;
  text-align: center;
  font-weight: 400;
  margin: 0 0 15px 0;
  background: transparent;
  padding: 35px 0 35px 0;
}

/* liveauctions */
/* recentlyadded */
.recentlyadded {
  background-color: #fff;
  height: 100%;
  padding: 50px 0 100px 0px;
}
.recentlyadded h2 {
  font-size: 36px;
  font-family: "HelveticaNeue";
  color: #1f1f1f;
  text-align: center;
  font-weight: 400;
  margin: 0 0 15px 0;
}

/* recentlyadded */
/* tokencard */

.swiper-button-disabled {
  display: none !important;
}
.swiper-button-next:after {
  content: "\f141" !important;
  background-image: url("../src/assets/images/swipericon.png");
  background-repeat: no-repeat;
  font-family: "Font Awesome 5 Free" !important;
  background-size: 61% auto;
  position: absolute;
  top: 42px;
  transform: rotate(273deg);
  background-position: center;
}
.swiper-button-prev:after {
  content: "\f141" !important;
  background-image: url("../src/assets/images/swipericon.png");
  background-repeat: no-repeat;
  font-family: "Font Awesome 5 Free" !important;
  background-size: 61% auto;
  transform: rotate(273deg);
  background-position: center;
}
.swiper-cards {
  position: relative !important;
}
.swiper-button-next {
  transform: translate(481px, -23px);
  position: relative !important;
  bottom: 40px !important;
  top: unset !important;
  right: unset !important;
  left: unset !important;
}
.swiper-3d .swiper-slide-shadow {
  background: none !important;
}
.tokencardsec {
  border-radius: 17px;
  box-shadow: 1px 2px 12px -3px #000;
}
.tokencardsecimg {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border: 0px !important;
}
.tokencardsecimg img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  transition: 0.5s ease-out;
}

.timercontent {
  background-color: #323234;
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 11px;
  min-width: 219px;
  transform: translate(-50%, -7%);
}
.tokencardsecimgsec {
  position: relative;
}
.termsofservice h1 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 50px 0;
  font-weight: 400;
  font-size: 36px;
  cursor: pointer;
  margin-top: 35px;
}

/* .termsofservice table tr td {    width: 100% !important;
          display: inline-block;} */
/* .termsofservice table tr td p{width: 100% !important;} */
/* .termsofservice table tr td p strong{width:30% !important;} */
.termsofservice table {
  width: 100% !important;
}
.termsofservice h2 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 0 0;
  font-size: 18px;
  cursor: pointer;
  font-weight: 600;
}
.termsofservice p {
  color: #000;
  font-size: 16px;
  cursor: pointer;
  font-family: "HelveticaNeue";
}
.homebanerheight .timercontent {
  padding: 2px 11px;
}
.hourds {
  color: #fff;
  font-weight: 400;
  font-family: "HelveticaNeue";
}
.homebanerheight .hourds {
  color: #fff;
  font-weight: 400;
  font-family: "HelveticaNeue";
  font-size: 16px;
}
.hrsname {
  font-weight: 400;
  font-family: "HelveticaNeue";
  font-size: 12px;
  color: #ffffffa3;
}
.timercontent span img {
  width: 20px !important;
  margin-right: 7px;
}
.homebanerheight .timercontent span img {
  width: 20px !important;
}
.tokencardinfo {
  padding: 10px 20px;
  background: #fff;
  border-bottom-right-radius: 17px;
  border-bottom-left-radius: 17px;
}
.tokencardinfosec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tokencardinfosec .heart i {
  font-size: 16px;
  color: #d5d5d5;
  transition: all 0.5s ease-in-out;
}
.tokencardinfosec .heart i:hover {
  color: #fe0000;
}
.tokencardinfosec h3:hover {
  color: #00affe;
}
.tokencardinfosec .heart.clicked i {
  font-size: 16px;
  color: #fe0000;
}
.tokencardinfoheade {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.tokencardinfoheade h4 {
  font-size: 13px;
  font-family: "HelveticaNeue";
  color: #a7a7a7;
  margin-bottom: 0;
  font-weight: 400;
}
.tokencardinfosec h3 {
  font-size: 18px;
  font-family: "HelveticaNeue";
  font-weight: 400;
  color: #000;
  transition: 0.5s ease-in-out;
}
.homebanerheight .tokencardinfoheade p {
  font-size: 12px;
}
.tokencardinfoheade p {
  font-size: 14px;
  font-family: "HelveticaNeue";
  font-weight: 400;
  margin-bottom: 0;
  color: #00affe;
}
.tokencardinfoheade .connectwallet:hover {
  border: 1px solid rgba(162, 121, 250, 0.3);
  background: rgba(133, 100, 201, 0.25);
  text-decoration: none;
  color: #fff !important;
}
.owl-dots {
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  bottom: 40px;
}
/* tokencard */
/* subscribe */
.subscribe {
  height: 100%;
  padding: 0px 0;
  background-size: cover;
  background-color: #000;
}
.subscribes {
  padding: 50px 0;
  background-size: cover;
  background-color: #fff;
}
.subscribebnft {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.subscribebnft h2 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 36px;
  font-weight: 400;
}
.subscribebnft p {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
  line-height: 30px;
  font-weight: 400;
}
/* subscribe */
.section-padding {
  width: 1170px;
  margin: 0 auto;
  padding: 80px 0;
}
/* modal */
.fixedpries {
  width: 100%;
  display: block;
}
/* .modal-header {
  background: #323234;
  padding: 25px 0 !important;
} */
.modal-title {
  color: #fff;
  margin: 0 auto;
  font-weight: 400;
  font-size: 22px;
  font-family: "HelveticaNeue";
}
/* .modal-dialog-scrollable .modal-content {
  max-height: 575px !important;
  border-radius: 35px !important;
  overflow: hidden !important;
  border: none !important;
  width: 350px !important;
  margin: 0 auto;
} */
.modal-footer {
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}
#putonsale .modal-dialog {
  max-width: 900px !important;
}
#search .cancelbtn {
  color: #fff !important;
}
.reportnews {
  color: #434242;
  font-size: 14px;
  font-family: "HelveticaNeue";
  text-align: center;
  font-weight: 400 !important;
  margin-bottom: 30px;
}
.repostmgs {
  color: #000;
  font-size: 12px;
  font-family: "HelveticaNeue";
  text-align: center;
  font-weight: 400 !important;
  margin-bottom: 30px;
}
.searchad {
  overflow-y: scroll;
  height: 400px;
}
.searchlistbar {
  display: flex;
  align-items: center;
  background-color: #373737;
  border-radius: 30px;
  padding: 10px;
  transition: 0.5s ease-in;
  margin-bottom: 20px;
}
.searchlistbar:hover {
  background-color: #373737;
  background: #00101e;
  box-shadow: 0px 8px 18px #000000;
}
.searchlistbar h3 {
  color: #bcbbbb;
  font-size: 14px;
  font-family: "HelveticaNeue";
}
.searchlistbar h4 {
  color: #bcbbbb;
  font-size: 12px;
  font-family: "HelveticaNeue";
}
.searchlistbarimg {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 110px;
  max-height: 110px;
  border-radius: 15px;
  margin-right: 20px;
}
.searchlistbarimg img {
  object-fit: cover;
  max-width: 100%;
  min-height: 60px;
}
#search .modal-content {
  background-color: #575757;
  box-shadow: 2px 20px 20px #000000;
}
#buynow .modal-content {
  width: 350px !important;
}
.palcebideths {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  border-radius: 14px;
  margin-bottom: 20px;
  height: 40px;
  margin-top: 20px;
}
.palcebideths.palcebideths_krdrop {
  border: none !important;
  border-radius: 15px !important;
}
.palcebideths.palcebideths_krdrop:focus {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.palcebideths img {
  margin-left: 20px;
}
#placeabid .react-select__control {
  border-radius: 14px;
  margin-left: auto;
}
.palcebideths input {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  margin-left: 20px !important;
  width: 100%;
}
#placeabid h1 {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  text-align: center;
  padding: 0;
}
#placeabid h3 {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  text-align: center;
}
#cancelbid h1 {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  text-align: center;
}
#cancelbid input {
  background: none !important;
  border: none !important;
  margin-left: 20px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 14px;
  margin-top: 30px;
  height: 40px;
  padding-left: 16px;
}

.modal-backdrop.fade.show {
  z-index: 0;
}
#burntoken h1 {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  text-align: center;
}
#burntoken input {
  appearance: none;
  --webkit-appearance: none;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 10px;
}
#burntoken input:focus-visible {
  outline: none;
}

#report h1 {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  text-align: center;
}
#report textarea {
  appearance: none;
  --webkit-appearance: none;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
}
#report textarea:focus-visible {
  outline: none;
}
#report textarea::placeholder {
  font-size: 12px;
}
.buynow_popup {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.buynow_popupname {
  text-align: left;
  max-width: 60%;
  word-break: break-word;
}
.buynow_popupname h6 {
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  font-size: 14px !important;
}
.buynow_popupname h2 {
  color: #000;
  font-weight: 400 !important;
  font-family: "HelveticaNeue";
  font-size: 16px !important;
}
.buynow_popupname1 {
  text-align: right;
  max-width: 40%;
  word-break: break-word;
}
.buynow_popupname1 h6 {
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  font-size: 14px !important;
}
.bidnowconent {
  color: #434242;
  font-weight: 400;
  font-family: "HelveticaNeue";
  font-size: 14px !important;
  text-align: center !important;
  margin-top: 0 !important;
}
.burntone {
  color: #000;
  font-weight: 400;
  font-family: "HelveticaNeue";
  font-size: 16px !important;
  margin-top: 0 !important;
  text-align: center;
}
.buynow_popupname1 h2 {
  color: #000;
  font-weight: 400 !important;
  font-family: "HelveticaNeue";
  font-size: 16px !important;
}
.modal-content {
  border-radius: 10px !important;
  margin: 0 auto;
  width: 280px !important;
  background-color: transparent !important;
}

.modal-body .checkbox {
  display: flex;
  align-items: center;
  padding: 10px;
}

.modal-content {
  border: none !important;
}
#bidnow .modal-content {
  width: 350px !important;
}
#bidnow input {
  appearance: none;
  --webkit-appearance: none;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
}
#bidnow input:focus-visible {
  outline: none;
}
#bidnow h1 {
  text-align: center;
  color: #000;
  font-weight: 700;
  font-family: "HelveticaNeue";
  font-size: 18px;
  margin-top: 20px;
}

#bidnow .blksedition {
  font-size: 15px;
  color: #00a1ff;
  font-weight: 700;
  font-family: "HelveticaNeue";
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}
.percentagepop {
  font-weight: 400 !important;
  font-family: "HelveticaNeue";
  font-size: 16px !important;
  color: #000;
}
.modal-footer {
  padding-bottom: 40px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0 !important;
}
#buynow h3 {
  text-align: center;
  font-weight: 800;
  font-family: "HelveticaNeue";
  color: #4a4a4a;
  font-size: 18px;
  padding: 10px 0;
}
.modal-body .checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.modal-body .metamasklogo:hover {
  background-color: #00b1ff21;
  border-radius: 5px;
}
#buynow input[type="text"]:focus-visible {
  border: 1px solid #0089ff !important;
}
#buynow input[type="text"] {
  border: 1px solid #0089ff !important;
}
/* table{cursor: pointer;} */
.react-select__control--is-focused {
  border: none !important;
  outline: none !important;
}

.modal-body .checkbox p {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
  width: unset;
  margin: unset;
  padding: unset;
}

.modal-body h2 span a {
  font-size: 15px;
  color: #00a1ff;
  font-weight: 400;
  text-decoration: none;
  font-family: "HelveticaNeue";
}
.modal-footer {
  justify-content: center !important;
}

.modal-footer .connectwallet:hover {
  color: #00a1ff !important;
  text-decoration: none;
}
/* modal */
/* explore */
.explorepage #navbar {
  background-color: #fff;
}
.explorepagesec {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
  /* min-height: calc(100vh - 437px); */
  padding-top: 50px;
  padding-bottom: 100px;
}
.explorepagesec h1 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 43px 0;
  font-size: 36px;
  font-weight: 400;
  margin-top: 15px;
}
.explorepage.faq .explorepagesec h1 {
  margin-top: 45px;
}
.loadmorebtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}
.loadmorebtn img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.loadmorebtn h2 {
  font-size: 16px;
  color: #000;
  margin: 0;
}
.explorepage .searchbarexplore .serchitems {
  color: #000;
}
.explorepage .tabbable-line {
  display: flex;
  align-items: center;
}
.explorepage .tabbable-line .nav-tabs {
  display: block !important;
  white-space: nowrap;
  cursor: pointer;
  max-width: 1115px;
  overflow-x: auto;
  height: 60px;
  border: none !important;
  background: transparent !important;
}
.explorepage .tabbable-line .nav-tabs::-webkit-scrollbar-track {
  padding: 1px 0;
  background-color: rgba(155, 124, 220, 0.1);
}

.explorepage .tabbable-line .nav-tabs::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}

.explorepage .tabbable-line .nav-tabs::-webkit-scrollbar-thumb {
  border-radius: 2px;

  background-color: #a279fa;
}
.explorepage .tabbable-line .nav-tabs li.active {
  color: #a279fa;
}
.explorepage .tabbable-line .nav-tabs li.active:hover {
  background-color: transparent;
  color: #8958f5 !important;
}
.explorepage .tabbable-line .nav-tabs li {
  padding: 5px 25px;
  border-radius: 15px;
  margin-right: 25px;
  transition: 0.5s ease;
  display: inline-block;
}

.explorepage .tabbable-line .nav-tabs li a {
  text-decoration: none;
  font-family: "HelveticaNeue";
  font-size: 18px;
  font-weight: 400 !important;
  display: block;
}
/* .explorepage .tabbable-line .nav-tabs li:hover{background: #00AFFE;} */
.explorepage .tabbable-line .nav-tabs li a:hover {
  color: #a279fa;
}
.searchbarexplore .serchitems {
  --webit-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 5px;
  font-family: "HelveticaNeue";
  font-size: 12px;
  padding: 5px 16px;
  width: 100%;
  color: #fff;
}
.searchbarexplore .serchitems:focus-visible {
  border: 1px solid #00b1ff !important;
  box-shadow: none !important;
  outline: none !important;
  transform: none !important;
}
.searchbarexplore .serchitems:focus {
  border: none;
  box-shadow: none;
  outline: none;
  transform: none;
}
.searchbarexplore .serchitems::placeholder {
  color: #bcbbbb;
  font-size: 14px;
}
.searchbarexplore {
  width: 350px;
  margin-right: 50px;
  background-color: #e6e6e6 !important;
  max-width: 100%;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px !important;
}
/* explore */
/* myitems */
.myitemspage #navbar {
  background-color: #fff;
}

.myitemspagecontent {
  position: relative;
}
.myitemsedit {
  font-size: 18px;
  color: #fff;
  position: absolute;
  right: 60px;
  bottom: 20px;
  cursor: pointer;
  z-index: 9 !important;
}
.myitemsbanner:after {
  content: "";
  position: absolute;
  background-color: #000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  top: 0;
  display: none;
  transition: 0.5s ease-in-out;
}
.myitemsbanner:hover:after {
  display: block;
}

.myitemspagesec h1 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 50px 0;
  font-size: 36px;
  font-weight: 400;
  margin-top: 35px;
}
.myitemsbanner {
  overflow: hidden;
  min-height: 400px;
  max-height: 400px;
  border-radius: 50px;
  border: 15px solid #fff;
  box-shadow: 0px 15px 25px #00000029;
  position: relative;
  z-index: 0;
  background: #0079ff;
}
.myitemsbanner img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
  max-height: 400px;
  min-height: 400px;
}
.myitemsprofile {
  text-align: center;
  position: relative;
  width: 120px;
  border-radius: 25px;
  margin: auto;
  top: -59px;
}
.myitemsprofile img {
  background: #fff;
  width: 120px;
  height: 120px;
  border-radius: 25px;
  border: 8px solid #fff;
  position: relative;
}
.myitemsprofile .before_inter::after {
  content: "";
  position: absolute;
  background-color: #000;
  opacity: 0.4;
  top: 8px;
  left: 8px;
  border-radius: 18px;
  width: 105px;
  height: 104px;
  display: none;
  transition: 0.5s ease-in-out;
}
.myitemsprofile .before_inter:hover::after {
  display: block;
}
.editprofileprofile .before_inter::after {
  content: "";
  position: absolute;
  background-color: #000;
  opacity: 0.4;
  top: 18px;
  left: 8px;
  border-radius: 100px;
  width: 133px;
  height: 135px;
  display: none;
  transition: 0.5s ease-in-out;
}
.editprofileprofile .before_inter:hover::after {
  display: block;
}
.myitemspage .filetupepend {
  text-align: center;
  position: relative;
}
.myitemspage.filetupepend:hover i {
  z-index: 9;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #00affe;
  z-index: 99999;
}
.myitemspage .filetupepend i {
  position: relative;
  background-color: #fff;
  padding: 10px;
  border-radius: 50px;
  border: 2px solid #ccc;
  left: 0;
  right: 0;
  display: inline-table;
  margin: 0 auto;
  top: -26px;
  transition: 0.5s ease-in;
  cursor: pointer;
  z-index: 9;
}
.myitemspage .filetupepend:hover i {
  background-color: #00affe;
  color: #fff;
  border-color: #fff;
}
.editprofile .filetupepend:hover i {
  background-color: #00affe;
  color: #fff;
  border-color: #fff;
}
textarea::placeholder {
  color: #787878 !important;
}
.myitemspage .filetupepend input {
  position: absolute;
  width: 42px;
  height: 42px;
  left: 0;
  right: 0;
  top: -23px;
  z-index: 9;
  opacity: 0;
  display: inline-table;
  margin: 0 auto;
  cursor: pointer !important;
}
.myitemspage h2 {
  color: #000;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  font-family: "HelveticaNeue";
  padding: 0px 0px 10px 0px;
}
.myitemspage h4 {
  color: #4e4d4d;
  font-weight: 400;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 14px;
}
.myitemspage .nftcollect {
  color: #9d9999;
  font-weight: 400;
  text-align: center;
  font-family: "HelveticaNeue";
  margin: 0;
  font-size: 14px;
}
.chooseimgaebtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}
.chooseimgaebtn input {
  position: absolute;
  top: 4px;
  opacity: 0;
  cursor: pointer;
}
.myitemspagesec .tabbable-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.myitemspagesec .tabbable-line .nav-tabs {
  border: none !important;
  justify-content: center;
}
.myitemspagesec .tabbable-line .nav-tabs li.active {
  background: transparent;
  color: #fff;
}
.myitemspagesec .tabbable-line .nav-tabs li.active a {
  font-weight: 700 !important;
}
.myitemspagesec .tabbable-line .nav-tabs li.active:hover {
  color: #000;
}
.myitemspagesec .tabbable-line .nav-tabs li.active a {
  color: #fff;
}
.myitemspagesec .tabbable-line .nav-tabs li {
  padding: 5px 25px;
  border-radius: 15px;
  margin-right: 30px;
  transition: 0.5s ease;
  display: block;
}
.myitemspagesec .tabbable-line .nav-tabs li a {
  text-decoration: none;
  color: #000 !important;
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400 !important;
  display: block;
  margin-bottom: 0;
  cursor: pointer;
}
/* .myitemspagesec .tabbable-line .nav-tabs li:hover{background: #00AFFE;} */
.myitemspagesec .tabbable-line .nav-tabs li a:hover {
  color: #fff;
}
.myitemsshareicons {
  text-align: center;
  margin-top: 20px;
}
.myitemsshareicons i {
  background-color: #fff;
  padding: 10px;
  border-radius: 50px;
  margin-right: 10px;
  transition: 0.5s ease;
  box-shadow: -2px 6px 14px #00000029;
  cursor: pointer;
}
.myitemsshareicons i:hover {
  background-color: #00affe;
  color: #fff;
}
.shareiconslinkss {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.shareiconslinkss i {
  background-color: transparent;
  border-radius: 50px;
  transition: 0.5s ease;
  color: #bebebe;
  border: 2px solid #bebebe;
  width: 50px;
  height: 50px;
  line-height: 1.66;
  font-size: 28px;
}
.shareiconslinkss i:hover {
  background-color: transparent;
  border-color: #fff600;
  color: #fff600;
}
.shareiconslinkss .fa-twitter {
  line-height: 49px !important;
}
.modal-footer {
  border-top: none !important;
}
.copylinks {
  margin-top: 20px;
  border: 2px solid #00aaff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copylinks input {
  appearance: none;
  --webkit-appearance: none;
  border: none !important;
  background-color: transparent;
  padding-left: 12px;
  width: 100%;
}
.copylinks input:focus {
  border: none;
  outline: none;
}
.copylinks input:focus-visible {
  border: none;
  outline: none;
}

.copylinks .connectwallet:hover {
  color: #00aaff !important;
}
/* myitems */
/* about */
.aboutus #navbar {
  background-color: #fff;
}
.aboutuspagesec {
  background-color: #fff;
  /* min-height: calc(100vh - 465px); */
}

.aboutuspagesec h1 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 30px 0;
  font-size: 36px;
  font-weight: 400;
  margin-top: 55px;
}
.aboutuspagesec p {
  color: #000;
  font-family: "HelveticaNeue";
  font-size: 16px;
}
.video_iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  margin: 0 auto;
}
.video-wrapper {
  position: relative;
  /* height: 70vh; */
  margin: 0 auto;
}
.video-wrapper video {
  position: relative;
  top: -1px;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#videolinks .video-wrapper {
  position: relative;
  height: 400px;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
  text-align: center;
}
.infodos .buybtn {
  width: 90px;
}
.learnmore {
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  font-family: "HelveticaNeue";
  cursor: pointer;
}
.learnmore i {
  font-size: 14px;
}
#videolinks .modal-dialog-scrollable .modal-content {
  width: 450px !important;
}
.responsive-video {
  position: relative;
  padding-bottom: 35%;
  padding-top: 60px;
  overflow: hidden;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* about */
/* activity */
.activity #navbar {
  background-color: #fff;
}
.activitysec {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
  /* min-height: calc(100vh - 437px); */
  padding-top: 50px;
  padding-bottom: 50px;
}
.activitysec h1 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 50px 0;
  font-size: 36px;
  font-weight: 400;
  margin-top: 35px;
}
.activity .table {
  margin: 0;
  text-align: center;
}
.activity .table #tabelrowes {
  border-radius: 5px;
  border: 1.5px solid rgba(162, 121, 250, 0.5);
  /* background: rgba(97, 44, 210, 0.20); */
}

.table thead th {
  /* border-radius: 5px; */
  border-bottom: 1.5px solid rgba(162, 121, 250, 0.5);
  border-top: 1.5px solid rgba(162, 121, 250, 0.5);
}

.activity .table #tabelrowes th {
  background: rgba(97, 44, 210, 0.2) !important;
  color: #fff !important;
}
.activity .table thead th {
  padding: 25px;
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 400 !important;
  white-space: nowrap;
}
.activity .table tbody {
  background: #fff !important;
}
.activity .table tbody th {
  padding: 25px;
  font-family: "HelveticaNeue";
  font-size: 14px;
}
.activity .table tbody td {
  padding: 25px;
  font-family: "HelveticaNeue";
  font-size: 14px;
  border: none !important;
  box-shadow: none !important;
  white-space: nowrap;
}
.activity .table tbody td img {
  width: 25px;
  height: auto;
}
.activity .table tbody .timing {
  color: #000 !important;
  font-size: 14px;
  /* box-shadow: inset 0 0 0 9999px #7878780a !important; */
  background: #f9f9f9;
}
/* .activity .table-hover tbody tr:hover{background-color: #f5f8ff;
        box-shadow: 0px 8px 19px #008BC826;
      } */
.activity .table-hover tbody .activity .table tbody td,
.activity .table-hover tbody td.editpeors {
  background-color: #f9f9f9 !important;
  background: #f9f9f9 !important;
  box-shadow: none !important;
}
.activity .dateprofile img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 10px;
  box-shadow: 0px 11px 16px #00000029;
  margin-right: 10px;
}
.activity .dateprofile a,
.activitysec td a {
  color: #000 !important;
  font-size: 14px;
  margin-bottom: 0;
}
.activity .dateprofile {
  display: flex;
  align-items: center;
}
.activitysec .editpeors a {
  color: #000 !important;
}
.editpeors {
  box-shadow: none !important;
  background: none !important;
  background-color: #00000008 !important;
}
/* .salecals {
  border-top-left-radius: 5px;
}
.salecalss {
  border-top-right-radius: 5px;
} */
/* activity */
/* info */
input::placeholder {
  font-size: 14px !important;
  color: #888 !important;
}

#placeabidlabel input::placeholder {
  font-size: 10px !important;
}
#placeabid .react-select__menu-list {
  border-radius: 18px;
  height: 33px;
  max-height: unset !important;
  padding: 0;
}
#placeabid .react-select__menu {
  border-radius: 9px;
  overflow: auto;
}
.info #navbar {
  background-color: #fff;
}
.infosec {
  background-image: url("https://api.unblokdata.com/frontImg/Markeetplace/background.png");
  background-size: cover;
  background-position: center;
}
.infosec h1 {
  color: white;
  font-family: "HelveticaNeue";
  padding: 0px 0;
  font-size: 36px;
  margin-top: 35px;
  font-weight: 400;
}
.infosecinfo {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  border: 2px solid rgba(162, 121, 250, 0.3);
  background: rgba(133, 100, 201, 0.25);
  backdrop-filter: blur(10px);
  padding: 8px;
}
.infosecinfo img {
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
}
.react-select__option {
  color: #000 !important;
  transition: all 0.5s ease;
}
.react-select__option:hover {
  color: #00b1ff !important;
}
.infolist h2 {
  font-size: 60px;
  font-family: "HelveticaNeue";
  color: #fff600;
  font-weight: 400;
  margin-bottom: 0;
}
.infoiconslist i {
  background-color: transparent;
  padding: 8px;
  color: #fff;
  border-radius: 50px;
  transition: 0.5s ease;
  margin-right: 5px;
}
.infoiconslist .fa-heart.active {
  background-color: #ffffff;
  color: #fe0000;
  box-shadow: 2px 4px 6px 3px #00000014;
}
.infoiconslist .fa-heart.active:hover {
  background-color: rgba(133, 100, 201, 0.25);
  color: #fff;
}
.infoiconslist i:hover {
  background-color: rgba(133, 100, 201, 0.25);
  padding: 8px;
  color: #fff;
  border-radius: 50px;
}
.infolist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.infodropdown.dropdown .btn-primary {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}
.infoiconslist .dropdown-toggle::after {
  display: none !important;
}
.infoiconslist {
  display: flex;
  align-items: center;
}
.infodropdown.dropdown .btn-primary:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
.infodropdown.dropdown .btn-primary:focus {
  outline: none !important;
  box-shadow: none !important;
}
.infodropdown.dropdown .btn-primary i {
  background-color: transparent;
  color: #000;
}
.infodropdown.dropdown .btn-primary i:hover {
  color: #00affe;
}
.infosec h3 {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  font-family: "HelveticaNeue";
  margin-top: 10px;
  margin-bottom: 25px;
}
.infosec p {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.infosec .tabbable-line .nav-tabs {
  margin-top: 30px;
  border: none !important;
  justify-content: space-between;
}
.infosec .tabbable-line .nav-tabs li {
  margin-right: 20px;
}
.infosec .tabbable-line .nav-tabs li.active a {
  font-weight: 700;
}
.infosec .table {
  cursor: auto;
}
.infosec .tabbable-line .nav-tabs li.active a:hover {
  background-color: #fff;
  color: #000;
  display: inline-block;
}
.infosec .tabbable-line .nav-tabs li a {
  padding: 7px 18px;
  font-size: 16px;
  text-decoration: none;
  color: #000;
  background-color: transparent;
  font-family: "HelveticaNeue";
  border-radius: 8px;
  transition: 0.5s ease;
  font-weight: 400;
  display: inline-block;
}
.infosec .tabbable-line .nav-tabs li a:hover {
  color: #fff;
  background-color: #00affe;
  box-shadow: 0px 11px 31px #00000063;
}
.infotabdetailssec img {
  width: 40px;
  height: 40px;
  border-radius: 65px;
  border: 2px solid #fff;
  box-shadow: 0px 6px 20px #00000029;
  object-fit: cover;
}
.infotabdetailssec {
  display: flex;
  cursor: pointer;
}
.react-select__option {
  background-color: #fff !important;
  color: #000;
}
.infotabdetailssec h2 {
  font-size: 16px;
  color: #fff;
  font-family: "HelveticaNeue";
  margin-left: 10px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 2;
  transition: all 0.5s ease;
  cursor: auto;
}
/* .infotabdetailssec h2:hover{font-size: 16px;color: #00AFFE; font-family: 'Roboto', sans-serif;;margin-left: 10px;margin-bottom: 0;font-weight: 400;line-height: 2;} */
.infotabdetailssec h3 {
  font-size: 14px;
  color: #000;
  font-family: "HelveticaNeue";
  margin-left: 10px;
  margin-bottom: 0;
  font-weight: 400;
}
.infotabdetails h1 {
  font-family: "HelveticaNeue";
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 5px;
}
.infotabdetailsroyal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #70707021;
  padding-bottom: 10px;
  padding-top: 10px;
  cursor: pointer;
}
.infotabdetailsroyal h2 {
  color: #00affe;
  font-size: 18px;
  font-weight: 400;
}
.infotabdetailsroyal p {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  text-align: start;
}
.infotabdetailsroyal:last-child {
  border-bottom: none;
}
/* .infotabdetails  table tbody tr{border-bottom: 1px solid #00000038 !important;} */
.approvetransaction .servicefeesec {
  margin-top: 0;
  font-size: 16px !important;
}
.info .hidone {
  font-size: 18px;
  color: #000;
  font-weight: 500 !important;
  font-family: "HelveticaNeue";
  width: 50%;
  padding: 15px 0px;
}
.info .hidone1 {
  font-size: 14px;
  color: #000;
  font-weight: 500 !important;
  font-family: "HelveticaNeue";
  width: 50%;
  padding: 15px 0px 15px 15px;
}
.info tr td {
  font-size: 16px;
  font-weight: 400 !important;
  font-family: "HelveticaNeue";
}
.bidnowbtn {
  text-align: end;
}
#placeabid .placeabidbalance {
  display: flex;
  justify-content: space-between;
}
#price_item_modal i {
  color: #00affe;
}
#price_item_modal .approve_media {
  align-items: baseline;
}
#proceed_bid_modal .modal-content, #bidnow .modal-content, #accept_price_item_modal .modal-content, div#cancel_model .modal-content{
  background: url("./assets/images/Header/nft-header.png");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.modal-footer .bidbtn:hover{
  color: #0000ff00!important;
}
div#cancel_model .bidbtn:focus-visible, div#cancel_model .bidbtn:hover{
  outline: 0!important;
  border: 0px!important;
}
#proceed_bid_modal .closePopupWrapper, .bidbtn {
  position: absolute;
  top: -20px;
  right: 0px !important;
}
#cancel_model .bidbtn {
  position: absolute;
  top: -20px;
  right: 0px !important;
  width: 100%;
  text-align: right;
}
#bidnow  .bidbtn {
  position: absolute;
  top: -20px;
  right: 0px !important;
}
#accept_price_item_modal  .bidbtnn {
  position: absolute;
  top: -20px;
  right: 0px !important;
}
#bidnow .modal-body h1, #bidnow .modal-body h5, #bidnow .modal-body h6, #bidnow p, #cancel_model .bidnowconent{
  color: #ffffff;
}
#bidnow .buynow_popupname1 h6{
  color:  #fff600;
}
#bidnow .modal-header, #accept_price_item_modal .modal-header, #cancel_model .modal-content{
  padding-top: 40px;
}
div#proceed_bid_modal .modal-body{
  margin-top: 20px; 
}
#proceed_bid_modal button, #cancel_model .modal-footer .buybtn {
  text-transform: none !important;
  align-items: center;
  background: #fff600 !important;
  border-radius: 5px !important;
  color: #000 !important;
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 139px;
  border: 0px !important;
}
#proceed_bid_modal .approve_text{
  color: #ffffff;
}
#proceed_bid_modal .modal-header{
  padding-top: 30px;
}
#bidnow .modal-footer .bidbtn{
  padding-right: 0px!important;
  width: 100%!important;
  text-align: right!important;
}
#proceed_bid_modal .connectwallet{
  width: 90%!important;
  margin: 10px auto;
}
#accept_price_item_modal .approve_media{
  margin-top:20px;
}
html body #accept_price_item_modal .modal-body p{
  color: #ffffff!important;
}
#placeabid .placeabidbalance h6 {
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
#placeabid .placeabidbalance h5 {
  font-family: "HelveticaNeue";
  font-size: 14px;
  font-weight: 400;
  color: #252525;
}
.buybtn {
  background: linear-gradient(180deg, #00affe 0%, #005eff 100%);
  font-family: "HelveticaNeue";
  border-radius: 10px !important;
  font-size: 14px;
  color: #fff !important;
  padding: 5px 26px !important;
  border: 2px solid transparent;
  border: none;
  transition: 0.2s ease-in;
  font-weight: 400;
  cursor: pointer !important;
}

.buybtn:hover {
  background: linear-gradient(180deg, #005eff 0%, #00affe 100%);
  color: #fff !important;
}
.bidbtn:hover {
  background: linear-gradient(180deg, #00affe 0%, #005eff 100%) !important;
  color: #fff !important;
}
.bidbtn {
  background: none;
  color: #000 !important;
  font-family: "HelveticaNeue";
  border-radius: 15px;
  transition: all 0.2s ease-in;
  padding: 3px 26px !important;
  font-weight: 400;
  border: 2px solid #00affe !important;
  cursor: pointer;
  font-size: 14px;
}
.info .bidnowbtn {
  display: flex;
  align-items: center;
  /* justify-content: end; */
}

/* info */
/* editprofile */
.editprofile #navbar {
  background-color: #fff;
}
.editprofile {
  background-color: #fff;
}
.editprofilesec .bidnowbtn {
  display: flex;
  justify-content: end;
  align-items: center;
}
.editprofile h1 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 50px 0;
  font-size: 36px;
  cursor: pointer;
  font-weight: 400;
  margin-top: 35px;
}
.editprofilecontent {
  position: relative;
}
.editprofileedit {
  font-size: 18px;
  color: #fff;
  position: absolute;
  right: 60px;
  bottom: 80px;
  cursor: pointer;
  z-index: 9 !important;
}
.editprofilebanner:after {
  content: "";
  position: absolute;
  background-color: #000;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  top: 0;
  display: none;
  transition: 0.5s ease-in-out;
}
.editprofilebanner:hover:after {
  display: block;
}
.editprofileedit img {
  width: 50px !important;
  height: auto !important;
  max-height: unset !important;
  min-height: unset !important;
  position: absolute;
  /* bottom: 0; */
  right: 0px;
}
.editprofilesec {
  min-height: calc(100vh - 437px);
}
.editprofilepagesec h1 {
  color: #000;
  font-family: "HelveticaNeue";
  padding: 50px 0;
  cursor: pointer;
}
.editprofilebanner {
  overflow: hidden;
  min-height: 400px;
  max-height: 400px;
  position: relative;
  z-index: 0;
  border-bottom-right-radius: 50px;
}
.editprofilebanner img {
  object-fit: cover;
  min-height: 400px;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.editprofileprofile {
  text-align: center;
  position: relative;
  display: flex;
  align-items: baseline;
  top: -72px;
  left: 31px;
  width: 50%;
}
.editprofilepage h2 {
  color: #000;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  margin-right: 12px;
  font-family: "HelveticaNeue";
}
.editprofileprofile img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  border: 8px solid #fff;
}
.editprofile .filetupepend i {
  position: absolute;
  background-color: #fff;
  padding: 9px;
  border-radius: 50px;
  border: 3px solid #a2a2a2;
  left: 104px;
  right: 0;
  display: inline-table;
  margin: 0 auto;
  font-size: 19px;
  bottom: 96px;
  color: #a2a2a2;
}
.editprofile .filetupepend {
  text-align: center;
  position: relative;
  z-index: 9;
}
.editprofile .filetupepend input {
  position: absolute;
  width: 42px;
  height: 42px;
  left: 104px;
  right: 0;
  bottom: 100px;
  z-index: 1;
  opacity: 0;
  display: inline-table;
  margin: 0 auto;
}
.editprofilepage h4 {
  color: #202020;
  font-weight: 400;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 14px;
}
.editprofilepage {
  margin-top: 70px;
}
.editprofileinputlist h2 {
  font-size: 14px;
  color: #000;
  font-family: "HelveticaNeue";
  font-weight: 400;
}
.editprofiledisplay {
  display: flex;
  width: 70%;
  margin-left: 40px;
}
.editprofiledisplay img {
  height: 50px;
  width: 53px;
  background-color: #f1f1f1;
  border: 1px solid #ffffff;
  border-radius: 10px 0px 0px 10px;
  padding: 15px;
}
.editprofileinputlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editprofiledisplay input {
  box-shadow: 0px 3px 6px #01759a1a !important;
  border-radius: 0px 10px 10px 0px !important;
  background: #fff;
  border: none !important;
  padding-left: 20px;
  width: 400px !important;
}
.editprofiledisplay input::placeholder,
.editprofiledisplay textarea::placeholder {
  color: #6e6e6e;
  font-size: 14px;
}
.editprofiledisplay textarea {
  box-shadow: 0px 3px 6px #01759a1a !important;
  border-radius: 0px 10px 10px 0px !important;
  background: #fff;
  border: none !important;
  padding-left: 20px;
  width: 400px !important;
}
.inputypetest {
  border-bottom: 1px solid #ccc;
  margin-top: 50px;
  padding-bottom: 30px;
}
/* editprofile */
/* search */
.usericons {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 10px 8px #0000001a;
  transition: 0.5s ease;
}
.usericons:hover {
  background-color: #00affe;
}
.usericons img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 3px solid #fff;
  box-shadow: 0 0 8px #00000059;
}
.usericons h3 {
  color: #000;
  font-size: 18px;
}
.usericons h4 {
  color: #a7a7a7;
  font-size: 12px;
}
.usericons:hover h3 {
  color: #fff;
}
.usericons:hover h4 {
  color: rgb(224, 224, 224);
}
/* search */
#proceed_bid_modal .approve_media {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
}
#proceed_bid_modal .approve_text,
#proceed_bid_modal .approve_desc {
  text-align: left !important;
  padding: 0;
}

#proceed_bid_modal .approve_text {
  font-weight: 400;
}
#accept_price_item_modal i {
  color: #00affe;
}
#accept_price_item_modal .modal-body p {
  width: 100%;
  text-align: left;
  line-height: 18px;
}
#accept_price_item_modal .approve_text {
  padding: 0;
}
#proceed_bid_modal .approve_desc {
  font-size: 14px;
}
.approve_desc {
  font-size: 14px;
}
#proceed_bid_modal .pro_complete {
  color: #005eff;
}

.modal-footer .buybtn {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
  padding: 6px !important;
  border-radius: 10px;
}
.modal-footer .bidbtn {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 4px !important;
  border-radius: 10px;
}
.mobile_search {
  display: flex;
  align-items: center;
  opacity: 0;
}
.mobile_search .searchbarsd {
  border: none;
  background-color: #e2e2e2;
  height: 59px;
  margin-top: -2px;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 99;
}
.mobile_search .searchbarsd i {
  color: #000;
}
.mobile_search .searchbarsd input {
  background: none;
  border: none;
  color: #000;
}
.mobile_search .searchbarsd input:focus-visible {
  border: none;
}
.mobile_search i {
  color: #fff;
}
.mobile_search .srecat {
  max-height: 400px;
  min-height: 400px;
}
/* faq */
.faq .accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #000;
  font-family: "HelveticaNeue" !important;
  font-size: 20px !important;
}
.faq .accordion-item,
.faq .accordion-button {
  background-color: transparent !important;
  border: none !important;
}
.faq .accordion-button {
  background-color: transparent !important;
  color: #000;
  font-family: "HelveticaNeue" !important;
  font-size: 17px !important;
}
.faq .accordion-body {
  color: #1c1c1c;
  font-family: "HelveticaNeue" !important;
  font-size: 16px !important;
}
.faq .accordion-button::after {
  background-image: none !important;
  content: "+";
  width: unset !important;
  height: unset !important;
  color: #005dff;
}
.faq .accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "-";
  color: #000;
}
.accordion-header:focus-visible {
  outline: none !important;
}
.accordion-header:focus {
  outline: none !important;
}
/* faq */
.navbars.dropdown .dropbtn {
  background-color: transparent;
  color: #00b1ff;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
}
.connectwalletcopy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}
.polugonbalance:hover {
  background-color: #0002;
}
.polugonbalance:hover:last-child {
  background-color: #0002;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.navbars.dropdown i {
  font-size: 20px;
  transition: 0.5s ease;
}
.navbars.dropdown i:hover {
  color: #00b1ff;
}
/* The container <div> - needed to position the dropdown content */
.navbars.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.navbars.dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  border-radius: 10px;
  right: 0;
}
.linkdataa {
  font-size: 16px;
  color: #000;
  padding: 0px 0 0 15px;
  margin: 0;
  font-family: "HelveticaNeue";
  font-weight: 400;
}
/* Links inside the dropdown */
.dropdown-content p {
  color: black;
  padding: 6px 16px;
  text-decoration: none;
  border-radius: 5px;
  display: block;
  margin: 10px;
}

/* Change color of dropdown links on hover */
.dropdown-content p:hover {
  /* background: linear-gradient(180deg, #00B1FF 0%, #005DFF 100%) 0% 0%; */
  color: #fff;
  margin: 10px;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  transition: all 0.5s ease;
}

/* .infosec .tab-content{height: 350px;overflow-y: scroll;} */

/* Change the background color of the dropdown button when the dropdown content is shown */
.copyclipath i {
  font-size: 14px !important;
  color: #00b1ff;
}
.dropdown:hover .dropbtn {
  background-color: transparent;
}
.copyclipath {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.copyclipath .linkdataa {
  font-size: 16px;
  font-family: "HelveticaNeue";
  padding: 0px 0px 0px 5px !important;
  margin-right: 20px !important;
  font-weight: 400;
}
.copyclipath h3 {
  font-size: 16px;
  color: #000;
  margin-right: 20px;
  font-family: "HelveticaNeue";
}
.polugonbalance {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  cursor: pointer;
}

.polugonbalancename h3 {
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0;
  font-family: "HelveticaNeue";
  color: #000;
  font-weight: 400;
}
.polugonbalancename .linkdataa {
  font-size: 14px;
  padding: 2px 0 0 11px !important;
  font-weight: 400;
}

@media (max-width: 1600px) and (min-width: 1441px) {
  /* .homebanerheight h1{margin-top: 60px;} */
  .explorepage .tabbable-line .nav-tabs {
    /* max-width: 1200px !important; */
  }
  .custom-container {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media (max-width: 1440px) and (min-width: 1200px) {
  /* .explorepage .tabbable-line .nav-tabs {
    max-width:  !important;
  } */

  .timercontent {
    transform: translate(-59%, -7%) !important;
  }

  .owdlslider {
    position: relative;
  }
  .myitemspage h2 {
    padding: 0 !important;
  }
  .homebanerheight p {
    font-size: 15px !important;
  }
  .editprofilebanner {
    overflow: hidden;
    min-height: 400px !important;
    max-height: 400px !important;
  }
  .myitemspagesec h1 {
    padding: 50px 0 !important;
  }
  .myitemsbanner {
    overflow: hidden;
    min-height: 300px !important;
    max-height: 300px !important;

    border: 8px solid #fff !important;
  }

  .homelinkswhole {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 1280px) {
  .bannerimges img {
    max-height: 470px !important;

    min-height: 470px !important;
  }
  .srecat {
    min-height: 400px;
    max-height: 400px;
  }
  .subscribebnft h2 {
    font-size: 25px;
  }
  .recentlyadded h2 {
    font-size: 25px;
  }
  .live_auctions h2 {
    font-size: 25px;
  }
  .notfound {
    padding-top: 0px !important;
  }
  .live_auctions h2 {
    padding-bottom: 0px !important;
  }
  .not_found_text {
    font-size: 30px;
  }
  .header_main img {
    width: 600px;
  }
  .homebanerheight h1 .spanclasd {
    font-size: 50px !important;
  }
  .tokencardinfoheade p {
    font-size: 12px !important;
  }
  .timercontent {
    transform: translate(-65%, -7%) !important;
  }
  .homelinklink {
    font-size: 12px !important;
  }
  .timercontent {
    transform: translate(-55%, -7%) !important;
  }
  .homebanerheight h1 {
    font-size: 30px !important;
  }
  .homebanerheight h5 {
    font-size: 20px !important;
  }
  /* .homebanerheight h1 span {
    font-size: 70px !important;
  } */

  .myitemspage h2 {
    padding: 0 !important;
  }
  .downfarrow {
    position: absolute;
    top: 358px;
    left: 454px;
  }
  .editprofilebanner {
    overflow: hidden;
    min-height: 300px !important;
    max-height: 300px !important;
  }
  /* .myitemspagesec h1{padding: 20px 0 !important;} */
  .myitemsbanner {
    overflow: hidden;
    min-height: 300px !important;
    max-height: 300px !important;

    border: 8px solid #fff !important;
  }
  .connectwallet {
    font-size: 14px;
    padding: 5px 25px !important;
    /* border-radius: 10px !important; */
  }

  .subscribebnft p {
    font-size: 18px !important;
    /* width: 70% !important; */
  }
  .searchlist {
    min-height: 380px;
    max-height: 380px;
  }
}
.myitemsapge .myitemspage {
  margin-top: -20px;
  cursor: pointer;
}
@media (max-width: 1279px) and (min-width: 770px) {
  .homelinkswhole {
    width: 600px;
  }
  .custom-container {
    max-width: 95%;
    margin: 0 auto;
  }
  .infosecinfo {
    border-radius: 5px !important;
  }
  .infosec .tab-content {
    height: fit-content;
  }
  /* .explorepage .tabbable-line .nav-tabs {
    max-width: 90vw !important;
  } */
  .homebanerheight h1 .spanclasd {
    font-size: 30px !important;
  }

  .timercontent {
    transform: translate(-55%, -7%) !important;
  }
  .swiper-button-next:after {
    top: -9px !important;
  }
  .homebanerheight p {
    font-size: 16px !important;
  }
  .homelinklink {
    font-size: 16px !important;
  }
  .downfarrow {
    width: 25px !important;
    height: 25px !important;
    max-width: 100% !important;
    min-height: unset !important;
    max-height: unset !important;
    transform: rotateZ(0deg);
    position: absolute;
    top: 291px;
    left: 364px;
  }

  .searchbarexplore .searchlist {
    left: 40px !important;
  }
  .searchlist {
    right: 0 !important;
    left: unset !important;
  }

  .homebanerheight h1 span {
    font-size: 56px !important;
  }
  .bannerimges img {
    max-height: 380px !important;
    min-height: 380px !important;
  }
  .swiper-button-next {
    transform: translate(363px, -18px) !important;
  }

  .homebanner .timercontent {
    left: 140px !important;
  }

  .homebanner .tokencardsec {
    max-width: 230px !important;
  }
}
.footer_flez {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.footer_noner {
  display: none !important;
}
.swiper {
  cursor: pointer;
}
/* @media screen and (min-width: 750px) and (max-width: 990px) {
  .explorepage .tabbable-line .nav-tabs {
    max-width: 104vw !important;
  }
}
@media screen and (min-width: 590px){
  .explorepage .tabbable-line .nav-tabs {
    max-width: 80vw !important;
  }
} */
@media (max-width: 991px) {
  /* .explorepage .tabbable-line .nav-tabs {
    max-width: 80vw !important;
  } */
  .tabview {
    display: block !important;
  }
  .icon_div {
    padding-bottom: 10px;
  }
  #videolinks .video-wrapper {
    height: 330px !important;
  }
  .desktopview {
    display: none !important;
  }
  /* .video-wrapper {
    height: 45vh;
  } */
  .header_main img {
    width: 600px;
  }
  .custom-container {
    max-width: 95%;
    margin: 0 auto;
  }
  .recentlyadded {
    padding: 0px 0 !important;
  }
  .live_auctions {
    padding: 30px 0 30px 0 !important;
  }
  .searchbarexplore .searchlist {
    left: -50px;
    margin: 0 auto;
    right: 0;
  }
  .homebanerheight h5 {
    font-size: 20px !important;
    margin-top: 0 !important;
  }
  .homebanerheight h1 .spanclasd {
    font-size: 30px !important;
  }
  .homelinkswhole {
    margin-top: 40px !important;
  }
  .homelinklist {
    padding: 20px !important;
    margin-top: 0 !important;
    border-bottom: 1px solid rgb(0 0 0 / 31%);
  }
  .homebanner {
    min-height: 100% !important;
  }
  .footer_blocks {
    display: none !important;
  }
  .footer_noner {
    display: block !important;
  }
  .explorefles {
    flex-direction: column;
    gap: 20px;
    width: 100% !important;
  }
  .searchbar {
    border: 1px solid #ccc;
  }
  .footerhead ul li {
    line-height: 20px !important;
  }
  .footerhead,
  .footerhead h2 {
    text-align: center !important;
    font-weight: 400;
  }
  .footerhead h2 {
    margin-bottom: 10px !important;
    margin-top: 15px;
    color: #000;
  }
  .quicklinks ul {
    margin-top: 0 !important;
  }
  .footersociallinks {
    margin-bottom: 20px;
  }
  .swiper-button-next {
    transform: translate(599px, -17px) !important;
  }
  #maticzFixedNavbar.container_custom .burger {
    float: right;
  }
  .navbar_items {
    justify-content: flex-start !important;
    width: 93% !important;
  }

  .downfarrow {
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 999;
    left: calc(100% - 279px) !important;
  }
  #accept_price_item_modal .approve_text {
    color: #000 !important;
    font-size: 16px !important;
    text-align: left !important;
  }
  .downfarrow {
    display: block !important;
  }

  .bannerimges {
    display: none;
  }
  .swiper {
    background-image: url("../src/assets/images/Group-88.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    max-width: 600px;
  }
  .editprofileinputlist {
    align-items: start !important;
  }
  .bidnowbtn {
    justify-content: center !important;
  }
  .editprofileinputlist {
    margin-bottom: 30px;
  }
  .inputypetest {
    border-bottom: none !important;
    margin-top: unset !important;
    padding-bottom: unset !important;
  }

  .editprofilecontent {
    margin-bottom: 40px !important;
  }
  .subscribebnft p {
    width: unset !important;
  }
  /* .infosecinfo {
    margin: auto;
  } */
  .infosecinfo {
    border-radius: 5px !important;
    max-height: auto;
    min-height: auto;
    width: 75%;
  }
  .infolist {
    margin-top: 0px;
  }
  .bidnowbtn {
    justify-content: center;
  }
  .explorepage .tabbable-line {
    flex-direction: column;
    gap: 30px;
  }
  .homebanerheight {
    text-align: center;
  }
  .homebanerheight .content {
    margin-top: 80px;
  }
  .bannerimges img {
    max-height: 380px !important;
    min-height: 380px !important;
  }
  .live_auctions h2 {
    padding-bottom: 0px !important;
  }
  .notfound {
    padding-top: 0px !important;
  }

  .bannerimges {
    top: unset !important;
    left: 0;
    right: 0;
  }
  .homebanner .tokencardsec {
    margin-left: 110px !important;
  }

  .homebanner .timercontent {
    left: 140px !important;
  }

  .homebanner .tokencardsec {
    max-width: 230px !important;
  }

  /* .homebanerheight h1{font-size: 46px !important;}
  .homebanerheight h1 span{font-size: 48px !important;} */
  .homelinknavae {
    display: none !important;
  }

  .searchbarmob {
    width: 400px;
    margin-top: 30px;
    display: block;
  }
  /* .searchbar:hover, .searchbar:focus-visible {
    background-color: rgb(116 116 116 / 50%) !important;
} */
  #mobilenav .searchbar {
    display: block;
  }
  .pHieT > ul {
    background: #000 !important;
    border-radius: 24px !important;
  }
  .homelinksmobile {
    display: block;
  }
  .homelinks {
    display: none;
  }
  .homelinkswhole {
    display: block;
  }
  .buybtn {
    margin-left: 20px;
  }
  .about_bg img {
    width: 100%;
  }
  .homelinkswhole,
  .homelinklist {
    margin-top: 40px;
  }
  .burger {
    display: block;
  }
  #mobilenav {
    z-index: -1;
    width: 47%;
  }
}
@media (max-width: 1030px) {
  #videolinks .video-wrapper video {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 767px) and (min-width: 575px) {
  .homebanerheight h1 {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 890px) and (min-width: 601px) {
  .home_img .img1,
  .home_img .img3 {
    width: 40px !important;
  }
}
@media (max-width: 767px) {
  .searchbarmob {
    width: 250px;
  }
  .home_header .content-banner .positionAbs {
    position: absolute !important;
    top: 20px !important;
  }
  .subscribe {
    padding: 25px 0px;
  }
  .mobile_search i {
    margin-right: 10px !important;
  }
  #videolinks .video-wrapper {
    min-height: 130px !important;

    max-height: 240px !important;
  }
  .header_main img {
    width: 400px;
  }
  .infosecinfo {
    border-radius: 5px !important;
    max-height: auto !important;
    min-height: auto !important;
    width: 85%;
  }
  .recentlyadded h2 {
    font-size: 23px !important;
  }
  .live_auctions h2 {
    font-size: 23px !important;
  }
  .connectImg {
    margin-right: 10px;
    margin-left: 10px;
  }
  .subscribebnft h2 {
    font-size: 23px !important;
  }
  .connectImg img {
    position: relative;
    left: 0px;
    top: -3px;
  }
  .infosecinfo img {
    max-height: auto !important;
    min-height: auto !important;
  }
  /* .infosec .tab-content {
    height: 550px !important;
  } */
  .live_auctions {
    padding: 32px 0 32px 0;
  }
  .recentlyadded {
    padding: 0px 0;
  }
  .desktopview {
    display: none !important;
  }
  .mobileview {
    display: block !important;
  }
  .tabview {
    display: none !important;
  }

  .video-wrapper {
    position: relative;
  }
  .homebanerheight h5 {
    font-size: 17px !important;
  }
  .learnmore i {
    font-size: 10px;
  }
  .learnmore {
    font-size: 16px;
  }
  #mobilenav {
    z-index: -1;
    width: 47%;
  }
  .homelinkswhole {
    margin-top: 40px !important;
  }
  .homelinklist {
    padding: 8px 20px !important;
  }

  /* .searchiconsd{    margin-right: 22px;} */
  .editprofileprofile .before_inter::after {
    top: 7px !important;
  }
  .editprofileprofile {
    width: 100%;
  }
  .navbar_items {
    width: 72%;
  }
  .editprofilebanner {
    overflow: hidden;
    min-height: 250px !important;
    max-height: 250px !important;
  }
  .homebanner .tokencardsec {
    margin-left: 71px !important;
  }
  .downfarrow {
    width: 25px !important;
    height: 25px !important;
    max-width: 100% !important;
    min-height: unset !important;
    max-height: unset !important;
    transform: rotateZ(90deg);
    position: absolute;
    top: 272px;
    left: 397px;
    z-index: 99;
  }

  .infolist h2 {
    text-align: center;
    margin-bottom: 13px;
  }
  .editprofileprofile {
    left: 20px !important;
    width: 70% !important;
  }
  .editprofilepage h4 {
    text-align: center !important;
  }
  .editprofileedit {
    bottom: 130px !important;
    right: 40px !important;
  }
  .editprofileedit img {
    width: 30px !important;
    top: 59px;
  }
  .editprofilepage {
    margin-top: 0 !important;
  }
  .bidnowbtn {
    justify-content: center !important;
  }
  .editprofileinputlist {
    margin-bottom: 40px !important;
  }
  .inputypetest {
    border-bottom: none !important;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .myitemsedit {
    bottom: 45px !important;
  }

  .myitemsbanner {
    border: 8px solid #fff !important;
  }
  .footersociallinks {
    margin-bottom: 20px;
  }
}
.heart {
  margin-bottom: 0;
}
.tokencardinfosec h3 {
  margin-bottom: 0;
}
.tokencardinfosec {
  margin-bottom: 10px;
}
.searchlistsec {
  padding: 10px;
  position: relative;
  top: 10px;
}
@media (max-width: 575px) {
  .mobile_search .srecat {
    max-height: 400px;
    min-height: 400px;
  }
  .ub_about_brands {
    width: 50% !important;
  }
  .learnmore a {
    font-size: 14px;
  }
  /* .card_client{} */
  .mobile_content {
    display: block !important;
  }
  .homebanner .content {
    display: none !important;
  }

  /* .placeholder-glow img.placeholder{max-height:946px;} */

  .homebanner {
    position: relative;
    /* padding-top: 112px; */
  }
  .homebanner img {
    padding-top: 0px;
  }

  body .homebanerheight h5 {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }
  .learnmore {
    font-size: 13px;
  }
  .learnmore i {
    font-size: 12px;
  }
  body .home_header .home_bg .homebanerheight {
    min-height: 30vh !important;
  }
  /* .homebanner{margin-top:55px !important; } */
  .live_auctions h2 {
    padding: 0px 0px 0px;
  }
  .text-center.notfound {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .footerhead .footer__logo img {
    width: 60% !important;
    margin-left: 0 !important;
    margin: auto;
  }
  /* .seathbat .mobile_search i{
  margin-right:0px !important
} */
  .infosecinfo {
    border-radius: 5px !important;
    max-height: 100%;
    min-height: 100%;
  }
  .connectImg img {
    width: 20px !important;
    position: relative;
    left: 17px;
    top: -2px;
  }
  ::-webkit-scrollbar {
    width: 1px;
    height: 2px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(97, 44, 210, 0.2);
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a279fa;
  }

  .myitemsapge .myitemspage {
    margin-top: 0px;
  }
  /* .infosec .tab-content {
    height: 670px;
  } */
  #buynow .modal-footer .buybtn {
    margin-bottom: 0 !important;
    padding: 7px 20px !important;
  }
  /* #putonsale .startwalletbtns{flex-direction: column;} */
  .infotabdetails h1 {
    padding-top: 5px;
  }
  #putonsale .buybtn,
  #PurchaseStep_modal .buybtn,
  #price_item_modal .buybtn {
    padding: 5px 26px !important;
    margin-bottom: 0 !important;
  }
  #putonsale .buybtn {
    width: 94% !important;
  }
  .home_links {
    width: 70%;
  }
  .termsofservice ol {
    padding: 0;
  }
  .termsofservice h1 {
    text-align: center;
  }
  .infosec h1 {
    font-size: 36px;
    text-align: center;
  }
  .infotabdetails h1 {
    font-size: 24px;
  }
  .aboutuspagesec h1,
  .activitysec h1,
  .myitemspagesec h1,
  .explorepagesec h1 {
    font-size: 36px;
    text-align: center;
  }
  .connectwallet {
    line-height: 24px !important;
  }
  .recentlyadded {
    padding: 0px 0;
  }
  .navbar_items {
    width: 88% !important;
    justify-content: space-between !important;
  }
  .header_main img {
    width: 400px;
  }
  .live_auctions {
    padding: 32px 0 32px 0;
  }
  .infodos .buybtn.buybtns {
    text-align: center !important;
    margin: 0 auto !important;
  }
  #videolinks .video-wrapper {
    min-height: 50px !important;
    max-height: 150px !important;
  }
  /* .video-wrapper{height: 25vh;} */
  .footerhead {
    padding-bottom: 10px;
    position: relative;
  }

  .subscribebnft p {
    font-size: 16px !important;
    line-height: 26px;
    margin-top: 5px;
    color: #1f1f1f;
  }
  .recentlyadded h2 {
    font-size: 17px !important;
  }
  .live_auctions h2 {
    font-size: 17px !important;
  }
  .owl-theme .owl-dots .owl-dot.active {
    width: 30px;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 27px !important;
  }
  .homebanerheight h5 {
    font-size: 20px !important;
    letter-spacing: 1px !important;
    margin-top: 30px;
  }
  .not_found_text {
    font-size: 26px;
  }
  .footer_flez {
    align-items: baseline;
  }
  .homebanerheight h1 .spanclasd {
    font-size: 32px !important;
  }
  .homelinkswhole {
    margin-top: 60px !important;
  }
  .homelinkswhole li {
    pad: 30px !important;
  }
  .seathbat {
    justify-content: center !important;
    width: auto !important;
  }
  .seathbats {
    justify-content: center !important;
    /* position: relative;
  right: 11px; */
  }
  /* .searchiconsd{display: block;} */
  .myitemsprofile .before_inter::after {
    top: 4px !important;
    left: 35px !important;
    border-radius: 10px !important;
    width: 51px !important;
    height: 50px !important;
  }
  .editprofileprofile .before_inter::after {
    top: 5px !important;
    width: 70px !important;
    height: 70px !important;
    left: 5px !important;
  }
  .searchbarexplore .searchlist {
    left: 0px !important;
    position: absolute;
    right: 0px !important;
    margin: 0 auto;
    width: 90%;
  }

  .editprofilepage h4 {
    text-align: left !important;
  }
  .searchlist {
    top: 50px;
  }
  .searchlist {
    background-color: rgba(133, 100, 201, 0.1);
    border-radius: 0px;
  }
  .mobile_search {
    opacity: 1 !important;
  }
  .searhnaf.navbar_items {
    width: 0% !important;
  }

  .burger p:first-child {
    /* height: 2px; */
    width: 20px;
    background: #fff;
  }
  .burger p:nth-child(2),
  .burger p:last-child {
    /* height: 2px; */
    width: 20px;
    background: #fff;
  }
  /* .searchbar{display: none;} */
  .owl-carousel .owl-nav button.owl-next {
    right: -6px !important;
  }
  .myitemspage .filetupepend i {
    padding: 7px !important;
    top: -15px !important;
    font-size: 10px;
  }
  .myitemspagesec .tabbable-line .nav-tabs {
    flex-direction: row;
    /* gap: 10px; */
    flex-wrap: nowrap;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  .myitemspagesec .tabbable-line .nav-tabs li {
    padding: 5px 13px;
  }
  .subscribebnft h2 {
    font-size: 17px !important;
    text-align: center;
    color: #1f1f1f;
  }
  .editprofilebanner {
    overflow: hidden;
    min-height: 180px !important;
    max-height: 180px !important;
  }
  .downfarrow {
    display: none !important;
  }
  .searchbarexplore {
    margin: 0 auto !important;
    width: 100% !important;
  }
  .editprofile .filetupepend i,
  .editprofile .filetupepend input {
    padding: 5px;
    left: 51px !important;
    right: 0;
    font-size: 11px;
    bottom: 50px !important;
    font-size: 10px !important;
  }
  .editprofileprofile img {
    width: 80px !important;
    height: 80px !important;
    border: 5px solid #fff !important;
  }
  .editprofileprofile {
    top: -40px !important;
  }
  .arryasearch {
    padding: 11px 8px;
  }

  #mobilenav {
    width: 100% !important;
  }
  #bidnow input {
    width: unset !important;
  }
  .infotabdetailsroyal p {
    text-align: center !important;
  }
  .infotabdetailsroyal {
    flex-direction: column;
  }
  .infotabdetails h1 {
    text-align: center;
  }
  .bidnowbtn {
    gap: 10px !important;
    text-align: center !important;
  }
  .buybtn {
    margin: 0 !important;
  }
  .editprofileinputlist {
    justify-content: center !important;
    flex-direction: column !important;
  }
  .editprofiledisplay input {
    width: 100% !important;
  }
  .editprofiledisplay {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .myitemspagesec .tabbable-line .nav-tabs li {
    margin-right: 0 !important;
  }

  .myitemsedit {
    right: 25px !important;
    bottom: 7px !important;
    font-size: 13px !important;
  }
  .myitemsbanner img {
    min-height: 250px !important;
  }
  .myitemsbanner {
    min-height: 150px !important;
    max-height: 150px !important;
    border-radius: 30px !important;
    border: 5px solid #fff !important;
  }
  .infosec .tabbable-line .nav-tabs li a {
    font-size: 16px !important;
    padding: 5px 18px !important;
  }
  .infosec .tabbable-line .nav-tabs {
    margin-top: 50px;
    border: none !important;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .myitemsprofile {
    top: -29px !important;
  }
  .myitemsprofile img {
    width: 60px !important;
    height: 60px !important;
    border-radius: 10px !important;
    border: 4px solid #fff !important;
  }

  .explorefles .nav-tabs {
    overflow: auto;
    white-space: nowrap;
  }

  .explorefles .nav-tabs li {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  .searchbarexplore {
    margin: 0 auto;
  }

  .homebanerheight h1 {
    font-size: 29px !important;
    letter-spacing: 2px !important;
    font-weight: 400 !important;
  }

  .homebanerheight h1 span {
    font-size: 54px !important;
  }

  .homebanner .tokencardsec {
    max-width: 280px !important;
  }

  .homebanerheight p {
    /* font-size: 20px !important; */
    line-height: 19px !important;
  }
  .homebanner .tokencardsec {
    margin-left: 27px !important;
  }
  .owdlsliderpara {
    bottom: -10px;
  }
  .bannerimges img {
    max-height: 237px !important;
    min-height: 150px !important;
  }
  /* .home_header{margin-top: -112px !important;} */
  .infosecinfo img {
    width: 100% !important;
    max-width: none !important;
    border-radius: 5px;
  }
  .infosecinfo {
    border-radius: 5px !important;
  }
  #navbar .custom-container {
    flex-wrap: nowrap !important;
  }
  .infolist {
    flex-direction: column;
    margin-top: 20px;
  }
  .infosec h3,
  .infosec p {
    text-align: left;
  }
  .bidnowbtn {
    justify-content: center !important;
  }
  .buybtn {
    padding: 0px 28px !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  .footersociallinks {
    margin-bottom: 10px;
  }
  .unblocklogonav {
    max-width: 100%;
    width: 140px;
  }

  .owdlsliderpara h2 {
    /* font-size: 24px; */
    text-align: left;
    margin-bottom: 4px;
  }
  #mobilenav {
    top: 0%;
    z-index: -1;
    width: 47%;
  }
  .homelinkswhole {
    margin: 0;
    padding: 0;
  }
  .homelinklist {
    border-bottom: 1px solid rgb(0 0 0 / 31%);
    padding-left: 12px;
    margin-top: 10px;
  }
  .homebanerheight h5 {
    margin-top: 0 !important;
  }
  .searchbarmob {
    width: 130px;
  }
}
@media (max-width: 361px) {
  .termsofservice p {
    font-size: 12px !important;
  }
  .unblocklogonav img {
    width: 60% !important;
  }
  .footerhead .footer_top_logo {
    width: 60% !important;
  }
  .unblocklogonav {
    width: 90% !important;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 30px !important;
  }
  .homebanerheight h1 {
    font-size: 28px !important;
    letter-spacing: 2px !important;
    /* font-weight: 900 !important; */
  }
  .navbars.dropdown .dropdown-content {
    min-width: 210px;
  }
  .connectwallet {
    font-size: 14px !important;
  }
  .seathbats .connectwallet {
    padding: 0px 14px !important;
  }
  .homebanerheight h5 {
    font-size: 20px !important;
    margin-top: 0 !important;
  }
  .mobile_search i {
    margin-right: 9px !important;
    font-size: 15px !important;
  }
  .connectwallet span {
    font-size: 14px;
  }
}
@media screen and (min-width: 1280px) {
  .homebanerheight h1 span {
    font-size: 70px !important;
  }
}
@media screen and (min-width: 999px) {
  .search-data-token .col-lg-7,
  .homebanerheight .content {
    width: 64% !important;
  }
}
@media (max-width: 280px) {
  /* .subscribebnft h2 ,.recentlyadded h2,.homebanerheight h1{
    font-size: 26px !important;} */
  .unblocklogonav {
    width: 95px !important;
  }
  .video-wrapper {
    height: 16vh;
  }
  body .homebanerheight h1 {
    font-size: 20px !important;
  }
  .homebanerheight .content {
    margin-top: 40px;
  }
  #videolinks .video-wrapper {
    height: 140px !important;
  }
  .homebanerheight h5 {
    font-size: 21px !important;
  }
  .unblocklogonav img {
    width: 113px !important;
  }
  .connectwallet {
    padding: 0px 14px !important;
    font-size: 10px !important;
  }

  .navbar_items {
    width: 84% !important;
  }
  .homebanerheight h4 {
    font-size: 60px;
  }
  .homebanerheight h4 .spanclasd {
    font-size: 50px;
  }
  .homebanerheight h5 {
    margin-top: 0 !important;
  }
  .mobile_search i {
    margin: 7px !important;
  }
}

.myitemspagesec .buybtn {
  border-radius: 15px !important;
}
.connectwallet span {
  line-height: 25px;
}
.connectwallet {
  height: 35px;
  line-height: 22px !important;
}
.explorepage {
  background: #fff;
}
b,
strong {
  font-weight: 200 !important;
}
body {
  background-color: #ffffff !important;
}
.sectionAnother p {
  font-size: 30px;
  font-weight: 500;
  color: #333;
}
.accordion-button::after {
  content: "+" !important;
  background-image: none !important;
  transition: none !important;
  color: #0c63e4;
}
.accordion-button:not(.collapsed)::after {
  content: "-" !important;
  transform: none !important;
  color: #0c63e4 !important;
}
.accordion-item {
  background-color: transparent !important;
  border-color: transparent !important;
}
.accordion-button {
  background-color: transparent !important;
}

.connect_wallet:hover,
.connect_wallet.search_borwhite:hover {
  background: rgba(133, 100, 201, 0.25) !important;
  border: 1px solid #fff !important;
  color: #fff600 !important;
  text-decoration: none;
}
.connect_wallet {
  background: transparent !important;
  border: 1px solid #0071e3 !important;
  color: #fff600 !important;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize !important;
  border: none;
  border-radius: 30px !important;
  transition: 0.5s ease-in-out !important;
  padding: 3px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.connect_wallet.search_borwhite {
  background: transparent !important;
  border: 1px solid #ffff !important;
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize !important;
  border: none;
  border-radius: 30px !important;
  transition: 0.5s ease-in-out !important;
  padding: 3px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.connect_wallet.search_borwhite.hm_changed {
  border: 1px solid #0266cb !important;
  color: #0266cb !important;
}
.connect_wallet1 {
  color: #fff !important;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize !important;
  border: 2px solid #00b1ff !important;
  border-radius: 10px !important;
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;

  text-decoration: none;
}
.connect_wallet1:hover {
  background: linear-gradient(180deg, #00b1ff 0%, #005dff 100%) 0% 0% !important;
  color: #fff !important;
}

.modal-footer .bidbtn:hover {
  /* border: 0px solid rgba(162, 121, 250, 0.5) !important; */

  background: rgba(97, 44, 210, 0.2) !important;
  /* color: #00000000 !important; */
  text-decoration: none;
}
.modal-footer .bidbtn {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #0071e3 !important;
  cursor: pointer;
  font-size: 18px;
  text-transform: capitalize !important;
  border: none;
  border-radius: 30px !important;
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
.mobile_search i {
  color: #d6d6d6;
  z-index: 0;
  font-size: 14px;
}
.footerhead .copyrightsde {
  padding-bottom: 15px !important;
}
.connectImg img {
  width: 35px;
}
.img-fluid1 {
  max-width: 100% !important;
  margin-right: 65px;
  vertical-align: top;
}
.logos_marquee {
  margin-right: 150px;
}
.about_max_width {
  max-width: 90%;
  margin: auto;
}
@media screen and (max-width: 1199px) {
  .about_max_width {
    max-width: 100% !important;
  }
  .images-about {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .about_max_width img {
    margin-bottom: 50px;
  }
  .srecat {
    min-height: 400px;
    max-height: 400px;
  }
  .connectImg {
    /* width: 30px; */
    text-align: center;
    /* margin-right: 10px; */
    margin-left: 10px;
  }
}
@media screen and (max-width: 575px) {
  .about_max_width .mobi {
    width: 50% !important;
    max-width: 50% !important;
  }
  .sectionAnother p {
    font-size: 20px;
    font-weight: 500;
  }
  .connectImg {
    width: 70% !important;
    justify-content: space-evenly !important;
  }
  .connectImg img {
    width: 20px !important;
    position: relative;
    left: 0px !important;
    top: 0px !important;
  }
  .content-banner .positionAbs {
    display: none;
  }
  .not_found_text_sub {
    font-size: 13px;
  }
  .subscribe {
    padding: 25px 0px;
    background: #000;
  }
  .fm_newdtl_txt,
  .fm_getting_txt {
    color: #fff !important;
    font-size: 14px;
  }
  .new_subscribe_mob {
    color: #fff !important;
  }
  .live_auctions .row {
    margin-top: 0px !important;
  }
  .fm_learnmore_btn p {
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .about_max_width .mobi {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.modal-footer .bidbtn {
  padding: 3px 25px !important;
}
.faq .accordion-button:not(.collapsed) {
  font-size: 17px !important;
  color: #0c63e4 !important;
}
.accordion-button:not(.collapsed) {
  color: #0c63e4 !important;
}

@media screen and (max-width: 990px) {
  .inputypetest .text-danger {
    position: relative !important;
    top: -30px !important;
  }
  .infosecinfo {
    border-radius: 5px !important;
    max-height: auto;
    min-height: auto;
    width: 100%;
  }
  .content-banner .positionAbs {
    position: absolute !important;
    top: 20px !important;
  }
  /* .home_header {
    margin-top: -1px !important;
} */
  /* .homebanerheight{min-height:70vh !important} */
  body .burger p {
    width: 20px !important;
  }
  .burger p {
    height: 1px !important;
  }
  .connectImg img {
    width: 30px !important;
  }
  .images-about {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  /* .infosecinfo img{
    max-height: 750px;
    min-height: 750px;
  } */
}
.aboutuspagesec .video-wrapper {
  width: 75%;
  max-width: 75%;
}
.aboutuspagesec .video-wrapper.video_width_align {
  width: 100% !important;
  max-width: 100% !important;
  text-align: center;
  max-width: 1575px !important;
}
/* @media screen and (max-width:480px){
  .infosecinfo {
    border-radius: 30px !important;
    max-height: 400px !important;
    min-height: 400px !important;
    width: 75%;
  }
  .infosecinfo img{
    max-height: 400px !important;
    min-height: 400px !important;
  }
} */
/* .burger{padding-right: 0px;} */
.burger p {
  background: #d6d6d6 !important;
}
.not_found_text {
  font-size: 20px;
}
.owdlsliderpara {
  text-align: left;
}
a:focus-visible {
  outline: none !important;
  transform: none !important;
  box-shadow: 0px 0px !important;
}
.form-control:focus {
  box-shadow: none !important;
  border: 1px solid lightgray !important;
}
.form-control:focus-visible {
  outline: none !important;
}
@media screen and (max-width: 767px) and (min-width: 479px) {
  .infosecinfo {
    width: 100% !important;
  }
  .nav-tabs {
    width: 100% !important;
  }
}
@media screen and (max-width: 480px) {
  .infosecinfo {
    width: 100%;
  }

  .connectImg img {
    width: 20px !important;
    position: relative;
    left: 7px;
    top: -2px;
  }
  .burger {
    padding-right: 0px;
  }
  .tokencardinfosec h3 {
    font-size: 13px;
  }
  .connectwallet {
    padding: 5px 4px !important;
  }
  .connectwallet span {
    font-size: 12px;
  }
  .tokencardinfoheade {
    text-align: center;
  }
  .tokencardinfoheade p {
    text-align: left;
  }
}
.aboutuspagesec .sectionAnother {
  margin-bottom: 70px !important;
}
.searchiconsd img {
  width: 18px;
}
.searchiconsd .arryasearch img {
  width: 40px;
}
.mobile_search img {
  width: 24px;
  /* padding-left: 10px;
  padding-right: 2px; */
}
.searchbarsd img {
  width: 24px;
  padding-left: 10px;
  padding-right: 2px;
}
.burger p {
  height: 2px !important;
}
.mobile_search .arryasearch img {
  width: 40px;
}
.explorepagesec.search {
  margin-top: 45px;
}

.pls_wait {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* .pls_wait .logo_load_modal{
  min-width:200px;
  min-height: 300px;
} */
/* @media screen and (min-width:1200px){
  .video-wrapper video{
    max-width:1062px !important;
    width:1062px;
    height:531px;
  }
} */
.infoiconslist i {
  cursor: pointer;
}
.connectImg img {
  cursor: pointer;
}

body .modal-footer .MuiButton-Text {
  background: transparent !important;
  border: 1px solid #0071e3 !important;
  color: #0071e3 !important;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize !important;
  border: none;
  border-radius: 30px !important;
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
  margin-bottom: 20px;
  width: 80%;
}
.explorepage .tabbable-line .nav-tabs li.active a {
  font-weight: 700 !important;
}
.filetupepend i {
  cursor: pointer;
}
.infosec .tabbable-line .nav-tabs li.active a:hover {
  background-color: transparent;
  color: #000;
  display: inline-block;
  box-shadow: 0px 0px;
}
.infosec .tabbable-line .nav-tabs li a:hover {
  color: #000;
  background-color: transparent;
  box-shadow: 0px 0px;
}
.modal-open {
  overflow: auto !important;
}
/* .homebanner{background:url("../src/assets/images/art_bg.jpg") no-repeat scroll center;background-size: 100% 100%;
  } */
.homebanerheight .owl-carousel {
  display: none !important;
}

/* .navbar{margin-top:30px;} */
.navbar.scroll {
  margin-top: 0px;
}
/* video[poster]{
height: 50px;
width: 100px;

} */
@media only screen and (min-width: 576px) {
  .trust_wallet {
    display: none !important;
  }
  /* .mobile_content{display:none !important} */
}

.home_bg .homebanerheight {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.homebanerheight {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  background: transparent !important;
}

.home_img {
  width: 100%;
  object-fit: cover;
}
.mobile_content {
  background: #000;
}
.mobile_content .homebanerheight {
  max-height: 140px !important;
  margin-top: 15px;
  background-color: #fff !important;
}
.homebanerheight .content {
  /* padding: 30px 0px 0px; */
  margin-top: 0px;
}
.homebanner img {
  max-width: 100%;
  height: 100%;
  max-height: 750px;
  max-width: 950px;
  margin: auto;
}
.owl-dot span {
  background: #fff;
  width: 6px !important;
  height: 6px !important;
  border-radius: 50%;
  display: block;
  margin: 3px;
}
.owl-dots .owl-dot.active {
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 50%;
  display: block;
  margin-right: 5px;
}
.owl-dots .owl-dot {
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 50%;
  display: block;
  margin-right: 5px;
}
.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-banner {
  position: relative;
  height: 100%;
  background: #fff;
}
.content-banner .positionAbs {
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 99;
}
@media screen and (max-width: 1279px) {
  .content-banner .positionAbs {
    top: 20px !important;
  }
}
@media screen and (max-width: 450px) {
  .card_client {
    flex: 0 0 auto;
    /* width: 100% !important; */
  }
}
@media screen and (max-width: 400px) {
  .tokencardsec {
    /* width: 70%; */
    margin: auto;
  }
  .connectwallet {
    padding: 5px 12px !important;
  }
  .ub_about_brands .img-fluid1 {
    max-width: 55% !important;
    height: auto;
  }
  .ub_about_brands .img-fluid1.fox_image {
    max-width: 40% !important;
    min-width: 40% !important;
    height: auto;
  }
}
.img-placeholder {
  max-width: 1598px;
  min-height: 1598px;
  max-height: 946px;
  min-height: 946px;
}
.subscribe {
  margin-top: -3px;
}
@media only screen and (min-width: 400px) and (max-width: 576px) {
  .ub_about_brands .img-fluid1.fox_image {
    max-width: 40% !important;
    min-height: 40px;
    max-height: 40px;
  }
  .ub_about_brands .img-fluid1 {
    max-width: 55% !important;
    height: auto;
  }
}
.subs_bg {
  background-color: #9e9e9e;
  border-radius: 50%;
  padding: 8px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.subs_bg .keyboard_icon_info {
  padding: 0;
  color: #fff !important;
  margin: 0;
}
.email_label_pop {
  color: #000 !important;
}
.email_label_pop.email_popkr {
  padding: 0 !important;
  margin-bottom: 20px !important ;
}
.subs_bg .keyboard_icon_info:hover {
  padding: 0 !important;
}
.subs_bg:hover {
  background-color: #00affe;
}
.info_subs_icon {
  height: 17px;
  width: 17px;
  filter: invert(1);
}
.img-fluid1.fox_image {
  max-width: 50% !important;
  margin-right: 0px;
  margin-left: 30px;
}

.playstore_img {
  position: relative;
  top: -2px;
  width: 120px !important;
  z-index: 10 !important;
}
.ques_bold {
  font-weight: 600 !important;
  color: #000 !important;
}
.faq_img.img_aligned,
.faq_img.faq_img_aligned {
  text-align: center;

  overflow: hidden;
}
.faq_img.img_aligned img {
  max-width: 400px;
  width: 100%;
  margin: auto;
}
.faq_img.faq_img_aligned img {
  max-width: 750px;
  width: 100%;
  margin: auto;
}
.image_nd_border {
}
/* .java_img{max-width: 100%;height: auto;} */
.android_log.tech_logos {
  max-width: 30% !important;
}
.poly_logo.tech_logos {
  max-width: 40% !important;
}
.tech_logos {
  max-width: 35% !important;
}
.java_tech_logo.tech_logos {
  max-width: 20% !important;
}
.crystal_logo.tech_logos {
  max-width: 25% !important;
}
.token_descript {
  text-align: left;
  font-family: "HelveticaNeue" !important;
}
@media only screen and (min-width: 319px) and (max-width: 992px) {
  .footersociallinks {
    justify-content: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .img-fluid1.fox_image {
    max-width: 40% !important;
  }
  .tech_logos {
    max-width: 30% !important;
  }
  .java_tech_logo.tech_logos {
    max-width: 25% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .img-fluid1.fox_image {
    max-width: 40% !important;
  }
}

.quicklinks ul li a:hover {
  color: #00b1ff !important;
}
.connectImg .disc_wallet {
  fill: #00affe !important ;
}
/* .burger.clicked img{filter: invert(1) !important;} */
.logo_row.row {
  align-items: center !important;
}
.infosec.otherpage {
  margin-top: -2px;
  min-height: calc(100vh - 375px);
}
.customerdata_h1 {
  color: #000;
  font-family: "HelveticaNeue";
  font-size: 36px;
  font-weight: 400;
  margin-top: 43px;
}
.customerdata_tab table th {
  color: #000 !important;
  background: none;
  background-size: 0px;
  font-weight: 500;
}
i.fa.fa-file-text-o::before {
  content: "\f15c";
}
i.fa.fa-file-excel-o::before {
  content: "\f1c3";
}

@media only screen and (min-width: 319px) and (max-width: 576px) {
  .tech_logos {
    max-width: 30% !important;
  }
  .java_tech_logo.tech_logos {
    max-width: 25% !important;
  }
  .instrock_al {
    margin-top: 35px !important;
  }
  .infosecinfo {
    margin-top: 30px !important;
  }
  .fm_newdtl_img {
    height: 35px !important;
    width: 35px !important;
  }
}
@media only screen and (max-width: 992px) {
  .aboutuspagesec .sectionAnother {
    margin-bottom: 20px !important;
    margin-top: 30px !important;
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #cdcdcd;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.toggle_custom .overleft {
  background: #0071e3 !important;
}
.kr_fixedprice.fixedprice {
  box-shadow: none !important;
}
.kr_fixedprice.fixedprice p {
  padding-top: 0px !important;
}
.kr_itemsimgs {
  min-height: 225px !important;
  max-height: 225px !important;
  width: 95% !important;
  box-shadow: 0px 0px 12px 2px rgb(0 0 0 / 20%);
}
.item_imgs img.putonsale_img {
  min-height: 180px !important;
}
.css-1dimb5e-singleValue.kr_reactslct__single-value.react-select {
  overflow: visible !important;
  text-overflow: unset !important;
}
.react-select.kr_kycreactslct__control.css-13cymwt-control {
  border-radius: 15px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  width: 100% !important;
  border: none !important;
}
#kycupdate .css-b62m3t-container > .react-select {
  border-radius: 15px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  width: 100% !important;
  border: none !important;
  margin-bottom: 20px;
}

.css-b62m3t-container:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
.kr_kycreactslct__control
  .react-select.kr_kycreactslct__control--is-focused.kr_kycreactslct__control--menu-is-open.css-13cymwt-control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
/* .react-select.kr_kycreactslct__input::placeholder{
  
} */
.kyc_placeholder {
  font-size: 14px !important;
  color: gray !important;
  padding-left: 25px;
}
.css-b62m3t-container {
  padding: 0 !important;
}
.about_banner_content {
  padding: 0px 10px;
}
.about_banner_maintxt {
  color: #000;
  font-size: 50px;
  text-align: center;
  margin: 0;
  padding-top: 10px;
  /* margin-bottom: 30px; */
  padding: 0px 50px;
}
.about_ban_title {
  color: #000;
  /* font-family: 'Aileron', sans-serif; */
  /* font-family: 'Aileron' !important; */
  /* font-weight: medium;  */
  margin-top: 30px;
  font-size: 22px;
}
.about_commonp {
  font-size: 16px;
  font-family: "HelveticaNeue" !important;
  color: #000 !important;
}

.fm_newdtl_whole {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}
.fm_newdtl_img {
  height: 50px;
  width: 50px;
  margin-right: 20px;
}
.fm_getting_txt {
  font-size: 20px !important;
}

.fm_newdtl_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.fm_newdtl_txt {
  font-size: 20px;
  text-align: left !important;
  margin-top: 0px !important;
}
.fm_learnmore_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 120px;
  cursor: pointer;
  margin-top: 13px;
}
.fm_learnmore_btn p {
  margin: 0;
  margin-right: 10px;
  color: #0266cb;
  font-size: 23px;
  font-family: "HelveticaNeue";
}
.fm_playicon {
  height: 25px;
  width: 25px;
}
.font_aileron {
  font-family: "HelveticaNeue" !important;
}
.newsubscribenft.subscribebnft {
  align-items: flex-start !important;
  padding: 0px 30px;
}
.newsubscribenft.subscribebnft p {
  text-align: left;
  color: #787878 !important;
  font-weight: 600;
  font-size: 23px;
}
#video_modal .modal-content {
  width: unset !important;
}
.modal.fade {
  z-index: 100;
}
.home_subscribe.subscribe {
  background-color: #fff;
  /* padding: 50px 0px 0px 0px; */
}
.fm_topdata_ttl {
  margin-bottom: 60px !important;
  margin-top: 30px !important;
}
.new_aboutuspage.aboutuspagesec p {
  color: #000;
}
.new_sectionAnother.sectionAnother p {
  font-size: 38px;
}
.newtext_clrliveauc.not_found_text_sub {
  color: #787878 !important;
}
.black_txt.fm_getting_txt.mob_minus {
  color: #000 !important;
  font-weight: 600;
}
.black_txt {
  color: #000 !important;
}
.robotoBold {
  font-family: "HelveticaNeue" !important;
}
.robotoReg,
.robotoReg p,
.robotoReg b {
  font-family: "HelveticaNeue" !important;
}
.fm_newdtl_txt.robotoReg {
  font-size: 20px !important;
}
.hide_homebannerheight.homebanerheight {
  display: none;
}
.kodu {
  height: 1px;
  max-width: 90% !important;
  left: 5%;
  position: relative;
  background-color: #e2e2e2;
}
.homebanner.homebanner_bg,
.owl-carousel .owl-stage {
  background-color: #fff !important;
}
#home_modalvideo {
  border-radius: 30px;
}

.faq_para p {
  font-family: "HelveticaNeue";
}
.faq_anstxtclr p,
.faq_anstxtclr,
.faq_anstxtclr li {
  color: #000;
  font-family: Helvetica Neue Light !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.about_redfree {
  color: #ff0303;
  font-weight: 600 !important;
}
@media only screen and (max-width: 992px) {
  .connectwallet.connectwallet_mobbot {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .new_sectionAnother.sectionAnother p {
    font-size: 30px;
  }
  .homebanerheight h1 {
    font-size: 40px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .new_sectionAnother.sectionAnother p {
    font-size: 25px;
  }
}
@media only screen and (min-width: 319px) and (max-width: 576px) {
  .subscribe {
    padding-top: 0px !important;
  }
  .mob_minus.fm_getting_txt {
    position: relative;
    top: -10px;
  }
  .fm_getting_txttop.fm_getting_txt {
    margin-top: 15px;
  }
  .recentlyadded h2 {
    font-size: 25px !important;
  }
  .live_auctions h2 {
    font-size: 25px !important;
    margin-top: 30px;
  }
  .fm_playicon {
    height: 25px;
    width: 25px;
  }
  .fm_learnmore_btn p {
    font-size: 21px;
  }
  .mob_minus.robotoReg {
    font-size: 21px !important;
  }
  .faq_imagedt {
    margin-top: 35px !important;
  }
}
/* @media only screen and (min-width:319px) and (max-width:450px){
  .fm_getting_txttop.fm_getting_txt{
    margin-top: 15px;
  }
} */
@media only screen and (min-width: 992px) {
  .row.slicing_row .card_client:last-child {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .newsubscribenft.subscribebnft p {
    font-size: 21px !important;
  }
  .faq_img.img_aligned img {
    max-width: 450px !important;
  }
  .privacy_img.faq_img.img_aligned img,
  .disclaimer_img.faq_img.img_aligned img {
    max-width: 300px !important;
  }
  .about_img.faq_img.faq_img_aligned img {
    max-width: 550px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .newsubscribenft.subscribebnft p {
    font-size: 21px !important;
  }
  .mobile_content .homebanerheight {
    /* max-height: 30px !important; */
    margin-top: 50px;
    background-color: #fff !important;
  }
  .about_banner_maintxt {
    font-size: 30px !important;
  }
  .faq_img.img_aligned img {
    max-width: 350px !important;
  }
  .privacy_img.faq_img.img_aligned img,
  .disclaimer_img.faq_img.img_aligned img {
    max-width: 250px !important;
  }
  .about_img.faq_img.faq_img_aligned img {
    max-width: 500px !important;
  }
  .tf-toggle-title b {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 319px) and (max-width: 576px) {
  .newsubscribenft.subscribebnft {
    padding: 0px;
  }
  .mobile_content .homebanerheight {
    background-color: #fff;
    margin-bottom: 20px;
  }
  body .homebanerheight h1 {
    color: #1f1f1f;
    text-shadow: none;
  }

  .fm_learnmore_btn {
    margin-bottom: 15px;
  }
  .tf-toggle-title b {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 319px) and (max-width: 450px) {
  .about_banner_maintxt {
    font-size: 23px;
    padding: 0px 15px !important;
  }
  .aboutuspagesec p {
    font-size: 16px;
  }
  .about_ban_title {
    font-size: 20px;
  }
  .new_sectionAnother.sectionAnother p {
    font-size: 22px;
  }
  .img-fluid1 {
    margin-right: 30px;
  }
  .logos_marquee {
    margin-right: 100px;
  }
  /* .newsubscribenft.subscribebnft p{
    font-size: 16px !important;
  } */
  .mob_minus.robotoReg {
    font-size: 19px !important;
  }
  .fm_newdtl_txt.robotoReg {
    font-size: 16px !important;
  }
  body .homebanerheight h1 {
    font-size: 25px !important;
  }
  .mobile_content .homebanerheight {
    max-height: 80px !important;
    margin-top: 25px;
  }
  .faq_img.img_aligned img {
    max-width: 250px !important;
  }
  .privacy_img.faq_img.img_aligned img,
  .disclaimer_img.faq_img.img_aligned img {
    max-width: 170px !important;
  }
}
@media only screen and (min-width: 400px) and (max-width: 767px) {
  .row.slicing_row .card_client:last-child {
    display: none;
  }
}
@media only screen and (min-width: 451px) and (max-width: 575px) {
  .aboutuspagesec p {
    font-size: 16px;
  }
  .about_ban_title {
    font-size: 22px;
  }
  .new_sectionAnother.sectionAnother p {
    font-size: 30px;
    line-height: 1.3;
  }
  /* .newsubscribenft.subscribebnft p{
    font-size: 15px !important;
  } */
  .fm_newdtl_txt.robotoReg {
    font-size: 18px !important;
  }
  body .homebanerheight h1 {
    font-size: 30px !important;
  }
  .mobile_content .homebanerheight {
    max-height: 90px !important;
    margin-top: 30px;
    background-color: #fff !important;
  }
}
@media only screen and (min-width: 451px) and (max-width: 768px) {
  .about_banner_maintxt {
    font-size: 25px;
    padding: 0px 25px;
  }
}
@media only screen and (min-width: 450px) and (max-width: 576px) {
  .faq_img.img_aligned img {
    max-width: 300px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .about_banner_maintxt {
    font-size: 35px;
  }
  .kodu {
    margin-top: 30px !important;
  }
}
@media only screen and (min-width: 768px) {
  .kr_patentpend {
    margin-top: 70px !important;
  }
  .kr_asfearued {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .mobile_content .homebanerheight {
    max-height: 100px !important;
    /* margin-top: 30px; */
  }
  .homebanerheight h1 {
    font-size: 40px !important;
  }
}
@media only screen and (min-width: 576px) {
  .about_br {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .fm_topdata_ttl {
    margin-top: 15px !important;
  }
  .mobile_content .homebanerheight {
    margin-bottom: 0px !important;
  }
}
@media only screen and (max-width: 576px) {
  /* .common_react_searchbar_whole{
    padding: 0px 10px;
  } */
  .common_react_searchbar_whole {
    display: flex;
    justify-content: center;
  }
  .wid_connectwallet.connectwallet {
    padding: 4px 25px !important;
    font-size: 18px !important;
  }
  .tokencard_connectwallet.connectwallet {
    padding: 4px 10px !important;
    white-space: nowrap;
    font-size: 12px !important;
  }
  .tokencardlg_connectwallet.connectwallet {
    padding: 4px 20px !important;
    white-space: nowrap;
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 450px) and (max-width: 461px) {
  .about_banner_maintxt {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1249px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 490px !important;
    max-width: 490px !important;
  }
}
@media only screen and (min-width: 1199px) and (max-width: 1250px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 400px !important;
    max-width: 400px !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 400px !important;
    max-width: 400px !important;
  }
}

@media only screen and (min-width: 575px) and (max-width: 768px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 280px !important;
    max-width: 280px !important;
  }
}
@media only screen and (min-width: 575px) and (max-width: 577px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 260px !important;
    max-width: 260px !important;
  }
  .common_react_searchbar {
    min-width: 320px;
    width: 320px;
  }
}

@media only screen and (min-width: 549px) and (max-width: 575px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 375px !important;
    max-width: 375px !important;
  }
  .common_react_searchbar {
    width: 85%;
  }
}
@media only screen and (min-width: 499px) and (max-width: 550px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 335px !important;
    max-width: 335px !important;
  }
  .common_react_searchbar {
    width: 95%;
  }
}
@media only screen and (min-width: 449px) and (max-width: 500px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 290px !important;
    max-width: 290px !important;
  }
  .common_react_searchbar {
    width: 95%;
  }
}
@media only screen and (min-width: 399px) and (max-width: 450px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 250px !important;
    max-width: 250px !important;
  }
  .common_react_searchbar {
    width: 95%;
  }
}
@media only screen and (min-width: 349px) and (max-width: 400px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 250px !important;
    max-width: 250px !important;
  }
}
@media only screen and (min-width: 319px) and (max-width: 400px) {
  .about_banner_maintxt {
    padding: 0;
  }

  .common_react_searchbar {
    width: 100%;
  }
}
@media only screen and (min-width: 319px) and (max-width: 350px) {
  .common_react_searchbar .sc-eDvSVe {
    min-width: 220px !important;
    max-width: 220px !important;
  }
}
.bordering_radius_left {
  border-top-left-radius: 15px !important;
}
.bordering_radius_right {
  border-top-right-radius: 15px !important;
}
.unblockdata_searchbar {
  border: 1px solid lightgray;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  font-size: 14px;
}
.unblockdata_searchbar:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
.downloding_options {
  text-align: center;
  margin-top: 15px;
}
.exporting_btns {
  background-color: #0071e3;
  color: #fff;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}
.exporting_btns:hover {
  outline: 1px solid #0071e3;
  color: #0071e3;
  background-color: transparent;
}
.aboutpage_cmstxts b,
.aboutpage_cmstxts strong {
  font-weight: 600 !important;
}

#wcm-modal {
  z-index: 10000 !important;
}
.wcm-container {
  z-index: 10000 !important;
}
.common_react_searchbar_whole {
  max-height: 500px;

  position: relative;
}
.common_react_searchbar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid lightgray;
  border-radius: 30px;
  padding: 10px 15px;
}
.common_react_searchbar:hover {
  box-shadow: 0px 1px 7px 0px #d7d7d7;
}
.common_react_searchbar .searcher_glasss {
  position: relative;
  top: 5px;
}
.common_react_searchbar .sc-eDvSVe {
  width: 100%;
  min-width: 550px;
  max-width: 550px;
}
.common_react_searchbar .react-search-box-dropdown {
  margin: 0;
  box-shadow: none;
  width: 100%;
}
.common_react_searchbar .react-search-box-dropdown > ul > li:hover {
  background-color: #f5f5f5;
}
.common_react_searchbar .react-search-box-dropdown > ul > li {
  border: none;
  font-size: 15px;
}
.common_react_searchbar input {
  border: none;
  outline: none;
  width: 100%;
  margin-left: 15px;
  padding: 0;
  height: 30px;
}
.common_react_searchbar input:focus-visible {
  border: none !important;
  outline: none !important;
}
.homepage_darker_span {
  color: #000 !important;
}
/* .accordion .termsaccord_firsthide:first-child{
  display: none;
} */
/* .disc_accord h1,.disc_accord h2,.disc_accord h3,.disc_accord h4,.disc_accord h5,.disc_accord h6{
  font-size: 22px;
} */
.disc_accord strong,
.disc_accord b {
  font-weight: 600 !important;
}
.disc_accord u {
  text-decoration: underline !important;
}
.disc_accord h5 {
  color: #787878 !important;
  font-size: 20px;
}
/* @media only screen and (min-width:1400px){
  .homebanner img{
    max-height: 964px;
  }
} */

/* input.form-control::placeholder::before{
  content: "\f002" !important;

} */
.terms_img {
  background-color: #b6c7ff !important;
  max-width: 900px;
  width: 90%;
  min-height: 300px;
  border-radius: 70px;
  margin: auto;
  box-shadow: 1px 2px 12px -3px #000;
  margin-top: 10px;
}
.privacy_img {
  background-color: #b3ffd7 !important;
  max-width: 900px;
  width: 90%;
  min-height: 300px;
  border-radius: 70px;
  margin: auto;
  box-shadow: 1px 2px 12px -3px #000;
  margin-top: 10px;
}
.disclaimer_img {
  background-color: #ffebb3 !important;
  max-width: 900px;
  width: 90%;
  min-height: 300px;
  border-radius: 70px;
  margin: auto;
  box-shadow: 1px 2px 12px -3px #000;
  margin-top: 10px;
}
.about_img {
  background-color: #dce9ae !important;
  max-width: 900px;
  width: 90%;
  min-height: 300px;
  border-radius: 70px;
  margin: auto;
  box-shadow: 1px 2px 12px -3px #000;
  margin-top: 10px;
}
.faqbg_img {
  background-color: #cffffe !important;
  max-width: 900px;
  width: 90%;
  min-height: 300px;
  border-radius: 70px;
  margin: auto;
  box-shadow: 1px 2px 12px -3px #000;
  margin-top: 10px;
}

/* .privacybanner_bgcolor,.privacybanner_bgcolor .footerhead.scroll-to-top,.privacybanner_bgcolor .footer_bgcolor{
  background-color: #b3ffd7 !important;
}
.custom-container{
  background-color: #fff !important;
}
.termsbanner_bgcolor,.termsbanner_bgcolor .footerhead.scroll-to-top,.termsbanner_bgcolor .footer_bgcolor{
  background-color: #b6c7ff !important;
}
.discbanner_bgcolor,.discbanner_bgcolor .footerhead.scroll-to-top,.discbanner_bgcolor .footer_bgcolor{
  background-color: #ffebb3 !important;
}
.aboutbanner_bgcolor,.aboutbanner_bgcolor .footerhead.scroll-to-top,.aboutbanner_bgcolor .footer_bgcolor{
  background-color: #dce9ae !important;
}
.faqbanner_bgcolor,.faqbanner_bgcolor .footerhead.scroll-to-top,.faqbanner_bgcolor .footer_bgcolor{
  background-color: #cffffe !important;
} */

/* .faqbg_img{
  background-color: #cffffe !important;
  max-width: 1600px;
  border-radius: 70px;
  margin: auto;
} */

.discbanner_bgcolor,
.termsbanner_bgcolor,
.privacybanner_bgcolor,
.aboutbanner_bgcolor,
.faqbanner_bgcolor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 450px);
}
.disc_accord .accordion .accord_question {
  font-family: "HelveticaNeue" !important;
}
/* .disc_removelast .hide_item:last-child{
  display: none;

} */
.resubs_details {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
}
.resub_dateprofile.dateprofile p {
  text-align: center;
  width: 100%;
}
.resubs_linktext a {
  color: #787878 !important;
}
.resubs_linktext a:hover {
  color: #00b1ff !important;
}
.twitter_x {
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  max-width: 34px;
  max-height: 34px;
  background-color: #000;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -2px;
  margin-right: 10px;
  transition: 0.5s ease-in;
  cursor: pointer;
}
.twitter_x i {
  font-size: 25px;
  position: relative;
  top: 3px;
  color: #fff !important;
}
.twitter_x:hover {
  background-color: #00b1ff;
}
.putonsale_startbtn {
  background-color: #0266cb;
  color: #fff;
  font-size: 16px;
  border: 1px solid #0266cb;
  outline: none;
  border-radius: 30px;
  padding: 5px 35px;
  cursor: pointer;
  /* width: 100%; */
}
.putonsale_startbtn:hover {
  border: 1px solid #e9e9e9;
  color: #0266cb;
  background-color: #e9e9e9;
}
.putonsale_cancelbtn {
  border: 1px solid #0266cb;
  color: #0266cb;
  padding: 5px 30px;
  background-color: transparent;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  /* width: 100%; */
}
.putonsale_cancelbtn:hover {
  background-color: #0266cb;
  color: #fff;
  border: 1px solid #0266cb;
}
/* .putonsale_botbtns.startwalletbtns{
  flex-direction: column;
} */
.search_galass {
  color: #787878 !important;
  font-size: 16px !important;
  margin-right: 10px;
}
.search_closer {
  color: #787878 !important;
  font-size: 16px !important;
  margin-left: 10px;
}
.searchiconsd:hover {
  box-shadow: 0 1px 7px 0 #d7d7d7;
}
.header_searchiconsd {
  opacity: 0 !important;
}
.putosale_imgcenter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top_heal.approvetransaction {
  margin-top: 0px !important;
}
@media only screen and (min-width: 575px) and (max-width: 577px) {
  .connectImg img {
    width: 35px !important;
  }
}
@media only screen and (max-width: 575px) {
  .connectImg img {
    width: 50px !important;
  }
}
/* .kr_tokencardinfoheade.tokencardinfoheade{
  justify-content: flex-end;
} */
.searchlist_height.searchlist.active {
  min-height: 400px !important;
  max-height: 400px !important;
}
.nod {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.nodataText {
  text-align: center;
  font-weight: 600;
  width: 100%;
}
#placeabid h1.placeabid_title {
  font-size: 16px !important;
}
.modal-body p.requ_title {
  width: 100% !important;
}
.buybtn_cap.buybtn {
  text-transform: none !important;
}
.privacy_img.faq_img.img_aligned img,
.disclaimer_img.faq_img.img_aligned img {
  max-width: 300px;
}
.terms_img.faq_img.img_aligned img {
  max-width: 450px;
}
.about_img.faq_img.faq_img_aligned img {
  max-width: 650px;
}
.quantity_inp {
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid #0000002e;
  width: 100%;
}
/* mycss */
.video_container {
  max-width: 90%;
  height: 90%;
}

/* Header Footer styles */
body .navbar {
  background: url("./assets/images/Header/nft-header.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
  height: 80px;
  font-family: "HelveticaNeue";
  margin-bottom: -2px;
}
.unblocklogonav {
  width: 166px;
  height: 58.189px;
}
@media screen and (max-width: 1025px) {
  .unblocklogonav {
    height: auto !important;
  }
}
.homelinklink {
  color: #ffffff;
  text-decoration: none;
  transition: 0.5s ease;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.homelinklink.active,
.homelinklink:hover,
#mobilenav a.homelinklink:hover,
a.homelinklink.active,
#mobilenav a.homelinklink.active {
  color: #fff600;
}

.searchiconsd {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
  display: none;
}
.homelinkswhole {
  display: flex;
  margin-bottom: 0;
  padding: 0;
  justify-content: space-evenly;
  max-width: 100%;
  width: 80%;
  margin: auto;
  margin-right: 0px;
}
@media screen and (max-width: 990px) {
  .homelinkswhole {
    display: block;
    width: 100%;
  }
}
.home_links {
  background: url("./assets/images/Header/nft-header.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  height: 100%;
  width: 50%;
  animation: slide 0.5s forwards;
  right: 0;
}
body #navbar.scroll {
  background: url("./assets/images/Header/nft-header.png");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  top: 0px;
  width: 100% !important;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  position: fixed !important;
}
.connectImg {
  color: #000000;
  width: 139px;
  height: 36px;
  border-radius: 5px;
  background: #fff600;
  justify-content: space-around;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.navWalletText {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  left: -18px;
}
.connectImg img {
  width: 35px;
  width: 24.024px;
  height: 24.024px;
  flex-shrink: 0;
}
.footerhead {
  background: url("./assets/images/Footer/marketplaceFooter.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 0 0 0;
  padding-top: 132px;
  font-family: "HelveticaNeue";
}
.footerhead .footer_top_logo {
  width: 40%;
}
.footer_xpln {
  color: #fff;
  font-family: "HelveticaNeue";
  padding-bottom: 5px !important;
  margin-top: 10.42px !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 25.11px;
  letter-spacing: 0.18px;
  margin-bottom: 20px;
}
.footerhead .fa-brands,
.footerhead .fab {
  color: #ffffff;
}
.twitter_x {
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  max-width: 34px;
  max-height: 34px;
  background-color: #ffffff;
  color: #000000 !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -2px;
  margin-right: 10px;
  transition: 0.5s ease-in;
  cursor: pointer;
}
.twitter_x i {
  font-size: 25px;
  position: relative;
  color: #000000 !important;
  top: 4px;
  left: -2px;
}
.twitter_x:hover {
  background-color: #00b1ff;
}
.footersociallinks a {
  font-size: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footersociallinks .twitter_x a {
  margin-right: 0;
}
.footerhead .footer_blocks h2 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 25px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
  padding-left: 10px !important;
  text-align: left;
}
.footerhead ul {
  padding: 0;
  margin-top: 23px;
}
.footerhead .quicklinks ul li a {
  color: #ffffff !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.18px;
}
.copyrightWrap {
  background: rgba(255, 246, 0, 1);
}
.footerhead ul li {
  list-style: none;
  color: #fff;
  margin-bottom: 10px;
  font-family: "HelveticaNeue";
  line-height: 45px;
  letter-spacing: 0.18px;
  font-weight: 400;
  font-style: normal;
}
.copyrightWrap .copyrights {
  border-top: 1px solid #9e9e9e;
  text-align: center;
  padding-top: 22px;
  margin: 0 !important;
  color: #000;
  text-decoration: none;
  transition: 0.5s ease;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  font-family: "HelveticaNeue";
}
/* .copyrightWrap .copyrightsde {
  text-align: center;
  padding-top: 2px;
  margin: 0 !important;
  color: #000;
  text-decoration: none;
  transition: 0.5s ease;
  font-size: 18px;
  font-style: normal;
  font-family: "HelveticaNeue";
  letter-spacing: 0.18px;
} */
.copyrightsde a {
  color: #000;
  transition: 0.5s ease;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.18px;
}
.copyrightsde li {
  color: #000 !important;
  margin: 0px !important;
}
.copyrightsde ul {
  margin: 0px !important;
}
.footersociallinks {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 69px;
}
@media screen and (max-width: 999px) {
  .footer_blocks {
    display: block !important;
    width: 100%;
    text-align: left;
  }
  /* .footer_bgcolor > div > div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  } */
  .footer_xpln {
    text-align: left;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 139.5%;
    letter-spacing: 0.14px;
  }
  .footer_bgcolor .footer_blocks .robotoBold {
    text-align: left !important;
    margin-top: 43px;
    margin-bottom: 30px !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
  }
  .footersociallinks {
    margin-bottom: 0px;
  }
  .footerhead .footer_top_logo {
    width: 200px;
    height: auto;
  }
  .footer__logo {
    width: 149.789px;
    height: 52.506px;
  }
  .footerhead .footer_bgcolor {
    padding-top: 42px !important;
    padding-left: 27px;
    padding-right: 40px;
  }
  .footerhead {
    padding: 0px;
  }
  .footerhead .quicklinks ul li a {
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.14px;
  }
  .copyrightsde a {
    cursor: pointer;
    color: #000000;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0px;
    margin: 10px !important;
  }
  .copyrightWrap .copyrights {
    font-size: 12px !important;
  }
  .copyrightsde {
    width: 100% !important;
  }
}
.footer-columns-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.footer-columns-wrapper > div {
  padding: 0px;
}
#mobilenav .connectImg {
  margin: 20px;
}
@media screen and (max-width: 991px) {
  /* .copyrightWrap .copyrights{
    margin-bottom:20px!important;
  } */
  #seathbats {
    display: block;
  }
  .seathbats {
    display: none;
  }
}
/* wallet popup style */

.modal-open .modal {
  background: linear-gradient(
    171deg,
    rgba(21, 10, 45, 0.9) -7.18%,
    rgba(10, 3, 24, 0.9) 47.09%,
    rgba(19, 8, 43, 0.9) 109.81%
  );
  backdrop-filter: blur(15px);
}
#connect_wallet .modal-dialog {
  max-width: 826px !important;
  width: 826px !important;
}
#connect_wallet .modal-dialog .modal-content {
  max-width: 800px !important;
  width: 800px !important;
  background: url("./assets/images/connectWallet.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px !important;
  border: 4px solid rgba(162, 121, 250, 0.3) !important;
  padding-bottom: 10px;
  font-family: "HelveticaNeue";
}
.modal-header {
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}
#connect_wallet .modal-title {
  color: #fff;
  margin: 0 auto;
  font-family: "HelveticaNeue";
  text-align: center;
  font-size: 79.416px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px !important;
  margin-top: 50px;
}
#connect_wallet .modal-title span {
  color: #fff600;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 79.416px;
  font-style: normal;
  font-weight: 400;
  text-shadow: -4px 4px 0px #120b19, -6px 6px 0px #612cd2;
  line-height: normal;
}
#connect_wallet .modal-body p {
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  font-family: "Helvetica Neue Light";
  line-height: normal;
  padding: 0;
}
#connect_wallet .modal-body h2 {
  margin-top: 30px;
  margin-bottom: 0px;
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#connect_wallet .modal-body h2 span a {
  color: #a279fa;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "HelveticaNeue";
  text-decoration-line: underline !important;
}
#connect_wallet .modal-body .metamasklogo {
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  background: url("./assets/images/connectWallet.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 2px solid rgba(162, 121, 250, 0.3);
  width: 744px;
  height: 181px;
  margin: 67px auto 0px auto;
}
#connect_wallet #walletConnectlogoo {
  margin-top: 17px;
}
#connect_wallet .modal-body .metamasklogo img,
#connect_wallet .modal-body .trust_wallet img {
  width: 50px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0px 3px 16px 2px #00000026;
  padding: 10px;
  border-radius: 50px;
  width: 106px;
  height: 99px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #201735;
  margin-right: 46px;
  margin-left: 32px;
}
#connect_wallet .modal-body .metamasklogoconte h3 {
  color: #fff;
  text-align: left;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: "HelveticaNeue";
  margin: 0px;
}
#connect_wallet .modal-body .metamasklogoconte p {
  color: #fff;
  text-align: left;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  font-family: "HelveticaNeue";
  line-height: normal;
}
.wallet-divider {
  width: 90%;
  margin: auto;
  overflow: hidden;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 10px;
}
.wallet-divider img {
  width: 100%;
}
#connect_wallet .modal-footer {
  position: absolute;
  top: 16px;
  right: 2px;
  padding-bottom: 40px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0 !important;
}
.connectwallet,
.connectwallet:hover {
  border-radius: 5px;
  background: #fff600;
  color: #000000 !important;
  cursor: pointer;
  font-size: 14px;
  /* text-transform: capitalize !important; */
  border: none;
  /* border-radius: 30px !important; */
  transition: 0.5s ease-in-out !important;
  padding: 5px 25px !important;
  font-weight: 400 !important;
  text-decoration: none;
}
#connect_wallet .modal-footer i {
  color: #fff600;
  font-size: 30px;
  cursor: pointer;
}
#connect_wallet .modal-footer {
  justify-content: center !important;
}
@media screen and (max-width: 820px) {
  #connect_wallet .modal-dialog .modal-content,
  #connect_wallet .modal-dialog {
    max-width: 96% !important;
    width: 96% !important;
    margin: 20px auto !important;
    padding: 0px;
  }
  #connect_wallet .modal-dialog,
  #connect_wallet .modal-body .metamasklogo {
    max-width: 96% !important;
    width: 96% !important;
  }
  #connect_wallet .modal-dialog,
  #connect_wallet .modal-body {
    padding-bottom: 04px !important;
  }
  #connect_wallet .modal-title,
  #connect_wallet .modal-title span {
    font-size: 46px;
    line-height: 45px !important;
  }
  #connect_wallet .modal-body p,
  #connect_wallet .modal-body h2,
  .modal-body h2 span a {
    font-size: 24px;
  }
  .wallet-divider {
    margin: 10px auto;
  }
  #connect_wallet .modal-body .metamasklogo img {
    background-color: #fff;
    box-shadow: 0px 3px 16px 2px #00000026;
    padding: 10px;
    border-radius: 50px;
    width: 60px;
    height: auto;
    border-radius: 5px;
    background: #201735;
    margin-right: 16px !important;
    margin-left: 6px !important;
  }
  #connect_wallet .modal-body .metamasklogo {
    height: auto;
    padding: 30px 10px;
    margin: 40px auto;
  }
  #connect_wallet .modal-body .metamasklogoconte h3 {
    color: #fff;
    text-align: left;
    font-size: 30px;
    margin: 0px;
  }
  #connect_wallet .modal-body .metamasklogoconte p {
    color: #fff;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 10px;
  }
}
@media screen and (max-width: 601px) {
  #connect_wallet .modal-title,
  #connect_wallet .modal-title span {
    font-size: 34px;
    line-height: 34px !important;
  }
  #connect_wallet .modal-body p {
    font-size: 14px;
    line-height: 24px;
    margin: 0;
  }
  #connect_wallet .modal-body h2,
  #connect_wallet .modal-body h2 span a {
    font-size: 13px;
    line-height: 24px;
    margin: 0;
  }
  #connect_wallet .modal-body .metamasklogoconte h3 {
    color: #fff;
    text-align: left;
    font-size: 18px;
    margin: 0px;
  }
  #connect_wallet .modal-body .metamasklogoconte p {
    color: #fff;
    text-align: left;
    font-size: 12px;
  }
  #connect_wallet .modal-body .metamasklogo {
    height: auto;
    padding: 12px 10px;
    margin: 20px auto;
  }
  .wallet-divider {
    margin: 24px auto -12px auto;
  }
}

/* #///////#  */
@media screen and (max-width: 426px) {
  #connect_wallet .modal-body p,
  #connect_wallet .modal-body h2,
  #connect_wallet .modal-body h2 span a {
    font-size: 12px !important;
    line-height: 20px;
  }
  #connect_wallet .modal-body .metamasklogo {
    height: auto;
    padding: 4px 0px;
    margin: 20px auto;
  }
  #connect_wallet .modal-body .metamasklogoconte h3 {
    font-size: 15px !important;
  }
  #connect_wallet .modal-body {
    padding: 6px;
  }
  #connect_wallet .modal-footer i {
    font-size: 20px;
  }
  #connect_wallet .modal-title,
  #connect_wallet .modal-title span {
    font-size: 28px;
    line-height: 26px !important;
  }
  #connect_wallet .modal-body .metamasklogo img {
    margin-right: 8px !important;
  }
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  background: none !important;
}
#yellowHeadText {
  color: #fff600;
  text-shadow: -6px 3px 0px #120b19, -8px 6px 0px #612cd2;
}
/* new css code */
#placeabid #placeabidlabel {
  color: #fff;
  text-align: center;
  font-family: HelveticaNeue;
  font-size: 79px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
  text-shadow: -6px 3px 0px #120b19, -8px 6px 0px #612cd2;
  margin-top: 40px;
}
#placeabid .modal-dialog {
  max-width: 743px;
}
#placeabid .modal-content {
  max-width: 743px;
  width: 100% !important;
  border: 4px solid rgba(162, 121, 250, 0.3) !important;
  background-color: rgba(133, 100, 201, 0.25) !important;
  backdrop-filter: blur(10px);
  height: fit-content !important;
  margin: 20px;
}
#placeabid .idTextWrapper h5 {
  color: #fff600;
  text-align: center;
  font-family: "HelveticaNeue";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
#placeabid .idTextWrapper h6 {
  color: #fff;
  font-family: "HelveticaNeue";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.ImagsContentWrapper {
  width: 84%;
  margin: auto;
  display: grid;
  grid-template-columns: 30% 70%;
  margin-top: 36px;
  margin-bottom: 31px;
  align-items: center;
}
.idTextWrapper {
  padding: 0px 20px;
}
#placeabid .modal-body {
  width: 84%;
  margin: auto;
  padding: 0;
}
#placeabid .placeabidbalance h6 {
  color: #a279fa;
  font-family: "HelveticaNeue";
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px !important;
}
#placeabid .placeabidbalance h5 {
  color: #fff;
  text-align: right;
  font-family: "HelveticaNeue";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px !important;
}
#placeabid .modal-footer {
  margin-top: 50px;
}
#placeabid .modal-footer .buybtn {
  width: 85%;
  border-radius: 5px !important;
  background: #fff600 !important;
  color: #000 !important;
  font-family: "HelveticaNeue";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 16px !important;
}
#placeabid #WalletBalance {
  margin-top: 39px !important;
}
.palcebideths.palcebideths_krdrop,
.palcebideths {
  border-radius: 5px !important;
  border: 1px solid rgba(162, 121, 250, 0.5) !important;
  background: rgba(97, 44, 210, 0.2) !important;
  color: #ffffff;
  font-family: "Helvetica Neue Light" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 34px 10px;
}
#placeabid .react-select__control {
  border-radius: 5px !important;
  border: 0px solid rgba(162, 121, 250, 0.5) !important;
  background: rgba(97, 44, 210, 0.2) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px) !important;
  color: #ffffff;
  font-family: "Helvetica Neue Light" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#placeabid #buyemail::placeholder,
#placeabid .form-control:focus,
#placeabid input[type="text"]:focus-visible,
.palcebideths input::placeholder,
.palcebideths.palcebideths_krdrop::placeholder,
#tokenBidAmt:focus,
.palcebideths::placeholder,
#Enter_Quantity h2 {
  font-family: "Helvetica Neue Light" !important;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #ffffff !important;
  padding: 34px 10px;
}
.palcebideths input {
  margin-left: 0px !important;
}
.react-select__single-value,
input#tokenBidAmt {
  color: #ffffff !important;
}
#placeabid .bidbtn {
  position: absolute;
  top: -44px;
  right: -57px;
  width: fit-content;
}
.modal-footer .bidbtn:hover {
  background: transparent !important;
  border: none !important;
}
@media screen and (max-width: 1025px) and (min-width: 769px) {
  .headDivider {
    overflow: hidden;
    display: none;
  }
  #placeabid #placeabidlabel {
    font-size: 50px !important;
  }
  #placeabid .idTextWrapper h5 {
    font-size: 30px;
  }
  #placeabid .idTextWrapper h6 {
    font-size: 20px !important;
  }
  #placeabid .placeabidbalance h6 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 769px) {
  .idImagWrapper img {
    width: 30%;
  }
  #placeabid .modal-dialog {
    max-width: 90% !important;
    margin: 20px auto;
  }
  .headDivider {
    overflow: hidden;
    display: none;
  }
  .ImagsContentWrapper {
    display: block !important;
    text-align: center;
    margin: 0 auto;
  }
  #placeabid #placeabidlabel {
    font-size: 24px !important;
  }
  #placeabid .idTextWrapper h5 {
    font-size: 20px;
  }
  #placeabid .idTextWrapper h6 {
    font-size: 16px !important;
  }
  #placeabid .placeabidbalance h5,
  #placeabid .placeabidbalance h6 {
    margin: 04px 0px !important;
    font-size: 12px !important;
  }
  #placeabid .idTextWrapper h5,
  #placeabid .idTextWrapper h6 {
    text-align: center !important;
    margin: 10px 0px;
  }
  .palcebideths.palcebideths_krdrop,
  .palcebideths {
    padding: 12px 10px;
  }
  #placeabid #WalletBalance {
    margin-top: 10px !important;
  }
  #placeabid .modal-footer {
    margin-top: 20px !important;
  }
  #placeabid #buyemail::placeholder,
  #placeabid .form-control:focus,
  #placeabid input[type="text"]:focus-visible,
  .palcebideths input::placeholder,
  .palcebideths.palcebideths_krdrop::placeholder,
  #tokenBidAmt:focus,
  .palcebideths::placeholder,
  #placeabid .react-select__control,
  .palcebideths.palcebideths_krdrop,
  .palcebideths,
  #Enter_Quantity h2 {
    font-size: 14px !important;
  }
  #Enter_Quantity .item_added {
    gap: 18px !important;
  }
}
@media screen and (max-width: 1025px) {
  #placeabid .bidbtn img {
    width: 30px;
    margin: 0;
  }
  #placeabid .bidbtn {
    position: absolute;
    top: 10px !important;
    right: 10px !important;
    width: fit-content;
    padding: 0px !important;
  }
  #placeabid .modal-content {
    margin: 0px !important;
  }
}
#placeabid {
  backdrop-filter: blur(0px);
}
#yellowBidText {
  color: #fff600;
}
.faq_anstxtclr p,
.faq_anstxtclr,
.faq_anstxtclr li {
  color: #000;
  font-family: Helvetica Neue Light !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.ImagsContentWrapper img#imagePlaceBid {
  border-radius: 6.673px;
  border: 2.669px solid rgba(162, 121, 250, 0.3);
  background: rgba(133, 100, 201, 0.25);
  backdrop-filter: blur(13.345815658569336px);
  padding: 5px;
  width: 100% !important;
}
#Enter_Quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#Enter_Quantity h2 {
  color: #fff;
  font-family: "Helvetica Neue Light";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6px;
}
#Enter_Quantity .item_added {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
#Enter_Quantity .item_added img {
  margin-left: 0px;
  cursor: pointer;
}
.footer-logoWrap {
  text-align: left;
}
#home_headerBox .not_found_text_sub {
  color: #ffffff;
}
@media screen and (max-width: 769px) {
  .copyrightWrap {
    position: relative;
    bottom: 0;
    width: 100%;
  }
  #home_headerBox .search-data-token {
    margin-top: 0px !important;
    padding: 0px !important;
  }
}
@media screen and (max-width:769px){
  .palcebideths .css-b62m3t-container{
    width: 60%;
  }
  .palcebideths .react-select__dropdown-indicator{
    padding: 2px;
  }
}
